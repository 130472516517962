export interface IPriceResponse {
  accountCurrency: string
  amountReceivedAcc: number
  amountReceivedDest: number | string
  amountSentInAcc: number
  amountSentSrc: number | string
  campaignData: any
  campaignDiscountAcc: number
  campaignDiscountSrc: number
  discountPercent: number
  discountAmountFixedInAcc: number
  discountAmountFixedInSrc: number
  fxRateAccDestWithCommission: number
  fxRateAccSrcWithCommission: number
  fxRateAccToDest: number
  fxRateAccToSrc: number
  fxRateSrcDestCustom: number
  fxRateSrcDestWithCommission: number
  fxRateSrcToDest: number
  message: string | null
  status?: string
  statusCode?: number
  organizationFeeAmountAcc: number
  organizationFeeAmountSrc: number
  organizationFeeFixed: number
  organizationFeeFixedInAcc: number
  organizationFeePercent: number
  rateMode: string
  senderToPayAcc: number
  senderToPaySrc: number
  destinationCountry: string
  sourceCountry: string
  destinationCurrency: string
  sourceCurrency: string
}

export interface IBankDetail {
  bankName: string
  bankCode: string
}

export interface IBranchBank {
  branchName: string
  branchCode: string
}

export interface ILocation {
  locationName: string
  locationCode: string
}

export interface ICity {
  code: string
  name: string
}

export interface ICorrLocation {
  locName: string
  locID: string
}

export interface ICityLocation {
  correspName: string
  correspID: string
  correspNo: string
  locations: ICorrLocation[]
}

export interface IRequiredField {
  name: string
  value: string | null
  minLength: number
  maxLength: number
  valid?: boolean
}

export interface IDTOneProduct {
  fee: number
  fxRate: number
  receivedAmount: number
  receivedCurrency: string
  sendCurrency: string
  senderToPay: number
  sentAmount: number
}

export interface IMobileOperator {
  mobileOperatorName: string
  mobileOperatorCode: string
}

export interface ITransferData extends IPriceResponse {
  sourceCountry: string
  destinationCountry: string
  payoutType: string
  payinType?: string
  operatorId: string
  sourceCurrency: string
  destinationCurrency: string
  fundingSource?: string
  sourceCurrencies: string[]
  destinationCurrencies: string[]
  reverse: boolean
  useOfFunds?: string
  sourceOfFunds?: string
  occupation?: string
  limit?: number | string
  mode?: string
  banksByCountry?: IBankDetail[]
  bankBranches?: IBranchBank[]
  hasMultiplePaymentType?: boolean
  pickupLocations?: ILocation[]
  citiesByCountry?: ICity[]
  statesByCountry?: string[]
  citiesByState?: ICity[]
  cityLocations?: ICityLocation[]
  mobileOperators?: IMobileOperator[]
  pickupCode?: string
  cashPickupName?: string
  state?: string
  pickupCityCode?: string
  pickupCityName?: string
  pickupCorrespondentCode?: string
  cardMask?: string
  cardNumber?: string
  cardAccountId?: string
  cardHolderName?: string
  creditCardValidThru?: string
  signature?: string
  products?: IDTOneProduct[]
  mobileOperatorName?: string
  mobileOperatorCode?: string
  phoneNumber?: string
  unsignedVerificationData?: string
  errorMessage?: string
  recipientWalletId?: string
  senderWalletId?: string
  sourceCard?: string
  receiveCard?: string
  comment?: string
  payoutOperator?: string
  amountSenderToPay?: number
  fromSenderToPay?: boolean
  promoCode?: string
  promoCodeValid?: boolean
  allowWalletBalance?: string | number
  saveCard?: boolean
  tokenizationCard?: string | null
}

export interface ITransactionMeta {
  transactionId: string
  depositCode: string
  reservationCode?: string
  transactionStatus: string
  receiveCurrency: string
  priceResponse: IPriceResponse
  validationResponseMessage: { requiredFields?: IRequiredField[]; validationPassed: boolean }
  agentId?: string
  errorMessage?: string
  requiredFields?: IRequiredField[]
  validationPassed?: boolean
  paymentLink?: string
  timeCreated?: string
}

export interface IComplianceRules {
  [rule: string]: any
}

export interface ITransfer {
  transferData: ITransferData
  transactionMeta?: ITransactionMeta
  activeComplianceRules: IComplianceRules
  loading: string
  priceError?: string
}

export const clearPrice = {
  accountCurrency: '',
  amountReceivedAcc: 0,
  amountReceivedDest: 0,
  amountSentInAcc: 0,
  amountSentSrc: 0,
  campaignData: null,
  campaignDiscountAcc: 0,
  campaignDiscountSrc: 0,
  discountPercent: 0,
  discountAmountFixedInAcc: 0,
  discountAmountFixedInSrc: 0,
  fxRateAccDestWithCommission: 0,
  fxRateAccSrcWithCommission: 0,
  fxRateAccToDest: 0,
  fxRateAccToSrc: 0,
  fxRateSrcDestCustom: 0,
  fxRateSrcDestWithCommission: 0,
  fxRateSrcToDest: 0,
  message: null,
  organizationFeeAmountAcc: 0,
  organizationFeeAmountSrc: 0,
  organizationFeeFixed: 0,
  organizationFeeFixedInAcc: 0,
  organizationFeePercent: 0,
  rateMode: '',
  senderToPayAcc: 0,
  senderToPaySrc: 0,
}

export const initialState = (): ITransfer => ({
  transferData: {
    sourceCountry: 'IL',
    destinationCountry: '',
    sourceCurrencies: [],
    destinationCurrencies: [],
    reverse: false,
    payoutType: '',
    payinType: '',
    operatorId: '',
    sourceCurrency: '',
    destinationCurrency: '',
    ...clearPrice,
  },
  activeComplianceRules: {},
  loading: 'idle',
})

export interface ICurrencyPayload {
  agentId: string
  organizationId: string
  sourceCountry: string
  destinationCountry: string
  operatorId: string
  payoutType: string
}

export interface IPricePayload {
  agentId: string
  amount: string | number
  reverse: boolean
  sendCountry: string
  receiveCountry: string
  sourceCurrency: string
  destinationCurrency: string
  fundingSource: string
  operatorId: string
  organizationId: string
  payoutType: string
  senderId: string
  transactionOrigin: string
  promoCode?: string
  pickupCode?: string
  amountSenderToPay?: string | number
  fromSenderToPay?: boolean
}

export interface ILimitPayload {
  country: string
  currency: string
  operatorId: string
  payoutType: string
}

export interface IValidatePayload {
  agentId: string
  operatorId: string
  transactionId: string
  requiredFields: IRequiredField[]
}

export interface IDTOnePayload {
  url: string
  operatorId: string
  phoneNumber: string
}

export interface IDTOneResponse {
  products: IDTOneProduct[]
  operatorName: string
  phoneNumber: string
}

type TPayType = {
  [payout: string]: {
    icon: { SEND: string; PAYOUT: string }
    image?: any
    method: string
    title: string
    description?: string
    question?: string
    order?: number
  }
}

export interface IPayoutPayload {
  operatorId: string
  pin: string,
  transactionOriginType: string
  amount?: string | number
  currency?: string
}

export const PAYOUT_TYPES: TPayType = {
  CASH: {
    icon: {
      SEND: 'cash',
      PAYOUT: 'cash-payout',
    },
    method: 'cash_pickup',
    title: 'cash_pick_up_from_any_of_our_affiliated_banks',
    order: 1,
  },
  MOBILE_TOPUP: {
    icon: {
      SEND: 'mobile-banking',
      PAYOUT: 'mobile-banking',
    },
    method: 'phone_top_up',
    title: 'transfer_money_top_up',
    order: 7,
  },
  AIR_TIME: {
    icon: {
      SEND: 'mobile-banking',
      PAYOUT: 'mobile-banking',
    },
    method: 'phone_top_up',
    title: 'transfer_airtime_top_up_to_a_mobile_number',
    order: 7,
  },
  BANK_ACCOUNT: {
    icon: {
      SEND: 'bank',
      PAYOUT: 'bank-payout',
    },
    method: 'bank_deposit',
    title: 'deposit_to_bank_account',
    order: 3,
  },
  SEPA_TRANSFER: {
    icon: {
      SEND: 'sepa',
      PAYOUT: 'sepa',
    },
    method: 'bank_transfer_sepa',
    title: 'transfer_by_iban',
    order: 3,
  },
  SWIFT_TRANSFER: {
    icon: {
      SEND: 'world',
      PAYOUT: 'world',
    },
    method: 'bank_transfer_swift',
    title: 'transfer_by_swift',
    order: 3,
  },
  COMPASS_UNION_PAY: {
    icon: {
      SEND: 'china-union-pay',
      PAYOUT: 'china-union-pay',
    },
    image: require('assets/images/china_union.png'),
    method: 'china_union',
    title: 'transfer_china_union',
  },
  YANDEX_MONEY: {
    icon: {
      SEND: 'yoomoney',
      PAYOUT: 'yoomoney',
    },
    method: 'yandex_money',
    title: 'transfer_yandex_money',
    order: 5,
  },
  UNIVERSAL_PAYMENT: {
    icon: {
      SEND: 'bank',
      PAYOUT: 'bank-payout',
    },
    method: 'universal_payment',
    title: 'transfer_universal_payment',
    order: 4,
  },
  QIWI_WALLET: {
    icon: {
      SEND: 'send_wallet',
      PAYOUT: 'send_wallet',
    },
    method: 'Qiwi wallet',
    title: 'wallet_to_wallet',
    order: 5,
  },
  MOBILE_WALLET: {
    icon: {
      SEND: 'wallet1',
      PAYOUT: 'wallet1',
    },
    method: 'mobile_wallet',
    image: require('assets/images/operators/wechatpay.png'),
    title: 'transfer_funds_to_the_receivers_mobile_phone_number',
  },
  WALLET_TO_WALLET: {
    icon: {
      SEND: 'send_wallet',
      PAYOUT: 'send_wallet',
    },
    method: 'wallet_to_wallet',
    title: 'wallet_to_wallet',
    order: 5,
  },
  WALLET_DEPOSIT_CASH: {
    icon: {
      SEND: 'top_up_wallet',
      PAYOUT: 'payout',
    },
    method: 'deposit_to_wallet',
    title: 'deposit_to_wallet',
    order: 5,
  },
  WALLET_DEPOSIT_CARD: {
    icon: {
      SEND: 'top_up_wallet',
      PAYOUT: 'payout',
    },
    method: 'deposit_to_wallet',
    title: 'deposit_to_wallet',
    order: 5,
  },
  WALLET_DEPOSIT_BANK: {
    icon: {
      SEND: 'top_up_wallet',
      PAYOUT: 'payout',
    },
    method: 'deposit_to_wallet',
    title: 'deposit_to_wallet',
    order: 5,
  },
  PAYMENT_FROM_WALLET: {
    icon: {
      SEND: 'payout',
      PAYOUT: 'payout',
    },
    method: 'payment_from_wallet',
    title: 'payment_from_wallet',
    order: 5,
  },
  PAYMENT_FROM_CARD: {
    icon: {
      SEND: 'card-payout',
      PAYOUT: 'card-payout',
    },
    method: 'payment_from_wallet',
    title: 'payment_from_wallet',
    order: 5,
  },
  CASH_TO_CREDIT: {
    icon: {
      SEND: 'card',
      PAYOUT: 'card-payout',
    },
    method: 'cash_to_credit',
    title: 'load_money_to_card',
    image: require('assets/images/china_union.png'),
    order: 2,
  },
  CASH_CARD: {
    icon: {
      SEND: 'card',
      PAYOUT: 'card-payout',
    },
    method: 'cash_to_credit',
    title: 'load_money_to_card',
    order: 2,
  },
  WALLET_EXCHANGE: {
    icon: {
      SEND: 'refresh-cw',
      PAYOUT: 'refresh-cw',
    },
    method: 'currency_exchange',
    title: 'currency_exchange',
  },
  WALLET_INTERNAL_TRANSFER: {
    icon: {
      SEND: 'between_accounts',
      PAYOUT: 'between_accounts',
    },
    method: 'between_your_accounts',
    title: 'between_your_accounts',
  },
  PAYOUT_TO_WALLET: {
    icon: {
      SEND: 'payout-to-wallet',
      PAYOUT: 'payout-to-wallet',
    },
    method: 'payout_wallet',
    title: 'payout_wallet',
  },
  ALIPAY: {
    icon: {
      SEND: '',
      PAYOUT: 'china-union-pay',
    },
    method: 'ALIPAY',
    title: 'transfer_alipay',
    image: require('assets/images/operators/alipay.png'),
    order: 6,
  },
  SALARY: {
    icon: {
      SEND: "salary-payment",
      PAYOUT: "salary-payment",
    },
    method: "salary_payment",
    title: "salary_payment",
  },
  // CASH_CEBUANA: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'Cash Cebuana',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // E_ZWITCH: {
  //   icon: {
  //     SEND: 'e-zwich',
  //     PAYOUT: 'e-zwich',
  //   },
  //   method: 'E-Zwich',
  //   title: 'transfer_money_to_an_E_Zwich_payment_card',
  // },
  // CSB: {
  //   icon: {
  //     SEND: 'csb',
  //     PAYOUT: 'csb',
  //   },
  //   method: 'CSB',
  //   title: 'deposit_to_Sterling_Bank_of_Asia',
  // },
  // NP$: {
  //   icon: {
  //     SEND: 'np',
  //     PAYOUT: 'np',
  //   },
  //   method: 'NP$',
  //   title: 'USD_notify_and_pay',
  // },
  // COL: {
  //   icon: {
  //     SEND: 'col',
  //     PAYOUT: 'col',
  //   },
  //   method: 'COL',
  //   title: 'COL_financial',
  // },
  // BANK_ACCOUNT_PROVINCIAL: {
  //   icon: {
  //     SEND: 'bank',
  //     PAYOUT: 'bank-payout',
  //   },
  //   method: 'BTP',
  //   title: 'bank_to_bank_provincial',
  // },
  // CVE: {
  //   icon: {
  //     SEND: 'sve',
  //     PAYOUT: 'sve',
  //   },
  //   method: 'CVE',
  //   title: 'china_trust_visa_reloading',
  // },
  // DOOR_TO_DOOR: {
  //   icon: {
  //     SEND: 'dtd',
  //     PAYOUT: 'dtd',
  //   },
  //   method: 'DTD',
  //   title: 'DOOR_TO_DOOR_METRO_MANILA',
  // },
  // DOOR_TO_DOOR_PROVINCIAL: {
  //   icon: {
  //     SEND: 'dtd',
  //     PAYOUT: 'dtd',
  //   },
  //   method: 'DTP',
  //   title: 'DOOR_TO_DOOR_PROVINCIAL',
  // },
  // MC$: {
  //   icon: {
  //     SEND: 'mc',
  //     PAYOUT: 'mc',
  //   },
  //   method: 'MC$',
  //   title: 'MM_US_CASH_DOOR_TO_DOOR',
  // },
  // NTF: {
  //   icon: {
  //     SEND: 'ntf',
  //     PAYOUT: 'ntf',
  //   },
  //   method: 'NTF',
  //   title: 'NOTIFY_AND_PAY_PICK_UP_AT_FOREIGN',
  // },
  // OB$: {
  //   icon: {
  //     SEND: 'ob',
  //     PAYOUT: 'ob',
  //   },
  //   method: 'OB$',
  //   title: 'US_CREDIT_TO_OTHER_BANK_ACCOUNT',
  // },
  // SDF: {
  //   icon: {
  //     SEND: 'sdf',
  //     PAYOUT: 'sdf',
  //   },
  //   method: 'SDF',
  //   title: 'STERLING_DOLLAR_FOREIGN',
  // },
  // SVD: {
  //   icon: {
  //     SEND: 'svd',
  //     PAYOUT: 'svd',
  //   },
  //   method: 'SVD',
  //   title: 'STERLING_DOLLAR_RELOADING',
  // },
  // SVE: {
  //   icon: {
  //     SEND: 'sve',
  //     PAYOUT: 'sve',
  //   },
  //   method: 'SVE',
  //   title: 'STERLING_VISA_RELOADING',
  // },
  // BANK_ACC_AL_ARAFAH_ISLAMI: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'bank_deposit_Al_Arafah_Islami',
  //   title: 'deposit_to_bank_account',
  // },
  // BANK_ACCOUNT_PREMIER: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'Bank_Deposit_Premier',
  //   title: 'deposit_to_bank_account',
  // },
  // BANK_ACCOUNT_PUBALI: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'bank_deposit_Pubali',
  //   title: 'deposit_to_bank_account',
  // },
  // BANK_ACC_SOUTH_EAST: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'bank_deposit_South_East',
  //   title: 'deposit_to_bank_account',
  // },
  // CASH_AL_ARAFAH_ISLAMI: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'cash_Al_Arafah_Islami',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // CASH_PREMIER: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'cash_Premier',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // CASH_PUBALI: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'cash_Pubali',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // CASH_SOUTH_EAST: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'cash_South_East',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // CASH_UTTARA: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'cash_Uttara',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // QF_BANK_DEPOSIT: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'bank_deposit',
  //   title: 'deposit_to_bank_account',
  // },
  // MOBILE_WALLET: {
  //   icon: {
  //     SEND: 'wallet1',
  //     PAYOUT: 'wallet1',
  //   },
  //   method: 'mobile_wallet',
  //   title: 'transfer_funds_to_the_receivers_mobile_phone_number',
  // },
  // BANK_ACCOUNT_BDO: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'Bank Deposit (BDO)',
  //   title: 'Bank Deposit (BDO)',
  // },
  // CASH_BDO: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'Cash BDO',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
  // CASH_TO_CREDIT: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'cash_to_credit',
  //   title: 'load_money_to_card',
  //   order: 2,
  // },
  // CASH_CARD: {
  //   icon: {
  //     SEND: 'card',
  //     PAYOUT: 'card-payout',
  //   },
  //   method: 'cash_to_credit',
  //   title: 'load_money_to_card',
  //   order: 2,
  // },
  // CASH_MLLHUILER: {
  //   icon: {
  //     SEND: 'cash',
  //     PAYOUT: 'cash-payout',
  //   },
  //   method: 'Cash Mllhuiller',
  //   title: 'cash_pick_up_from_any_of_our_affiliated_banks',
  // },
}

export const OPERATORS: { [key: string]: { label: string; image: any } } = {
  Ria: { label: 'Ria', image: require('assets/images/operators/ria.png') },
  Unistream: { label: 'Unistream', image: require('assets/images/operators/unistream.png') },
  Privatbank: { label: 'PrivatBank', image: require('assets/images/operators/privat.png') },
  KORONA: { label: 'Koronapay', image: require('assets/images/operators/korona.png') },
  KoronaPay: { label: 'Koronapay', image: require('assets/images/operators/korona.png') },
  Muthoot: { label: 'Muthoot', image: require('assets/images/operators/muthoot.png') },
  UPT: { label: 'UPT', image: require('assets/images/operators/upt.png') },
  'Domestic Payments': { label: 'Domestic Payments', image: require('assets/images/operators/domestic.png') },
  iRemit: { label: 'iRemit', image: require('assets/images/operators/iremit.png') },
  Contact: { label: 'Contact', image: require('assets/images/operators/contact.png') },
  Welsend: { label: 'Welsend', image: require('assets/images/operators/wellsend.png') },
  DTOne: { label: 'DTOne', image: require('assets/images/operators/dtone.png') },
  Wallet: { label: 'Wallet', image: require('assets/images/operators/wallet-info.png') },
  TransferTo: { label: 'TransferTo', image: require('assets/images/operators/transferto.png') },
  "Withdraw Wallet": { label: 'Withdraw Wallet', image: require('assets/images/operators/withdraw-wallet.png') },
  'Union Pay': { label: 'Union Pay', image: require('assets/images/china_union.png') },
  IntelExpress: { label: 'IntelExpress', image: require('../assets/images/operators/intelexpress.png') },
  CEBUANA: { label: 'CEBUANA', image: require('assets/images/operators/cebuana.png') },
  "STB Israel": { label: 'STB Israel', image: require('assets/images/operators/domestic.png') },
  "STB Union": { label: 'STB Union', image: require('assets/images/logo.png') },
  "STB Union 03": { label: 'STB Union', image: require('assets/images/logo.png') },
  "STB Union 04": { label: 'STB Union', image: require('assets/images/logo.png') },
  "Transfer to card": { label: 'Transfer to card', image: require('assets/images/logo.png') },
  MoneyGram: { label: 'MoneyGram', image: require('assets/images/operators/moneygram.png') },
  DFCC: {label: 'DFCC', image: require('assets/images/operators/dfcc.png')},
  'Visa commission': {label: 'Visa commission', image: require('assets/images/operators/visa-commission.png')},
  Kumari: {label: 'Kumari', image: require('assets/images/operators/kumari.png')},
  Tempo: {label: 'Tempo', image: require('assets/images/operators/tempo.png')},
  Kwickpay: { label: 'Kwikpay', image: require('assets/images/operators/kwikpay.png')},
  Kwikpay: { label: 'Kwikpay', image: require('assets/images/operators/kwikpay.png')},
  Multitransfer: { label: "Multitransfer", image: require("assets/images/operators/multitransfer.png")},
  FDH: { label: "FDH", image: require("assets/images/operators/FDH.png")},
}

export const MOBILE_OPERATORS: { [key: string]: { label: string; image: any } } = {
  Coins: {label: 'Coins.ph', image: require('assets/images/operators/coinsph.png')},
  GCash: {label: 'GCash', image: require('assets/images/operators/gcash.png')},
  'M-Pesa': {label: 'M-Pesa', image: require('assets/images/operators/m-pesa.png')},
  'Airtel Money': {label: 'Airtel Money', image: require('assets/images/operators/airtel.png')},
  'MTN Mobile Money': {label: 'MTN Mobile Money', image: require('assets/images/operators/mtn-momo.png')},
  'Tigo Money': {label: 'Tigo Money', image: require('assets/images/operators/tigo.png')},
  'Vodafone Money': {label: 'Vodafone Money', image: require('assets/images/operators/vodafone.png')},
  BKash: {label: 'BKash', image: require('assets/images/operators/bkash.png')},
  WechatPay: {label: 'WechatPay', image: require('assets/images/operators/wechatpay.png')},
  Amole: {label: 'Amole', image: require('assets/images/operators/amole.png')},
  AliPay: {label: 'AliPay', image: require('assets/images/operators/alipay.png')},
  'CBE Birr': {label: 'CBE Birr', image: require('assets/images/operators/cbe-birr.png')},
  Gizepay: {label: 'Gizepay', image: require('assets/images/operators/gizepay.png')},
  HelloCash: {label: 'HelloCash', image: require('assets/images/operators/hellopay.png')},
  TeleBirr: {label: 'TeleBirr', image: require('assets/images/operators/telebirr.png')},
  MonCash: {label: 'MonCash', image: require('assets/images/operators/moncash.png')},
  Dana: {label: 'Dana', image: require('assets/images/operators/dana.png')},
  GoPay: {label: 'GoPay', image: require('assets/images/operators/gopay.png')},
  LinkAja: {label: 'LinkAja', image: require('assets/images/operators/linkaja.png')},
  Ovo: {label: 'Ovo', image: require('assets/images/operators/ovo.png')},
  Paytren: {label: 'Paytren', image: require('assets/images/operators/paytren.png')},
  eSewa: {label: 'eSewa', image: require('assets/images/operators/e-sewa.png')},
  Bankplus: {label: 'Bankplus', image: require('assets/images/operators/bankplus.png')},
  'Aya Pay Wallet': {label: 'Aya Pay Wallet', image: require('assets/images/operators/aya-pay-wallet.png')},
  DFCC: {label: 'DFCC', image: require('assets/images/operators/dfcc.png')},
  'Moov Money Ivory Coast': {label: 'Moov Money Ivory Coast', image: require('assets/images/operators/moov-africa.png')},
  Movii: {label: 'Movii', image: require('assets/images/operators/movii.png')},
  'MTN Côte d’Ivoire': {label: 'MTN Mobile Money', image: require('assets/images/operators/mtn-cote-divoire.png')},
  Dinarak: {label: 'Bankplus', image: require('assets/images/operators/dinarak.png')},
  'Mared Wallet': {label: 'Mared Wallet', image: require('assets/images/operators/mared_wallet.png')},
  'Zain Cash': {label: 'Zain Cash', image: require('assets/images/operators/zain_cash.png')},
  UWallet: {label: 'UWallet', image: require('assets/images/operators/uwallet.png')},
  'Tigo Pesa': {label: 'Movii', image: require('assets/images/operators/tigo_pesa.png')},
  Meps: {label: 'Meps', image: require('assets/images/operators/meps.png')},
  Gadha: {label: 'Gadha', image: require('assets/images/operators/gadha.png')},
};

export const PAYIN_TYPES: TPayType = {
  CASH: {
    icon: {
      SEND: 'cash_payment',
      PAYOUT: 'cash_payment',
    },
    method: 'cash',
    title: 'cash_payment',
    description: 'cash_description',
    question: 'how_deposit_cash',
    order: 2,
  },
  CREDIT_CARD: {
    icon: {
      SEND: 'card_payment',
      PAYOUT: 'card_payment',
    },
    method: 'credit_debit_card',
    description: 'other_card_description',
    title: 'credit_card_title',
    question: 'how_deposit_card',
    order: 1,
  },
  BANK_TRANSFER: {
    icon: {
      SEND: 'bank_payment',
      PAYOUT: 'bank_payment',
    },
    method: 'bank_transfer_stb_union',
    title: 'bank_transfer_payment',
    description: 'bank_account_description_web',
    question: 'how_deposit_bank',
    order: 3,
  },
  CHECKOUT_CREDIT_CARD: {
    icon: {
      SEND: 'card_payment',
      PAYOUT: 'card_payment',
    },
    method: 'credit_debit_card',
    title: 'credit_debit_card',
  },
  IDEAL: {
    icon: {
      SEND: 'bank_payment',
      PAYOUT: 'bank_payment',
    },
    method: 'iDEAL bank',
    title: 'Bank Transfer via iDEAL Bank',
  },
  GOOGLE_PAY: {
    icon: {
      SEND: 'bank_payment',
      PAYOUT: 'bank_payment',
    },
    method: 'Google Pay system',
    title: 'Bank Transfer via Google Pay',
  },
  APPLE_PAY: {
    icon: {
      SEND: 'bank_payment',
      PAYOUT: 'bank_payment',
    },
    method: 'Apple Pay system',
    title: 'Bank Transfer via Apple Pay',
  },
  RAZORPAY: {
    icon: {
      SEND: 'bank_payment',
      PAYOUT: 'bank_payment',
    },
    method: 'RazorPay',
    title: 'Bank Transfer via RazorPay',
  },
  WALLET: {
    icon: {
      SEND: 'send_wallet',
      PAYOUT: 'payout',
    },
    method: 'payment_from_wallet',
    title: 'payment_from_wallet',
    description: 'payment_from_wallet',
  },
  SALARY: {
    icon: {
      SEND: "salary-payment",
      PAYOUT: "salary-payment",
    },
    method: "salary",
    title: "salary_payment",
    description: "salary_description",
    question: 'how_deposit_salary',
  },
}
