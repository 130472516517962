import React, { useCallback, useEffect, useState, KeyboardEvent, useRef } from 'react'
import {Typography, Box, Fade} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {get} from 'lodash'
import {useAppSelector, useAppDispatch} from 'redux/app.hooks'
import Input from 'components/Input'
import Button from 'components/Button'
import {closeModal} from 'redux/reducers/ui.reducer'
import {loginRequest} from 'redux/reducers/user.reducer'

import useStyles from './styles'

const LoginConfirmationContent = () => {
  const {classes} = useStyles()

  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const timer = useRef<number>(-1)
  const cursorValue = useRef<boolean>(true)

  const loginData = useAppSelector(({user}) => user.loginData)
  const verificationTimer = useAppSelector(({user}) => user.verificationTimer)

  const [code, setCode] = useState<string>('')
  const [cursor, setCursor] = useState<boolean>(false)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setCode('')
    } else if (e.target.value.length <= 6) {
      setCode(e.target.value)
    }
  }, [])

  useEffect(() => {
    timer.current = window.setInterval(() => {
      setCursor(cursorValue.current)
      cursorValue.current = !cursorValue.current
    }, 450)

    return () => {
      window.clearInterval(timer.current)
    }
  }, [])

  const handleCode = useCallback(() => {
    dispatch(
      loginRequest({
        verificationCode: code,
        password: get(loginData, 'password', ''),
        phoneNumber: get(loginData, 'phoneNumber', ''),
        phoneCountry: get(loginData, 'phoneCountry', ''),
        idNumber: get(loginData, 'idNumber', ''),
      })
    )
    dispatch(closeModal())
  }, [code, loginData])

  const handleResend = useCallback(() => {
    dispatch(
      loginRequest({
        password: get(loginData, 'password', ''),
        phoneNumber: get(loginData, 'phoneNumber', ''),
        phoneCountry: get(loginData, 'phoneCountry', ''),
        idNumber: get(loginData, 'idNumber', ''),
      })
    )
  }, [loginData])

  const handleKeyboard = useCallback(
    (e: KeyboardEvent) => {
      if (code.length === 6 && e.key === 'Enter') {
        handleCode()
      }
    },
    [code, handleCode]
  )

  return (
    <Box width={1}>
      <Typography variant={'h3'} align={'center'} className={classes.mainTitleMargin}>
        {t('main:phone_confirmation')}
      </Typography>
      <Typography variant={'subtitle2'} align={'center'}>
        {t('enter_code:we_have_sent_you_a_code_to_verify_your_phone_number_please_enter_code')}
      </Typography>
      <Box className="box-flex-center" my={1}>
        <Button disabled={verificationTimer > 0} onClick={handleResend} className={classes.textBtn}>
          {t('enter_code:resend')}
        </Button>
        <Typography variant={'subtitle2'}>
          {'('}{verificationTimer}{' '}{t('enter_code:sec_remain')}{')'}
        </Typography>
      </Box>
      <Box position={"relative"}>
        <Input
          autoFocus
          label={''}
          value={code}
          onChange={onChange}
          type={"number"}
          onKeyPress={handleKeyboard}
          sx={{ opacity: 0, zIndex: 3 }}
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={2} zIndex={1}
          position={"absolute"}
          top={22} left={0}
          right={0} bottom={0}
        >
          <Box className={classes.digitInput}>
            {!code[0] ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[0]}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[0] && !code[1]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[1] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[1] && !code[2]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[2] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[2] && !code[3]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[3] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[3] && !code[4]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[4] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[4] && !code[5]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[5] || ''}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={!code || code.length !== 6}
        className={classes.marginTop40}
        onClick={handleCode}
      >
        {t('main:confirm')}
      </Button>
    </Box>
  )
}

export default LoginConfirmationContent
