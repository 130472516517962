import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const Layout = () => {

  const navigate = useNavigate()

  return (
    <>
      <Outlet/>
    </>
  )
}

export default Layout
