
export interface IBankAccount {
  payoutType: string
  operatorId?: string
  country?: string
  accountNumber?: string
  bankName?: string
  bankCode?: string
  bankBranchCode?: string
  bankBranchName?: string
  accountNumberType?: string
  bankAddress?: string
  bankCity?: string
  bankDistrict?: string
  bankState?: string
  ezwitchNumber?: string
  ibanCode?: string
  ifscCode?: string
  sterlingCardNumber?: string
  swift?: string
  invoiceNumber?: string
  purpose?: string
}

export interface ICardAccount {
  id?: string
  operatorId: string
  payoutType: string
  country: string
  accountNumber?: string
  accountNumberMask?: string
}

export interface IRecipient {
  address: string
  bankAddress: string
  bankBranchCode: string
  bankBranchName: string
  bankCity: string
  bankCode: string
  bankDistrict: string
  bankName: string
  bankState: string
  city: string
  country: string
  dateOfBirth: string
  ezwitchNumber?: string
  firstName: string
  gender: string
  ibanCode: string
  idNumber: string
  idType: string
  ifscCode: string
  lastName: string
  middleName: string
  organizationId: string
  phoneCountry: string
  phoneNumber: string
  phone: string
  recentTransactions: any
  recipientId: string
  relationshipToSender: string
  senderId: string
  state: string
  sterlingCardNumber: string
  swift?: string
  zipCode: string
  bankAccount: IBankAccount
  bankAccounts: IBankAccount[]
  cardAccounts: ICardAccount[]
  originalFirstName: string
  originalLastName: string
  originalMiddleName: string
  edited?: string
}

export interface IRecipientState {
  recipientsList: IRecipient[]
  selectedRecipient: IRecipient | null
  loading: string
  deleteCardLoading?: string
}

export const initialState = (): IRecipientState => ({
  recipientsList: [],
  selectedRecipient: null,
  loading: 'idle',
})
