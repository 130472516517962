import React, { FC, useCallback } from 'react'
import { Typography, Box, Button, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {useAppDispatch} from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'

import useStyles from './styles'

const SessionExpired = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const close = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <Box width={1}>
      <Box className={classes.imageContainer}>
        <CardMedia
          component={"img"}
          src={require('assets/images/issue-card.png')}
          sx={{ width: 'auto', height: 100 }}
        />
      </Box>
      <Box mt={3.75} px={"12%"}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('errors:session_expired')}
        </Typography>
      </Box>
      <Box mt={2.5}>
        <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
          {t('info:log_stb_online')}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={5}>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={close}
        >
          {t('main:got_it')}
        </Button>
      </Box>
    </Box>
  )
}

export default (SessionExpired)
