import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link, Box, Typography } from '@mui/material'
import { useAppSelector } from 'redux/app.hooks'
import { termsAndConditionsUrl, privacyPolicyUrl, cookiePolicyUrl } from 'constants/configuration.constants'

import useStyles from './styles'

const Footer = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation('info')

  const { userId } = useAppSelector((state) => state.user)

  return (
    <Box className={classes.root}>
      <Box className={classnames(classes.box, classes.left, { [classes.unAuthLeft]: !!userId })}>
        <Typography variant="body2" color="inherit">
          {t('all_rights_reserved')} &#169; 2008
        </Typography>
        <Box className={classes.divider}/>
        <Typography variant="body2" color="inherit">
          {`${t('license')} № 56710`}
        </Typography>
      </Box>
      {!userId && (
        <Box className={classnames(classes.box, classes.center)}>
          <Link underline="none" variant="body2" color="inherit" href="https://stbunion.com/" target="_blank"
                classes={{ root: classes.centerLink }}>
            stbunion.com
          </Link>
          <Link underline="none" variant="body2" color="inherit" href="https://stbunion.com/partners" target="_blank"
                classes={{ root: classes.centerLink }}>
            {t('become_partner')}
          </Link>
          <Link underline="none" variant="body2" color="inherit" href="https://stbunion.com/faq" target="_blank"
                classes={{ root: classes.centerLink }}>
            FAQ
          </Link>
        </Box>
      )}
      <Box className={classnames(classes.box, classes.right)}>
        <Link
          underline="none" variant="body2"
          color="inherit" align="center"
          className={classes.rightLink}
          href={termsAndConditionsUrl(i18n.language)}
          target="_blank"
        >
          {t('settings:terms_and_conditions')}
        </Link>
        <Link
          underline="none" variant="body2"
          color="inherit" align="center"
          className={classes.rightLink}
          href={privacyPolicyUrl(i18n.language)}
          target="_blank"
        >
          {t('settings:privacy_policy')}
        </Link>
        <Link
          underline="none" variant="body2"
          color="inherit" align="center"
          className={classes.rightLink}
          href={cookiePolicyUrl(i18n.language)}
          target="_blank"
        >
          {t('settings:cookie_policy')}
        </Link>
      </Box>
      {/*<Box className={classnames(classes.box, classes.cookie)}>*/}
      {/*  <Link underline="none" variant="body2" color="inherit" align="center" className={classes.rightLink}*/}
      {/*        href="https://stbunion.com/cookie-policy" target="_blank">*/}
      {/*    {t('settings:cookie_policy')}*/}
      {/*  </Link>*/}
      {/*</Box>*/}
    </Box>
  )
}

export default (Footer)
