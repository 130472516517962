import {combineReducers} from '@reduxjs/toolkit'
import user from './user.reducer'
import recipients from './recipient.reducer'
import configuration from './configuration.reducer'
import ui from './ui.reducer'
import transactions from './transaction.reducer'
import transfer from './transfer.reducer'
import wallet from './wallet.reducer'

const createRootReducer = () =>
  combineReducers({
    user,
    recipients,
    transactions,
    transfer,
    configuration,
    wallet,
    ui,
  })

export default createRootReducer
