import React, { useCallback } from 'react'
import { Typography, Box, CardMedia, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'
import { setTransferData } from 'redux/reducers/transfer.reducer'
import { ITransferData } from 'model/transfer.model'

interface IDossierProps {
  type: string
  transferData: ITransferData
}

const DossierSuccess = ({ type, transferData }: IDossierProps) => {

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const onFinish = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()

    dispatch(closeModal())
    navigate('/dashboard')
  }, [navigate])

  const onContinue = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()

    dispatch(closeModal())

    if (transferData.payoutType === 'PAYOUT_TO_WALLET') {
      navigate('/payout-wallet')
    } else if (transferData.operatorId === '62617689-8ce2-468d-a303-26c1d4d47d58') {
      dispatch(setTransferData({ mode: 'previous' }))
      navigate('/withdraw')
    } else if (transferData.payoutType === 'WALLET_TO_WALLET') {
      dispatch(setTransferData({ mode: 'previous' }))
      navigate('/stb-payment')
    } else if (transferData.payoutType === 'WALLET_EXCHANGE') {
      dispatch(setTransferData({ mode: 'previous' }))
      navigate('/exchange-currency/exchange')
    } else if (transferData.operatorId === '881c4376-9a8a-4497-81f8-0e24699db02b') {
      dispatch(setTransferData({ mode: 'previous' }))
      navigate('/deposit');
    } else {
      dispatch(setTransferData({ mode: 'repeat' }))
      navigate('/world-transfers');
    }
  }, [navigate, type, transferData])

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}{'!'}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={10} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {type === 'KYC' ? t('questionnaire:thanks_answering') : t('compliance_failure:document_sent')}
        </Typography>

        <Box mt={3.75} className={"horizontalFlex"} justifyContent="center" gap={3.75}>
          <Button
            variant={"outlined"}
            color={"inherit"}
            size={"large"}
            onClick={onFinish}
          >
            {t('main:back_to_home')}
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={onContinue}
          >
            {t('transfer:start_transfer')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default DossierSuccess
