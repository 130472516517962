import React, {KeyboardEvent, useCallback, useState} from 'react'
import {Typography, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useAppSelector, useAppDispatch} from 'redux/app.hooks'
import Input from 'components/Input'
import Button from 'components/Button'
import {closeModal} from 'redux/reducers/ui.reducer'
import {setSignupDetails, signupRequest, sendVerificationCode} from 'redux/reducers/user.reducer'

import useStyles from './styles'

const SendVerificationContent = () => {
  const {classes} = useStyles()

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const verificationTimer = useAppSelector(({user}) => user.verificationTimer)

  const [code, setCode] = useState<string>('')

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value), [])

  const handleCode = useCallback(() => {
    dispatch(closeModal())
    dispatch(setSignupDetails({verificationCode: code}))
    dispatch(signupRequest())
  }, [code])

  const handleResend = useCallback(() => {
    dispatch(sendVerificationCode())
  }, [code])

  const handleKeyboard = useCallback(
    (e: KeyboardEvent) => {
      if (code.length === 4 && e.key === 'Enter') {
        handleCode()
      }
    },
    [code, handleCode]
  )

  return (
    <Box width={1}>
      <Typography variant={'h3'} align={'center'} className={classes.mainTitleMargin}>
        {t('enter_code:enter_code')}
      </Typography>
      <Typography variant={'subtitle2'} align={'center'}>
        {t('enter_code:we_have_sent_you_a_code_to_verify_your_phone_number_please_enter_code')}
      </Typography>
      <Box className="box-flex-center" my={1}>
        <Button disabled={verificationTimer > 0} onClick={handleResend} className={classes.textBtn}>
          {t('enter_code:resend')}
        </Button>
        <Typography variant={'subtitle2'}>
          {t('enter_code:available_2min', {
            min: verificationTimer > 60 ? '1' : '0',
            sec: verificationTimer % 60 > 9 ? (verificationTimer % 60).toString(10) : '0' + (verificationTimer % 60),
          })}
        </Typography>
      </Box>
      <Input autoFocus label={t('main:confirm_code')} value={code} onChange={onChange} onKeyPress={handleKeyboard}/>
      <Button color="primary" variant="contained" fullWidth disabled={!code || code.length !== 4}
              className={classes.marginTop40} onClick={handleCode}>
        {t('main:confirm')}
      </Button>
    </Box>
  )
}

export default SendVerificationContent
