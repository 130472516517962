import React, { useCallback } from 'react'
import {Typography, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
import { useAppDispatch } from 'redux/app.hooks'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { closeModal, openModal } from 'redux/reducers/ui.reducer'
import { rejectTransactionRequest } from 'redux/reducers/transaction.reducer'
import ConfirmTransaction from './ConfirmTransaction'

import useStyles from './styles'

const ConfirmRejectTransaction = () => {
  const {classes} = useStyles()

  const {t} = useTranslation()

  const dispatch = useAppDispatch()

  const cancelHandler = useCallback(() => {
    dispatch(openModal({
      type: ConfirmTransaction,
      props: {name: 'ConfirmTransaction'},
      paperClass: 'qr-code-paper-modal',
    }))
  }, [])

  const confirmHandler = useCallback(() => {
    dispatch(rejectTransactionRequest())
    dispatch(closeModal())
  }, [])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} sx={{color: 'designGrey.500', fontWeight: 700}}>
        {t('transaction:reject_transaction')}{'?'}
      </Typography>

      <Typography variant={'subtitle2'} align={'center'} sx={{color: 'designGrey.500', marginTop: 2.5}}>
        {t('info:by_closing_confirm_window')}
      </Typography>

      <Box width={1} className={"horizontalFlex"} justifyContent="center" mt={5} gap={3.75}>
        <Button
          color="inherit"
          size={"large"}
          variant="outlined"
          onClick={cancelHandler}
        >
          {t('main:back')}
        </Button>
        <Button
          color="secondary"
          size={"large"}
          variant="outlined"
          startIcon={<Icon icon={"cross"} size={10} color={"#EB5757"}/>}
          onClick={confirmHandler}
        >
          {t('main:cancel')}
        </Button>
      </Box>
    </Box>
  )
}

export default (ConfirmRejectTransaction)
