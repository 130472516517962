import { Box, Typography, TypographyVariant, SxProps } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import React, {useMemo} from "react";
import countryService from "../../services/country.service";

interface IAmount {
  variant: string
  withPlus?: boolean
  currency?: string
  amount?: number | string
  signs?: number
  className?: string
  sx?: SxProps
}

const AmountRow = ({ variant, currency = 'ILS', amount = 0, withPlus = false, signs = 2, className = '', sx = {} }: IAmount) => {
  const {typography} = useTheme();

  const numberParts = useMemo(() => {
    const fullNumber = Math.abs(Number(amount))
      .toFixed(signs)
      .replace(/\d(?=(\d{3})+\.)/g, "$& ");
    return fullNumber.split(".");
  }, [amount, signs]);

  const mainSize = Number((typography[variant as TypographyVariant].fontSize as string).replaceAll(/[^0-9^\.]/g, ''))
  const lineHeight = currency === "ILS" ? 1.08 : 1.1

  return (
    <Box display={"flex"} alignItems={"flex-end"}>
      {withPlus && (
        <Typography variant={variant as TypographyVariant} className={className} sx={{ ...sx, lineHeight: 1}}>
          +
        </Typography>
      )}
      {amount < 0 && (
        <Typography variant={variant as TypographyVariant} className={className} sx={{ ...sx, lineHeight: 1}}>
          -
        </Typography>
      )}
      <Typography
        variant={"subtitle2"}
        className={className}
        sx={{ ...sx, lineHeight, fontSize: currency === "ILS" ? mainSize * 16 * 0.9 : mainSize * 16 * 0.88 }}
      >
        {countryService.getCurrencySymbolByCurrencyCode(currency)}
      </Typography>
      <Box width={"1px"}/>
      <Typography variant={variant as TypographyVariant} className={className} sx={{ ...sx, lineHeight: 1}}>
        {numberParts[0]}{signs === 0 ? "" : "."}
      </Typography>
      <Typography variant={"subtitle2"} className={className} sx={{ ...sx, fontSize: mainSize * 16 * 0.85, lineHeight: 1.1}}>
        {numberParts[1]}
      </Typography>
    </Box>
  )
}

export default React.memo(AmountRow)
