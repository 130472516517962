import React, { useCallback } from 'react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'redux/app.hooks'
import { Validators, createValidationSchema } from 'services/validation.service'
import PhoneInput from 'components/PhoneInput'
import Button from 'components/Button'
import { validateNewPhoneRequest } from 'redux/reducers/user.reducer'

import useStyles from './styles'

const EnterNewPhone = () => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { phoneCountry } = useAppSelector(({ user }) => user.userMeta)
  const { errorMessage, spinner } = useAppSelector(({ user }) => user.changePhoneNumber)

  const formik = useFormik({
    initialValues: {
      phoneCountry,
      phoneNumber: '',
    },
    validationSchema: createValidationSchema({
      phoneCountry: Validators.required,
      phoneNumber: Validators.phoneNumber,
    }),
    onSubmit: (values) => {
      dispatch(validateNewPhoneRequest({
        phoneCountryCode: phoneCountry,
        phoneNumber: values.phoneNumber,
      }))
    },
  })

  const onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    formik.setFieldTouched('phoneNumber', false)
  }, [formik])

  const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    formik.setFieldTouched('phoneNumber', true)
  }, [formik])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} sx={{ fontWeight: 700, color: 'grey.500' }}>
        {t('enter_code:enter_new_phone')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <PhoneInput
          label={''}
          countries={[phoneCountry || 'IL']}
          countrySelectProps={{
            value: formik.values.phoneCountry,
          }}
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          error={formik.touched.phoneNumber ? formik.errors.phoneNumber || errorMessage || '' : ''}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <Box width={1} display="flex" justifyContent="center">
          <Button
            disabled={spinner}
            color="primary"
            variant="contained"
            className={classes.marginTop40}
            type="submit"
          >
            {t('main:confirm')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default EnterNewPhone
