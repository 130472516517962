import React, { useState, useCallback } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography"
import TextField, { TextFieldProps } from '@mui/material/TextField'
import countryService from 'services/country.service'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { FieldProps } from 'formik'
import Icon from '../Icon'
import InfoTooltip from '../InfoTooltip'

import useStyles from './styles'

export interface ISimpleInputProps extends Omit<InputBaseProps, 'classes' | 'error'> {
  error?: string | string[] | { message: string, params: object }
  title?: string
  label?: string
  focused?: boolean
  labelInfo?: string
}

export interface IAmountInputProps extends Omit<TextFieldProps, 'classes' | 'error'> {
  error?: string | string[] | { message: string, params: object }
  title?: string
  label?: string
  focused?: boolean
  labelInfo?: string
  currency: string
}

export interface IFormikInputProps extends ISimpleInputProps, FieldProps {
  focused?: boolean
  normalizer?: Function
}

const Input = ({
                 className = '',
                 error = '',
                 title,
                 label,
                 name,
                 type = 'text',
                 labelInfo = '',
                 focused,
                 ...props
               }: ISimpleInputProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation('validation')
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <FormControl
      error={!!error}
      fullWidth
      classes={{
        root: classnames(classes.root, className),
      }}
    >
      <Box className={classnames(classes.label, 'horizontalFlex')} gap={1.25}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        {!!labelInfo && <InfoTooltip title={t(labelInfo)} placement="top"/>}
      </Box>
      <InputBase
        id={name}
        name={name}
        type={type === 'password' && showPassword ? 'text' : type}
        endAdornment={
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end"
                          className={classes.passwordBtn}>
                <Icon icon={showPassword ? 'eye' : 'eye-off'} size={18} color={focused ? '#0090FF' : '#777C80'}/>
              </IconButton>
            </InputAdornment>
          ) : null
        }
        {...props}
      />
      <FormHelperText>
        {!error
          ? title || ''
          : (Array.isArray(error)
            ? error.reduce((res, chunk) => res + t(chunk), '')
            : typeof error === 'string' ? t(error) : t(error.message, error.params))
        }
      </FormHelperText>
    </FormControl>
  )
}

export const FormikInput = ({ className, label, title, field, form, ...meta }: IFormikInputProps) => {
  const { classes } = useStyles()

  const { name, value, onChange } = field
  const { errors, touched, setFieldTouched } = form
  const { type = 'text', normalizer, ...rest } = meta
  const [focused, setFocused] = useState<boolean>(false)

  const onFocus = useCallback(() => {
    setFieldTouched(name, false)
    setFocused(true)
  }, [name])

  const onBlur = useCallback(() => {
    setFieldTouched(name, true)
    setFocused(false)
  }, [name])

  return (
    <Input
      // classes={classes} ???
      label={label}
      title={title}
      className={className}
      error={touched[name] ? (errors[name] as string) : ''}
      type={type}
      name={name}
      value={value ? (normalizer ? normalizer(value) : value) : ''}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      focused={focused}
      {...rest}
    />
  )
}

export const AmountInput = ({
                              className = '',
                              error = '',
                              title,
                              label,
                              name,
                              labelInfo = '',
                              currency,
                              value,
                              focused,
                              ...props
                            }: IAmountInputProps) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  return (
    <FormControl
      error={!!error}
      fullWidth
      classes={{
        root: classnames(classes.amountRoot, className),
      }}
    >
      <Box className={classnames(classes.label, 'horizontalRow')}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        {!!labelInfo && (
          <Box px={1}>
            <InfoTooltip title={t(labelInfo)} placement="top"/>
          </Box>
        )}
      </Box>
      <TextField
        id={name}
        name={name}
        variant={"standard"}
        value={focused
          ? value
          : Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&, ')}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ marginRight: 0.25, paddingBottom: currency === 'ILS' ? 0 : '3px' }}
            >
              <Typography
                variant="h3"
                color={props.disabled ? 'grey.300' : 'textPrimary'}
                sx={{ fontWeight: 500, fontSize: '1.75rem' }}
              >
                {countryService.getCurrencySymbolByCurrencyCode(currency)}
              </Typography>
            </InputAdornment>
          )
        }}
        {...props}
      />
      <FormHelperText>
        {!error
          ? title || ''
          : (Array.isArray(error)
            ? error.reduce((res, chunk) => res + t(chunk), '')
            : typeof error === 'string' ? t(error) : t(error.message, error.params))
        }
      </FormHelperText>
    </FormControl>
  )

}

export default Input
