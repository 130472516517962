import { IRecipient } from 'model/recipient.model'

export interface IWalletAccount {
  id: number
  order: number
  accountNumber: string
  balance: number
  currency: string
  free: number
}

export interface ICardAccount {
  id: number
  order: number
  accountType: number
  accountNum: string
  availableBalance: number
  currency: string
}

export type TProductTariff = { [key: string]: { type: string, price: number, currency: string } }

export type TProductLimits = { [code: string]: { periodType: string, threshold: number, currency: string }[] }

export interface ICardProduct {
  cardProductId: string
  isVirtualCard: boolean
  cardImages: string[]
  currencies: string[]
  hasHiddenCurrencies: boolean
  title: string
  type: string
  monthlyCostPrice: number
  monthlyCostCurrency: string
  cardIssueFeePrice: number
  cardIssueFeeCurrency: string
  features: string[]
  tariff?: TProductTariff
  limits?: TProductLimits
}

export type TTotalDebts = { currency: string, amount: string }

export interface ICard {
  id: number
  pan: string
  sms: string
  productCaption: string
  productNameForCustomer: string
  productId: string
  status: string
  pcStatus: { status: string }
  expireDate: string
  accountsData: { [currency: string]: ICardAccount }
  cardAccountsInfo: ICardAccount[]
  walletAccountsData: { [currency: string]: IWalletAccount }
  walletId: number
  isVirtualCard: boolean
  isNeedActivation: boolean
  hasHiddenCurrencies: boolean
  totalDebts?: TTotalDebts
  debts?: any
}

export interface IWallet {
  id: number;
  walletNumber: string;
  accountsData: { [currency: string]: IWalletAccount }
  accounts: IWalletAccount[]
  walletCards: number[]
}

export type TRateTier = {
  maxAmount: number
  minAmount: number
  srcToDestFee: number
  srcToDestFeeType: string
  srcToDestRateWithFee: number
  srcToDestRateWithFeeView: number
}

export interface IExchangeRate {
  destinationCurrency: string;
  sourceCurrency: string;
  srcToDestRate: number;
  corridorRatesWithTiersData: TRateTier[];
}

export interface IQuestion {
  order: number
  answers: string[]
  answerType: string
  multiselect?: string
  questionCode: string
}

export interface IAnswer {
  answer: string | string[]
  answerType: string
  multiselect?: string
  questionCode: string
}

export interface IQuestionnaire {
  questionnaireName?: string
  questions?: IQuestion[]
  answers?: { [code: string]: IAnswer }
  questionnaireLoading?: string
}

export interface IMccGroup {
  id: string
  name: string
}

export interface IPaymentData {
  recipient?: Partial<IRecipient> | null
  recipientWallet: {[currency: string]: string} | null
  errorMessage?: string
}

export interface ICardRequest {
  agent: string | null
  issueType: string
  reason?: string
  cardId?: string | number
  cardIdForReissue?: string | number
  cardProductId: number
  comment?: string
  deliveryAddress?: string
  deliveryCity?: string
  deliveryTimeFrom?: string
  deliveryTimeTo?: string
  deliveryType: string
  id: string
  isCardNeeded: boolean
  isNotificationOn: boolean
  issueStatus: string
  phoneNumber: string
  publicId: string
  walletId: number
  timeCreated: string
}

export interface ICloseCardRequest {
  id: string
  publicId: string
  walletId: number
  cardId: number
  closeStatus: string
  reason: string
  cardProductName: string
  maskedPAN: string
  timeCreated: number
}

export interface IWalletState {
  wallets: IWallet[]
  cards: ICard[]
  exchangeRates: IExchangeRate[]
  questionnaire?: IQuestionnaire
  issueCard: {
    issueValues: Partial<ICardRequest>
    cardProducts: ICardProduct[]
    productLoading: string
    cardRequests: ICardRequest[]
    issueCardLoading: string
  }
  closeCardRequests: ICloseCardRequest[]
  mccGroups: IMccGroup[]
  paymentData?: IPaymentData
  payoutData?: any
  walletLoading: string
  cardLoading: string
  changeCardLoading: string
  cardDebtsLoading?: string
  paymentLoading?: string
  closeCardLoading: string
}

export const initialState = (): IWalletState => ({
  wallets: [],
  cards: [],
  exchangeRates: [],
  issueCard: {
    issueValues: {},
    cardProducts: [],
    productLoading: 'idle',
    cardRequests: [],
    issueCardLoading: 'idle'
  },
  closeCardRequests: [],
  mccGroups: [],
  walletLoading: 'idle',
  cardLoading: 'idle',
  changeCardLoading: 'idle',
  closeCardLoading: 'idle',
})

export interface IChangeStatusRequest {
  cardId: number
  status?: string
  reason?: string
}
