import * as validator from 'yup'
import { isValidBIC, isValidIBAN } from 'ibantools'
import moment from 'moment'
import cardValidator from 'card-validator'
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import { memoize } from 'lodash'
import countryService from './country.service'
import { checkUserExist, checkPhoneExist, checkEmailExist } from './apiServices/user.service'

const userExistAsyncValidator = memoize(
  async (phoneNumber: string, phoneCountry: string, idNumber: string) => {
    const code = phoneCountry === 'KV'
      ? 'XK'
      : phoneCountry === 'AN' ? 'BQ' : phoneCountry;
    const phone = parsePhoneNumber(phoneNumber.trim(), code as CountryCode)
    if (!phone || !phone.number) {
      throw new Error('validation:phone_number_is_invalid')
    }
    const fullNumber = countryService.getPhonePrefix(phoneCountry) + phoneNumber.trim()
    const validInternational =
      phone.formatInternational().replace(/\s+/g, '') === fullNumber.replace(/\s+/g, '')

    if (!phone.isValid() || !validInternational) {
      const condition = phoneNumber.length > 8 && phoneNumber[0] === '0'
      if (condition) {
        throw new Error('validation:phone_number_international_invalid')
      }
      throw new Error('validation:phone_number_is_invalid')
    }

    const payload = {
      idNumber,
      phoneCountry,
      phoneNumber: fullNumber,
    }

    const { data } = await checkUserExist(payload)

    return data.result
  },
  (...args) => JSON.stringify(args)
)

export const phoneExistAsyncValidator = memoize(
  async (phoneNumber: string, phoneCountry: string) => {
    const code = phoneCountry === 'KV'
      ? 'XK'
      : phoneCountry === 'AN' ? 'BQ' : phoneCountry;
    const phone = parsePhoneNumber(phoneNumber.trim(), code as CountryCode)
    if (!phone || !phone.number) {
      throw new Error('validation:phone_number_is_invalid')
    }
    const fullNumber = countryService.getPhonePrefix(phoneCountry) + phoneNumber.trim()
    const validInternational =
      phone.formatInternational().replace(/\s+/g, '') === fullNumber.replace(/\s+/g, '')

    if (!phone.isValid() || !validInternational) {
      const condition = phoneNumber.length > 8 && phoneNumber[0] === '0'
      if (condition) {
        throw new Error('validation:phone_number_international_invalid')
      }
      throw new Error('validation:phone_number_is_invalid')
    }

    const payload = {
      organizationId: process.env.REACT_APP_ORGANIZATION_ID,
      country: phoneCountry,
      phoneNumber: fullNumber,
    }
    const { data } = await checkPhoneExist(payload)
    return data.exists
  },
  (...args) => JSON.stringify(args)
)

const emailExistAsyncValidator = memoize(
  async (email: string) => {
    const payload = {
      email,
      organizationId: process.env.REACT_APP_ORGANIZATION_ID,
    }
    const { data } = await checkEmailExist(payload)

    return !data.exists
  },
  (...args) => JSON.stringify(args)
)

export const Validators = {
  firstName: validator
    .string()
    .min(2, () => ({ message: 'at_least_characters', params: { qty: 2 } }))
    .max(100)
    .required('required')
    .matches(/^[A-Za-z-\s]*$/, { excludeEmptyString: true, message: 'must_contain_only_letters' })
    .nullable(),
  lastName: validator
    .string()
    .min(2, () => ({ message: 'at_least_characters', params: { qty: 2 } }))
    .max(100)
    .required('required')
    .matches(/^[A-Za-z-\s]*$/, { excludeEmptyString: true, message: 'must_contain_only_letters' })
    .nullable(),
  middleName: validator
    .string()
    .notRequired()
    .matches(/^[A-Za-z-\s]*$/, { excludeEmptyString: true, message: 'must_contain_only_letters' })
    .nullable(),
  city: validator
    .string()
    .min(2, () => ({ message: 'at_least_characters', params: { qty: 2 } }))
    .max(100)
    .required('required')
    .matches(/^[A-Za-z-\s]*$/, { excludeEmptyString: true, message: 'must_contain_only_letters' })
    .nullable(),
  address: validator
    .string()
    .min(3, () => ({ message: 'at_least_characters', params: { qty: 3 } }))
    .max(100)
    .required('required') // must_contain_letters_or_numbers
    .matches(/^[A-Za-z0-9-\,\s]*$/, { excludeEmptyString: true, message: 'must_contain_letters_or_numbers' })
    .nullable(),
  zipCode: validator
    .string()
    .min(3, () => ({ message: 'at_least_characters', params: { qty: 3 } }))
    .max(30)
    .notRequired()
    .matches(/^[A-Za-z0-9\s]*$/, { excludeEmptyString: true, message: 'must_contain_letters_or_numbers' })
    .nullable(),
  required: validator.string().required('required').nullable(),
  notRequired: validator.string().notRequired().nullable(),
  boolean: validator.boolean(),
  agreed: validator
    .boolean()
    .nullable()
    .test('agreed', 'must_accept_Terms_and_Conditions', (value) => value === true),
  selfie: validator
    .boolean()
    .nullable()
    .test('selfie', 'errors:take_selfie', (value) => value === true),
  idNumber: validator
    .string()
    .min(5, 'id_number_must_be_at_least_5_characters')
    .required('required')
    .matches(/^[a-zA-Z0-9_]*$/, { excludeEmptyString: true, message: 'invalid_id' })
    .nullable(),
  idSerialNumber: validator.string().max(3).notRequired().nullable(),
  phoneNumber: validator.string()
    .required('required')
    .test({
      name: 'phoneNumber',
      exclusive: false,
      message: ({ value }) => {
        if (!!value && value.length > 8 && value[0] === '0') {
          return 'phone_number_international_invalid'
        }
        return 'phone_number_is_invalid'
      },
      test: function (number, context) {
        const countryCode = context.parent.phoneCountry
        try {
          if (!number) {
            return true
          }
          const code = countryCode === 'KV'
            ? 'XK'
            : countryCode === 'AN' ? 'BQ' : countryCode;
          const phone = parsePhoneNumber(number, code)
          if (!phone || !phone.number) {
            throw new Error('validation:phone_number_is_invalid')
          }
          if (phone.isValid()) {
            const fullNumber = countryService.getPhonePrefix(countryCode) + number
            return phone.formatInternational().replace(/\s+/g, '') === fullNumber.replace(/\s+/g, '')
          }
          return false
        } catch (err) {
          return false
        }
      },
    }),
  password: validator
    .string()
    .min(8, () => 'password_must_match_the_parameters')
    .required('required')
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/, {
      excludeEmptyString: true,
      message: 'password_must_match_the_parameters'
    })
    .nullable(),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ ~`!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/,
  //   i18n.t('validation:password_must_match_the_parameters')
  // ),
  newPassword: validator
    .string()
    .notRequired()
    .min(8, () => 'password_must_match_the_parameters')
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/, {
      excludeEmptyString: true,
      message: 'password_must_match_the_parameters'
    })
    .nullable(),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ ~`!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/,
  //   i18n.t('validation:password_must_match_the_parameters')
  // ),
  userExist: validator
    .string()
    .required('required')
    .test({
      name: 'checkUser',
      message: 'such_phone_number_already_exist',
      test: async (value, context) => {
        try {
          const result = await userExistAsyncValidator(value || '', context.parent.phoneCountry, context.parent.idNumber)
          // DATA_MISMATCH || EXISTS || NOT_FOUND
          if (result === 'NOT_FOUND') {
            return true
          } else if (result === 'EXISTS') {
            throw new Error('such_phone_number_already_exist')
          }

          return false
        } catch (err) {
          return context.createError({
            path: context.path,
            message: err.message,
          })
        }
      },
    }),
  phoneNumberExist: validator
    .string()
    .required('required')
    .test({
      name: 'checkPhoneNumber',
      message: 'such_phone_number_already_exist',
      test: async (value, context) => {
        try {
          return await phoneExistAsyncValidator(value || '', context.parent.phoneCountry)
        } catch (err) {
          return context.createError({
            path: context.path,
            message: err.message,
          })
        }
      },
    }),
  emailExist: validator
    .string()
    .required('required')
    .nullable()
    .test({
      name: 'emailExist',
      message: 'such_email_already_exist',
      test: async (value, context) => {
        if (context.parent.userEmail && context.parent.userEmail === value) {
          return true
        }
        try {
          const valid = await validator.string().email().isValid(value)
          if (!valid) {
            throw new Error('enter_your_email_in_format')
          }
          const result = await emailExistAsyncValidator(value || '')
          return result
        } catch (err) {
          return context.createError({
            path: context.path,
            message: err.message,
          })
        }
      },
    }),
  equalTo: (pathCompare: string, message?: string) =>
    validator.string().test({
      name: 'equalTo',
      message: message || 'must_be_the_same_as',
      test: (value, context) => {
        return value === context.parent[pathCompare]
      },
    }),
  oldPassword: validator.string().test({
    name: 'oldPassword',
    message: 'required',
    test: (value, context) => {
      if (!!context.parent.newPassword) {
        return !!value && value.length > 5
      }
      return true
    },
  }),
  dateInPast: validator.string().test({
    name: 'dateInPast',
    message: 'dateInPast_is_invalid',
    test: (value) => {
      return moment(value, 'YYYY-MM-DD').isBefore(moment())
    },
  }),
  dateInFuture: validator.string().test({
    name: 'dateInFuture',
    message: 'dateInFuture_is_invalid',
    test: (value) => {
      return moment(value, 'YYYY-MM-DD').isAfter(moment())
    },
  }),
  dateOfBirth: validator
    .string()
    .required('required')
    .test({
      name: 'dateOfBirth',
      message: () => ['you_must_be_at_least', ' 16 ', 'years_old'],
      test: (value) => {
        const dateOfBirth = moment(value, 'YYYY-MM-DD')
        const minRequiredDateOfBirth = moment().subtract(16, 'years')
        return value ? minRequiredDateOfBirth.isAfter(dateOfBirth) : true
      },
    }),
  iban: validator
    .string()
    .required('required')
    .test({
      name: 'iban',
      message: () => 'IBAN is incorrect',
      test: (value) => isValidIBAN(value as string),
    }),
  card: validator
    .string()
    .required('required')
    .test({
      name: 'card',
      message: 'validation:card_number_is_invalid',
      test: value => {
        if (!value || value.length < 6) return false;
        const cardData = cardValidator.number(value);
        const uzcardRegex = /^86\d{14}$/i;
        return (cardData && cardData.isValid) || uzcardRegex.test(value.replace(/\s/g, ''));
      },
    }),
  masterCard: validator
    .string()
    .required('required')
    .test({
      name: 'masterCard',
      test: function (value) {
        if (!value || value.length < 6) {
          return this.createError({
            path: this.path,
            message: "validation:card_number_is_invalid",
          });
        }

        const { isValid, card } = cardValidator.number(value);

        if (!isValid && card?.type === "mastercard") {
          return this.createError({
            path: this.path,
            message: "validation:card_number_is_invalid",
          });
        } else if (card?.type !== "mastercard") {
          return this.createError({
            path: this.path,
            message: "validation:only_mastercard",
          });
        }
        return true;
      },
    }),
  cardMonth: validator
    .string()
    .required('required')
    .test({
      name: 'cardMonth',
      message: 'invalid_month',
      test: value => cardValidator.expirationMonth(value).isValid,
    }),
  cardYear: validator
    .string()
    .required('required')
    .test({
      name: 'cardYear',
      message: 'invalid_year',
      test: value => cardValidator.expirationYear(value, 50).isValid,
    }),
}

export const Validator = validator

export const createValidationSchema = (obj: { [key: string]: validator.BaseSchema }) => validator.object().shape(obj)
