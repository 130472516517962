import React, { useCallback } from 'react'
import { IconButton } from '@mui/material'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import Slide, { SlideProps } from '@mui/material/Slide'
import classnames from 'classnames'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { useTranslation } from 'react-i18next'
import { closeToast } from 'redux/reducers/ui.reducer'
import { TransitionProps } from '@mui/material/transitions'
import Icon from 'components/Icon'
import useStyles from './styles'

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left"/>
}

const Toast = ({ ...props }: SnackbarProps) => {
  const { classes } = useStyles()

  const { toast, toastMessage, toastType, messageOpts } = useAppSelector(({ ui }) => ui)
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const close = useCallback(() => {
    dispatch(closeToast())
  }, [])

  return (
    <Snackbar
      open={toast}
      message={Array.isArray(toastMessage)
        ? toastMessage.reduce((acc, msg, idx) => acc + (idx > 0 ? '\n' : '') + t(msg), '')
        : t(toastMessage, messageOpts || {})
      }
      className={classnames({
        [classes.success]: toastType === 'success',
        [classes.error]: toastType === 'error',
      })}
      autoHideDuration={5500}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => dispatch(closeToast())}
      TransitionComponent={SlideTransition}
      action={
        <IconButton onClick={close}>
          <Icon icon="cross" size={11}/>
        </IconButton>
      }
      {...props}
    />
  )
}

export default Toast
