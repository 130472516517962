import React, { FC, useCallback, useRef, useEffect, useState } from 'react'
import { Box, Grid, CardMedia, Typography, Link, useMediaQuery } from '@mui/material'
import classnames from 'classnames'
import Header from 'features/UnauthHeader'
import Footer from 'features/Footer'
import { useTranslation } from 'react-i18next'
import { FIREBASE_DEEP_LINK } from 'constants/configuration.constants'

import useStyles from './styles'

const AdaptiveWarning = () => {

  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  return (
    <Box className={classes.root}>
      <Header position={'static'} checkProfile={false}/>
      <Box className={classes.containerRoot}>
        <Box className={classes.titleWrap}>
          <Typography variant="title2" className={classes.title}>
            {t('main:login_to')}{' '}
          </Typography>
          <Typography variant="title2" className={classes.title}>
            {t('main:online_banking')}
          </Typography>
        </Box>
        <Box className={"horizontalFlex"} my={3.75}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('info:adaptive_web_info')}
          </Typography>
        </Box>
        <Box className={"horizontalFlex"} justifyContent={"center"}>
          <Link href={FIREBASE_DEEP_LINK} target="_blank" underline="none">
          <Box className={classes.storeBtn}>
            <CardMedia
              component="img"
              src={require('assets/images/ios-square.png')}
              className={classes.storeImage}
            />
            <Typography variant="body1" sx={{ color: 'grey.500', fontWeight: 500 }}>
              App Store
            </Typography>
          </Box>
          </Link>
          <Link href={FIREBASE_DEEP_LINK} target="_blank" underline="none">
            <Box className={classes.storeBtn}>
              <CardMedia
                component="img"
                src={require('assets/images/google-square.png')}
                className={classes.storeImage}
              />
              <Typography variant="body1" sx={{ color: 'grey.500', fontWeight: 500 }}>
                Google Play
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AdaptiveWarning
