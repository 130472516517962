import React, { FC } from 'react'
import { Typography, Box, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChatButton, WhatsAppButton } from 'components/Button'
import {phoneSupport, phoneSupport2} from 'constants/configuration.constants'

import useStyles from './styles'

interface IWalletInfo {
  israelTax: boolean
}

const BreakOpenWallet: FC<IWalletInfo> = ({ israelTax }) => {
  const { classes } = useStyles()

  const { t } = useTranslation(['wallets'])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} className={classes.mobileTitle}>
        {t(`questionnaire:${israelTax ? 'TAX_RESIDENT_INFO' : 'USA_TAX_RESIDENT'}`)}
      </Typography>
      <Box color={"designGrey.500"} mt={2.5} mb={5}>
        <Typography variant={'subtitle2'} align={'center'} color={"inherit"}>
          {t('questionnaire:contact_open_wallet')}
        </Typography>
      </Box>
      <Typography variant={'h6'} className={classes.subtitle}>
        {t('transaction:contact_us')}
      </Typography>
      <Box className="horizontalFlex" justifyContent={"center"} gap={3.75}>
        <ChatButton />
        <WhatsAppButton />
      </Box>
      <Box className="horizontalFlex" justifyContent={"center"} fontWeight={600} mt={3.5}>
        <Typography variant={'body1'} color={"textPrimary"}>
          {phoneSupport}
        </Typography>
        <Box
          width={"1px"}
          height={16}
          bgcolor={"designGrey.300"}
          mx={2}
        />
        <Typography variant={'body1'} color={"textPrimary"}>
          {phoneSupport2}
        </Typography>
      </Box>
    </Box>
  )
}

export default (BreakOpenWallet)
