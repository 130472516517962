import React, { FC, useEffect, useCallback } from 'react'
import { AppBar, Toolbar, Typography, CardMedia, Box, useMediaQuery } from '@mui/material'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import logo from 'assets/images/logo-row.svg'
import Icon from 'components/Icon'
import { WhatsAppSquare, IntercomSquare } from 'components/Button'
import { LanguageSelect } from 'components/Select'
import { phoneSupport } from 'constants/configuration.constants'

import useStyles from './styles'

interface IHeaderProps {
  position: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative'
  checkProfile: boolean
}

const UnauthHeader: FC<IHeaderProps> = ({ position, checkProfile }) => {
  const { classes } = useStyles()

  const match680 = useMediaQuery('(min-width:680px)')
  const match540 = useMediaQuery('(min-width:540px)')
  const navigate = useNavigate()

  const redirectStart = useCallback(() => {
    if (!checkProfile) {
      navigate('/start')
    }
  }, [navigate, checkProfile])

  return (
    <AppBar position={position} color={position === 'static' ? 'transparent' : 'default'}
            className={classes.headerRoot}>
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <CardMedia image={logo} classes={{ root: classes.mediaRoot }} onClick={redirectStart}/>
        <Box className={classes.rightHeader}>
          <Box className={classes.phoneRow}>
            {match680 && <Icon icon="number1" size={16}/>}
            {match680 ? (
              <Typography variant="body1" className={classnames(classes.phoneNumber)}>
                {`${phoneSupport.slice(0, 4)}-${phoneSupport.slice(4, 6)}-${phoneSupport.slice(6, 10)}-${phoneSupport.slice(10)}`}
              </Typography>
            ) : null}
          </Box>
          <Box className={"horizontalFlex"} gap={2.5}>
            <IntercomSquare/>
            {match540 && <WhatsAppSquare/>}
          </Box>
          <LanguageSelect className={classes.langSelect}/>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default UnauthHeader
