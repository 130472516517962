import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/he'
import 'moment/locale/ka'
import 'moment/locale/hi'
import 'moment/locale/si'
import 'moment/locale/ro'
import 'moment/locale/zh-cn'
import 'moment/locale/th'
import 'moment/locale/en-gb'
import 'moment/locale/fil'
import 'moment/locale/uk'

const resources = {
  en: require('./en.json'),
  ru: require('./ru.json'),
  he: require('./he.json'),
  uk: require('./uk.json'),
  zh: require('./zh.json'),
  sin: require('./sin.json'),
  // geo: require('./geo.json'),
  // fil: require('./fil.json'),
  // mol: require('./mol.json'),
  // th: require('./th.json'),
  // zh: require('./zh.json'),
  // hin: require('./hin.json'),
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    supportedLngs: Object.keys(resources),
    keySeparator: ' ',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      wait: true,
    }
  })
  .then(i18n => {
    moment.locale(i18next.languagesMap[i18next.language].moment)
  })
  .catch(err => console.log(err.message))

i18next.languagesMap = {
  he: {
    country: 'IL',
    label: 'עבר',
    language: 'he',
    moment: 'he',
  },
  en: {
    country: 'US',
    label: 'Eng',
    language: 'en',
    moment: 'en-gb',
  },
  ru: {
    country: 'RU',
    label: 'Рус',
    language: 'ru',
    moment: 'ru',
  },
  uk: {
    country: 'UA',
    label: 'Укр',
    language: 'uk',
    moment: 'uk',
  },
  zh: {
    country: 'CN',
    label: '中文',
    language: 'zh',
    moment: 'zh-cn',
  },
  sin: {
    country: 'LK',
    label: 'සිංහල',
    language: 'sin',
    moment: 'si',
    select: 'SIN',
  },
  // geo: {
  //   country: 'GE',
  //   label: 'ქართული',
  //   language: 'geo',
  //   moment: 'ka',
  //   select: 'GEO',
  // },
  // mol: {
  //   country: 'MD',
  //   label: 'Moldovean',
  //   language: 'mol',
  //   moment: 'ro',
  //   select: 'MOL',
  // },
  // hin: {
  //   country: 'IN',
  //   label: 'हिन्दी',
  //   language: 'hin',
  //   moment: 'hi',
  //   select: 'HIN',
  // },
  // fil: {
  //   country: 'PH',
  //   label: 'Pilipinas',
  //   language: 'fil',
  //   moment: 'fil',
  //   select: 'FIL',
  // },
  // sin: {
  //   country: 'LK',
  //   label: 'සිංහල',
  //   language: 'sin',
  //   moment: 'si',
  //   select: 'SIN',
  // },
  // th: {
  //   country: 'TH',
  //   label: 'ไทย',
  //   language: 'th',
  //   moment: 'th',
  //   select: 'THA',
  // },
}

i18next.on('languageChanged', lng => {
  moment.locale(i18next.languagesMap[lng].moment)
})

export default i18next
