import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(({ breakpoints, palette, spacing, isHebrew }) => ({
  headerRoot: {
    boxShadow: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: palette.designGrey['100'],
    backgroundColor: palette.background.default,
  },
  toolbarRoot: {
    height: 84,
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: 'space-between',
    direction: isHebrew ? 'rtl' : 'ltr',
    [breakpoints.down(768)]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    [breakpoints.down(620)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [breakpoints.down(480)]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  mediaRoot: {
    width: 160,
    height: 46,
    cursor: 'pointer',
    [breakpoints.down(620)]: {
      width: 120,
      height: 34.5,
    },
    [breakpoints.down(520)]: {
      width: 110,
      height: 31.6,
    },
  },
  rightHeader: {
    color: palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    gap: 50,
    [breakpoints.down(680)]: {
      gap: 40,
    },
    [breakpoints.down(520)]: {
      gap: 30,
    },
  },
  leftMargin20: {
    marginLeft: 20,
  },
  rightMargin50: {
    marginRight: 50,
    [breakpoints.down(768)]: {
      marginRight: 20,
    },
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    direction: 'ltr',
  },
  langSelect: {
    width: 110,
    '& .MuiSelect-select.MuiInputBase-input': {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
  },
  phoneNumber: {
    color: palette.text.primary,
    fontWeight: 600,
  },
}))

export default useStyles
