import React, { memo } from 'react'
import { Typography, Box, SxProps } from '@mui/material'
import Icon from 'components/Icon'
import AmountRow from 'components/AmountRow'
import classnames from 'classnames'

interface ICurrencyBadgeProps {
  size?: number
}

interface ICurrencySignProps {
  size?: number
  currency: string
}

interface IAccountProps {
  amount: number | string
  currency: string
  className?: string
  sx?: SxProps
  selected?: boolean
}

export const ShekelSquareSign = ({ size = 30 }: ICurrencyBadgeProps) => {
  return (
    <Box
      width={size} height={size}
      borderRadius={2}
      className={classnames('box-flex-center', 'currencySign')}
      style={{ background: 'linear-gradient(90deg, #1D97F3 0%, #4FF5F5 100%)' }}
    >
      <Icon icon="shekel" size={size * 0.42} color="#fff"/>
    </Box>
  )
}

export const DollarSquareSign = ({ size = 30 }: ICurrencyBadgeProps) => {
  return (
    <Box
      width={size} height={size}
      borderRadius={2}
      className={classnames('box-flex-center', 'currencySign')}
      style={{ background: 'linear-gradient(90deg, #00BFBF 0%, #94FFB6 100%)' }}
    >
      <Icon icon="dollar" size={size * 0.44} color="#fff"/>
    </Box>
  )
}

export const EuroSquareSign = ({ size = 30 }: ICurrencyBadgeProps) => {
  return (
    <Box
      width={size} height={size}
      borderRadius={2}
      className={classnames('box-flex-center', 'currencySign')}
      style={{ background: 'linear-gradient(90deg, #526DFF 0%, #DCBCFF 100%)' }}
    >
      <Icon icon="euro1" size={size * 0.45} color="#fff"/>
    </Box>
  )
}

export const CurrencySquareSign = ({ currency, size }: ICurrencySignProps) => {
  if (currency === 'ILS') {
    return <ShekelSquareSign size={size}/>
  } else if (currency === 'USD') {
    return <DollarSquareSign size={size}/>
  } else if (currency === 'EUR') {
    return <EuroSquareSign size={size}/>
  }
  return <ShekelSquareSign size={size}/>
}

export const ShekelBigSign = () => {
  return (
    <Box
      width={85} height={50}
      borderRadius={2.5}
      padding={1.25}
      className={classnames('verticalFlex', 'currencySign')}
      justifyContent={"space-between"}
      style={{ background: 'linear-gradient(104.95deg, #1D97F3 0%, #4FF5F5 100%)' }}
    >
      <Icon icon="shekel" size={11} color="#fff"/>
      <Box
        width={40} height={10}
        borderRadius={"5px"}
        bgcolor={"info.dark"}
      />
    </Box>
  )
}

export const DollarBigSign = () => {
  return (
    <Box
      width={85} height={50}
      borderRadius={2.5}
      padding={1.25}
      className={classnames('verticalFlex', 'currencySign')}
      justifyContent={"space-between"}
      style={{ background: 'linear-gradient(104.95deg, #00BFBF 0%, #94FFB6 100%)' }}
    >
      <Icon icon="dollar" size={13} color="#fff"/>
      <Box
        width={40} height={10}
        borderRadius={"5px"}
        bgcolor={"#BCF2F2"}
      />
    </Box>
  )
}

export const EuroBigSign = () => {
  return (
    <Box
      width={85} height={50}
      borderRadius={2.5}
      padding={1.25}
      className={classnames('verticalFlex', 'currencySign')}
      justifyContent={"space-between"}
      style={{ background: 'linear-gradient(104.95deg, #526DFF 0%, #DCBCFF 100%)' }}
    >
      <Icon icon="euro1" size={13} color="#fff"/>
      <Box
        width={40} height={10}
        borderRadius={"5px"}
        bgcolor={"#CBD2F3"}
      />
    </Box>
  )
}

export const AccountData = memo(({ amount, currency, className, sx, t }: IAccountProps & { t: Function }) => {

  return (
    <Box className={classnames('horizontalRow', className)} sx={sx}>
      <Box className={"horizontalFlex"} flex={1}>
        {currency === 'ILS'
          ? <ShekelSquareSign/>
          : currency === 'USD'
            ? <DollarSquareSign/>
            : <EuroSquareSign/>
        }
        <Box mx={1.25} className={"verticalFlex"}>
          <Typography variant={"body1"} color={"textPrimary"}>
            {`${currency} ${t('wallets:account')}`}
          </Typography>
        </Box>
      </Box>
      <AmountRow
        currency={currency}
        amount={amount}
        variant={"body1"}
        sx={{ color: 'grey.600', fontWeight: 600 }}
      />
    </Box>
  )
})

export const AccountBigData = memo(({ amount, currency }: IAccountProps) => {

  return (
    <Box className={"horizontalFlex"}>
      {currency === 'ILS'
        ? <ShekelBigSign/>
        : currency === 'USD'
          ? <DollarBigSign/>
          : <EuroBigSign/>
      }
      <Box width={15}/>
      <Box className={"verticalFlex"} color={"designGrey.500"}>
        <Box className={"horizontalFlex"}>
          <Typography variant={"h3"} color={"inherit"} sx={{ fontSize: '1.75rem !important', fontWeight: 700 }}>
            {currency}
          </Typography>
        </Box>
        <Box fontWeight={600}>
          <Typography variant={"h6"} color={"inherit"}>
            {`${currency === 'ILS' ? '₪' : currency === 'USD' ? '$' : '€'}`}
            {Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
})
