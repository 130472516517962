import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, spacing, breakpoints}) =>
  ({
    verticalCenter: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    padding50: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    mainTitleMargin: {
      fontWeight: 700,
      color: palette.grey['500'],
      marginBottom: 36,
      marginTop: 8,
    },
    marginTop40: {
      marginTop: 40,
    },
    twoButtonRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 40,
      [breakpoints.down(420)]: {
        marginTop: 20,
        flexWrap: 'wrap',
      },
    },
    btnInRow: {
      '&:first-child': {
        marginRight: 30,
      },
      [breakpoints.down(420)]: {
        marginTop: 20,
        '&:first-child': {
          marginRight: 0,
        },
      },
    },
    qrcodeBtn: {
      marginBottom: 20,
      marginTop: 30,
    },
    preview: {
      position: 'relative',
      width: 300,
      height: 66,
      borderRadius: 12,
      marginTop: 30,
      backgroundColor: palette.background.paper,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.background.paper,
      overflow: 'hidden',
      color: palette.text.primary,
      cursor: 'pointer',
      '&:hover': {
        borderColor: palette.primary.main,
      },
    },
    cardPreview: {
      width: 180,
      height: 180,
      borderRadius: 12,
    },
    closePreview: {
      backgroundColor: palette.background.default,
      marginRight: 16,
    },
    textarea: {
      height: 118,
      '& .MuiInputBase-formControl': {
        height: 75,
      },
    },
    summaryDigits: {
      fontSize: '2rem',
      color: palette.primary.light,
      [breakpoints.down(960)]: {
        fontWeight: 'normal',
        fontSize: '1.6rem',
      },
      [breakpoints.down(600)]: {
        fontWeight: 'normal',
        fontSize: '1.4rem',
      },
    },
    textBtn: {
      width: 180,
    },
    mobileTitle: {
      color: palette.grey['500'],
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 28,
    },
    subtitle: {
      color: palette.text.primary,
      fontSize: 18,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: 16,
    },
    description: {
      color: palette.grey['400'],
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      marginBottom: 30,
      paddingLeft: 65,
      paddingRight: 65,
    },
    mobileImage: {
      margin: '20px 0',
      borderRadius: 14,
      overflow: 'hidden',
    },
    transactionData: {
      paddingBottom: 16,
      paddingLeft: 60,
      paddingRight: 60,
      [breakpoints.down(600)]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [breakpoints.down(400)]: {
        paddingLeft: 26,
        paddingRight: 26,
      },
    },
    summaryRow: {
      display: 'flex',
      padding: '26px 56px',
      [breakpoints.down(600)]: {
        padding: '26px 36px',
      },
      [breakpoints.down(520)]: {
        padding: 24,
      },
      [breakpoints.down(420)]: {
        padding: '24px 16px',
      },
    },
    button300: {
      width: 300,
      marginTop: 40,
    },
    horizontalRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [breakpoints.down(460)]: {
        flexDirection: 'column',
        '&:first-child': {
          alignItems: 'flex-start',
        },
        '&:last-child': {
          alignItems: 'flex-end',
          paddingRight: 8,
        },
      },
    },
    cropper: {
      width: '100%',
      height: 280,
      [breakpoints.down(640)]: {
        height: '82vw',
      },
    },
    productBtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 300,
      height: 278,
      borderRadius: 20,
      backgroundColor: 'white',
      boxShadow: '0px 3px 15px rgba(0, 46, 79, 0.15)',
      cursor: 'pointer',
    },
    digitInput: {
      width: 44,
      minWidth: 44,
      height: 44,
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.designGrey["300"],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 500,
      color: palette.grey["600"],
    },
    imageContainer: {
      width: 148,
      height: 148,
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.designGrey["200"],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: spacing(1.5),
      marginRight: 'auto',
      marginLeft: 'auto',
    }
  }))

export default useStyles
