import {memoize} from 'lodash'
import {api} from './api'
import {IAnalyticaPayload} from 'model/transaction.model'

export const getTransactions = ({query}: { query: string }) => api.get(`users/transactions?${query}`)

export const walletHistoryReport = (payload: unknown) =>
  api.post('mobile/report/walletOnlineLedgerReport', payload)

export const getTransactionDocuments = (payload: { transactionId: string }) => api.post('transactionNew/getDocuments', payload)

export const downloadTransactionDocument = memoize(async function (documentId: string) {
  const {data} = await api.get(`transactionNew/getDocument?id=${documentId}&base64`, {responseType: 'text'})
  return data
})

export const uploadTransactionDocument = (payload: FormData) => api.post('transactionNew/image/upload', payload)

export const rejectTransaction = (payload: { transactionId: string }) => api.post('transactionNew/payment/reject', payload)

export const walletAnalyticsReport = (payload: IAnalyticaPayload) =>
  api.post('mobile/report/walletLedgerAnalyticReport', payload);
