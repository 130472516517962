import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(({ palette, breakpoints, spacing, isHebrew }) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
    },
    containerRoot: {
      paddingLeft: 80,
      paddingRight: 80,
      [breakpoints.down(768)]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [breakpoints.down(560)]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [breakpoints.down(460)]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    titleWrap: {
      paddingTop: 60,
      paddingBottom: 30,
    },
    title: {
      color: palette.grey['900'],
      fontWeight: 800,
    },
    subtitle: {
      color: palette.grey['500'],
      fontWeight: 600,
      lineHeight: 1.2,
    },
    storeBtn: {
      width: 160,
      height: 40,
      borderRadius: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.grey["500"],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: spacing(1),
      cursor: 'pointer',
      '&:first-child': {
        marginRight: isHebrew ? 0 : spacing(2.5),
        marginLeft: isHebrew ? spacing(2.5) : 0,
      },
      [breakpoints.down(460)]: {
        width: 158,
        '&:first-child': {
          marginRight: isHebrew ? 0 : spacing(1.25),
          marginLeft: isHebrew ? spacing(1.25) : 0,
        },
      },
    },
    storeImage: {
      width: 20,
      height: 'auto',
    },
  }))

export default useStyles
