import React from 'react'
import {Typography, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import {useAppDispatch} from 'redux/app.hooks'
import Input from 'components/Input'
import Button from 'components/Button'
import {sendEmailMessage} from 'redux/reducers/user.reducer'

import useStyles from './styles'

const SendMessage = () => {
  const {classes} = useStyles()

  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values) => {
      dispatch(sendEmailMessage(values.message))
    },
  })

  return (
    <Box width={1}>
      <Typography variant={'h3'} align={'center'} className={classes.mainTitleMargin}>
        {t('support_questions:do_you_have_any_question')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        {/*<Input*/}
        {/*  name="email"*/}
        {/*  label={t('main:email')}*/}
        {/*  type="email"*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  value={formik.values.email}*/}
        {/*/>*/}
        {/*<Input*/}
        {/*  name="name"*/}
        {/*  label={t('rec_details:name')}*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  value={formik.values.name}*/}
        {/*  title={t('main:optional')}*/}
        {/*/>*/}
        <Input name="message" label={t('main:your_message')} onChange={formik.handleChange}
               value={formik.values.message} multiline rows={3} className={classes.textarea}/>
        <Box width={1} display="flex" justifyContent="center">
          <Button color="primary" variant="contained" className={classes.marginTop40} type="submit">
            {t('settings:send_via_email')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default SendMessage
