import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, spacing, isHebrew}) => ({
  wrapper: {
    position: 'relative',
  },
  phoneRoot: {
    width: 106,
    height: 42,
    position: 'absolute',
    top: 22,
    left: 1,
    zIndex: 2,
    paddingBottom: 0,
    paddingTop: 2,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-root': {
      borderWidth: 0,
      backgroundColor: 'transparent',
      '& .MuiSelect-select': {
        '&.MuiOutlinedInput-input.MuiInputBase-input': {
          paddingRight: 15,
          paddingLeft: isHebrew ? 35 : 15,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline, & .Mui-disabled': {
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '& .MuiInputBase-input, & .MuiSelect-select': {
      paddingTop: 0,
      backgroundColor: 'transparent',
      '&:hover': {backgroundColor: 'transparent'},
    },
    '& .MuiIconButton-root': {
      right: isHebrew ? 'initial' : 8,
      left: isHebrew ? 6 : 'initial',
    },
  },
  input: {
    '& .MuiInputBase-formControl': {
      paddingLeft: 107,
      '&.Mui-disabled': {
        backgroundColor: palette.grey['50'],
        color: palette.grey["600"],
      }
    },
  },
  label: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    direction: 'ltr',
    gap: spacing(1),
    color: palette.grey["600"],
    '&:hover': {
      color: palette.primary.main,
    }
  },
  delimiter: {
    width: 1,
    height: 22,
    position: 'absolute',
    top: 35,
    left: 98,
    zIndex: 2,
    backgroundColor: palette.grey['500'],
  },
}))

export default useStyles
