const flags: { [key: string]: any } = {
  AM: require('./AM.png'),
  AN: require('./AN.png'),
  AZ: require('./AZ.png'),
  AO: require('./AO.png'),
  BG: require('./BG.png'),
  BY: require('./BY.png'),
  DE: require('./DE.png'),
  GB: require('./GB.png'),
  FR: require('./FR.png'),
  GE: require('./GE.png'),
  IL: require('./IL.png'),
  IN: require('./IN.png'),
  IT: require('./IT.png'),
  KZ: require('./KZ.png'),
  LK: require('./LK.png'),
  MD: require('./MD.png'),
  NP: require('./NP.png'),
  PH: require('./PH.png'),
  RO: require('./RO.png'),
  RU: require('./RU.png'),
  TH: require('./TH.png'),
  TJ: require('./TJ.png'),
  TR: require('./TR.png'),
  UA: require('./UA.png'),
  UZ: require('./UZ.png'),
  US: require('./US.png'),
  EU: require('./EU.png'),
  AE: require('./AE.png'),
  BD: require('./BD.png'),
  CH: require('./CH.png'),
  CZ: require('./CZ.png'),
  EE: require('./EE.png'),
  JP: require('./JP.png'),
  NG: require('./NG.png'),
  NZ: require('./NZ.png'),
  KG: require('./KG.png'),
  ES: require('./ES.png'),
  AI: require('./AI.png'),
  AL: require('./AL.png'),
  DZ: require('./DZ.png'),
  AG: require('./AG.png'),
  AR: require('./AR.png'),
  AT: require('./AT.png'),
  AU: require('./AU.png'),
  AW: require('./AW.png'),
  BB: require('./BB.png'),
  BA: require('./BA.png'),
  BF: require('./BF.png'),
  BR: require('./BR.png'),
  MT: require('./MT.png'),
  NL: require('./NL.png'),
  NO: require('./NO.png'),
  PL: require('./PL.png'),
  PT: require('./PT.png'),
  RS: require('./RS.png'),
  SG: require('./SG.png'),
  SI: require('./SI.png'),
  SK: require('./SK.png'),
  TG: require('./TG.png'),
  UY: require('./UY.png'),
  VG: require('./VG.png'),
  GD: require('./GD.png'),
  GG: require('./GG.png'),
  GI: require('./GI.png'),
  GN: require('./GN.png'),
  GR: require('./GR.png'),
  GT: require('./GT.png'),
  GW: require('./GW.png'),
  GY: require('./GY.png'),
  HK: require('./HK.png'),
  HN: require('./HN.png'),
  HT: require('./HT.png'),
  UG: require('./UG.png'),
  SE: require('./SE.png'),
  CD: require('./CD.png'),
  DM: require('./DM.png'),
  DO: require('./DO.png'),
  EC: require('./EC.png'),
  EG: require('./EG.png'),
  ER: require('./ER.png'),
  ET: require('./ET.png'),
  FI: require('./FI.png'),
  FJ: require('./FJ.png'),
  GA: require('./GA.png'),
  GM: require('./GM.png'),
  GH: require('./GH.png'),
  SV: require('./SV.png'),
  CF: require('./CF.png'),
  CL: require('./CL.png'),
  CN: require('./CN.png'),
  CO: require('./CO.png'),
  CR: require('./CR.png'),
  CV: require('./CV.png'),
  CY: require('./CY.png'),
  DJ: require('./DJ.png'),
  DK: require('./DK.png'),
  HR: require('./HR.png'),
  KM: require('./KM.png'),
  TD: require('./TD.png'),
  KY: require('./KY.png'),
  CA: require('./CA.png'),
  BI: require('./BI.png'),
  BE: require('./BE.png'),
  KH: require('./KH.png'),
  BJ: require('./BJ.png'),
  CM: require('./CM.png'),
  BO: require('./BO.png'),
  HU: require('./HU.png'),
  IS: require('./IS.png'),
  ID: require('./ID.png'),
  IE: require('./IE.png'),
  IM: require('./IM.png'),
  CI: require('./CI.png'),
  JM: require('./JM.png'),
  JE: require('./JE.png'),
  JO: require('./JO.png'),
  KE: require('./KE.png'),
  KV: require('./KV.png'),
  LA: require('./LA.png'),
  LV: require('./LV.png'),
  LR: require('./LR.png'),
  LI: require('./LI.png'),
  LT: require('./LT.png'),
  LU: require('./LU.png'),
  MK: require('./MK.png'),
  MG: require('./MG.png'),
  MW: require('./MW.png'),
  VE: require('./VE.png'),
  VN: require('./VN.png'),
  WS: require('./WS.png'),
  YE: require('./YE.png'),
  ZA: require('./ZA.png'),
  ZM: require('./ZM.png'),
  ZW: require('./ZW.png'),
  ML: require('./ML.png'),
  MM: require('./MM.png'),
  MN: require('./MN.png'),
  MR: require('./MR.png'),
  MU: require('./MU.png'),
  MX: require('./MX.png'),
  MY: require('./MY.png'),
  MZ: require('./MZ.png'),
  PA: require('./PA.png'),
  PE: require('./PE.png'),
  PY: require('./PY.png'),
  RW: require('./RW.png'),
  SC: require('./SC.png'),
  SL: require('./SL.png'),
  SM: require('./SM.png'),
  SN: require('./SN.png'),
  SR: require('./SR.png'),
  TL: require('./TL.png'),
  TN: require('./TN.png'),
  TZ: require('./TZ.png'),
  CG: require('./CG.png'),
  MA: require('./MA.png'),
  MC: require('./MC.png'),
  ME: require('./ME.png'),
  NE: require('./NE.png'),
  NI: require('./NI.png'),
  SB: require('./SB.png'),
  AD: require("./AD.png"),
  AX: require("./AX.png"),
  BH: require("./BH.png"),
  BL: require("./BL.png"),
  BM: require("./BM.png"),
  BN: require("./BN.png"),
  KN: require("./KN.png"),
  KR: require("./KR.png"),
  KW: require("./KW.png"),
  LC: require("./LC.png"),
  LS: require("./LS.png"),
  LY: require("./LY.png"),
  MF: require("./MF.png"),
  MH: require("./MH.png"),
  MQ: require("./MQ.png"),
  BS: require("./BS.png"),
  BT: require("./BT.png"),
  BW: require("./BW.png"),
  BZ: require("./BZ.png"),
  FM: require("./FM.png"),
  GL: require("./GL.png"),
  GP: require("./GP.png"),
  GQ: require("./GQ.png"),
  GU: require("./GU.png"),
  MO: require("./MO.png"),
  MS: require("./MS.png"),
  MV: require("./MV.png"),
  NA: require("./NA.png"),
  NC: require("./NC.png"),
  NR: require("./NR.png"),
  OM: require("./OM.png"),
  PF: require("./PF.png"),
  PG: require("./PG.png"),
  PM: require("./PM.png"),
  PR: require("./PR.png"),
  QA: require("./QA.png"),
  RE: require("./RE.png"),
  ST: require("./ST.png"),
  SZ: require("./SZ.png"),
  TC: require("./TC.png"),
  TO: require("./TO.png"),
  TT: require("./TT.png"),
  TV: require("./TV.png"),
  VA: require("./VA.png"),
  VC: require("./VC.png"),
  VU: require("./VU.png"),
  YT: require("./YT.png"),
}

export default flags
