import React, { useCallback } from 'react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { useAppDispatch } from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'

import useStyles from './styles'

const ChangeManyRequests = ({ text }: { text: string }) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const confirmHandler = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} className={classes.mainTitleMargin}>
        {t(text)}
      </Typography>
      <Box mt={2.5}>
        <Typography variant={'subtitle2'} align={'center'} sx={{ color: 'designGrey.500' }}>
          {t('cards:try_in_minute')}
        </Typography>
      </Box>
      <Box mt={5} display={"flex"} justifyContent={"center"}>
        <Button
          color="secondary"
          variant="contained"
          onClick={confirmHandler}
        >
          {t('main:got_it')}
        </Button>
      </Box>
    </Box>
  )
}

export default (ChangeManyRequests)
