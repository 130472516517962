import React, { KeyboardEvent, useCallback, useState, useEffect, useRef } from 'react'
import { Typography, Box, Fade, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import Input from 'components/Input'
import Button from 'components/Button'
import { confirmTransactionRequest } from 'redux/reducers/transfer.reducer'

import useStyles from './styles'

const ConfirmTransaction = () => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { phone } = useAppSelector(({ user }) => user.userMeta)
  const { loading, transactionMeta } = useAppSelector(({ transfer }) => transfer)

  const inputRef = useRef(null)
  const cursorTimer = useRef<number>(-1)
  const cursorValue = useRef<boolean>(true)

  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [cursor, setCursor] = useState<boolean>(false)

  useEffect(() => {
    cursorTimer.current = window.setInterval(() => {
      setCursor(cursorValue.current)
      cursorValue.current = !cursorValue.current
    }, 450)

    return () => {
      setError(false)
      setCode('')
      window.clearInterval(cursorTimer.current)
    }
  }, [])

  useEffect(() => {
    if (!!transactionMeta?.errorMessage) {
      setCode('')
      setError(true)
      if (!!inputRef?.current) {
        (inputRef.current as HTMLInputElement).focus();
      }
    }
  }, [transactionMeta?.errorMessage])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setCode('')
    } else if (e.target.value.length <= 4) {
      setCode(e.target.value)
    }
    if (error) {
      setError(false)
    }
  }, [error])

  const handleCode = useCallback(() => {
    dispatch(confirmTransactionRequest({ verificationCode: code }))
  }, [code])

  const handleKeyboard = useCallback((e: KeyboardEvent) => {
    if (code.length === 4 && e.key === 'Enter') {
      handleCode()
    }
  }, [code, handleCode])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} className={classes.mainTitleMargin}>
        {t('transaction:confirm_transfer')}
      </Typography>
      <Box display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={0.75}>
        <Typography variant={'subtitle2'} sx={{ color: 'designGrey.500' }}>
          {t('enter_code:we_sent_sms_to')}
        </Typography>
        <Typography variant={'subtitle2'} sx={{ fontWeight: 700, color: 'designGrey.500', direction: 'ltr' }}>
          {`${phone.slice(0, 4)} ${phone.slice(4, 6)} ${phone.slice(6, 10)} ${phone.slice(10)}`}
        </Typography>
      </Box>

      <Box position={"relative"} mt={5}>
        <Input
          autoFocus
          inputRef={inputRef}
          label={''}
          value={code}
          onChange={onChange}
          onKeyPress={handleKeyboard}
          sx={{ opacity: 0, zIndex: 3 }}
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={2.5} zIndex={1}
          position={"absolute"}
          top={4} left={0}
          right={0} bottom={0}
        >
          <Box className={classes.digitInput}>
            {loading === 'pending'
              ? <Skeleton variant="circular" width={30} height={30}/>
              : !code[0] ? (
                <Fade in={cursor}>
                  <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
                </Fade>
              ) : (
                <Typography variant={"title"} color={"inherit"}>
                  {code[0]}
                </Typography>
              )}
          </Box>
          <Box className={classes.digitInput}>
            <Typography variant={"title"} color={"inherit"}>
              {loading === 'pending'
                ? <Skeleton variant="circular" width={30} height={30}/>
                : (!!code[0] && !code[1]) ? (
                  <Fade in={cursor}>
                    <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
                  </Fade>
                ) : (
                  <Typography variant={"title"} color={"inherit"}>
                    {code[1] || ''}
                  </Typography>
                )}
            </Typography>
          </Box>
          <Box className={classes.digitInput}>
            {loading === 'pending'
              ? <Skeleton variant="circular" width={30} height={30}/>
              : (!!code[1] && !code[2]) ? (
                <Fade in={cursor}>
                  <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
                </Fade>
              ) : (
                <Typography variant={"title"} color={"inherit"}>
                  {code[2] || ''}
                </Typography>
              )}
          </Box>
          <Box className={classes.digitInput}>
            {loading === 'pending'
              ? <Skeleton variant="circular" width={30} height={30}/>
              : (!!code[2] && !code[3]) ? (
                <Fade in={cursor}>
                  <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
                </Fade>
              ) : (
                <Typography variant={"title"} color={"inherit"}>
                  {code[3] || ''}
                </Typography>
              )}
          </Box>
        </Box>
      </Box>
      <Box mt={-1}>
        <Typography
          variant={"subtitle2"}
          align={"center"}
          sx={{ color: !error ? 'white' : 'error.main', fontWeight: 500 }}
        >
          {t('validation:wrong_verification_code')}{'.'}
        </Typography>
        <Typography
          variant={"subtitle2"}
          align={"center"}
          sx={{ color: !error ? 'white' : 'error.main', fontWeight: 500 }}
        >
          {t('main:try_again')}{'.'}
        </Typography>
      </Box>
      <Box mt={2.5} display={"flex"} justifyContent={"center"}>
        <Button
          color="primary"
          variant="contained"
          disabled={loading === 'pending' || !code || code.length !== 4}
          onClick={handleCode}
        >
          {t('main:confirm')}
        </Button>
      </Box>
    </Box>
  )
}

export default ConfirmTransaction
