import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {
  IUser,
  ISignup,
  ILoginRequest,
  ISendNewPasswordRequest,
  IDocument,
  IUserMeta,
  EContactOptions,
  IChangeSettingsRequest,
  INotification,
  INotificationsListRequest,
  initialUserState,
} from 'model/user.model'

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState(),
  reducers: {
    loginRequest(state, action: PayloadAction<ILoginRequest>) {
      state.loading = 'pending'
    },
    loginSuccess(state, action: PayloadAction<Partial<IUser>>) {
      state.userId = action.payload.userId
      state.startTime = action.payload.startTime || 0
      state.token = action.payload.token as string
      state.userMeta = action.payload.userMeta || {} as IUserMeta
      state.loading = 'success'
      if (state.error) {
        delete state.error
      }
      state.loginData = {}
      delete state.signup
    },
    loginFail(state, action: PayloadAction<string>) {
      state.userId = undefined
      state.token = ''
      state.userMeta = {} as IUserMeta
      state.loading = 'failed'
      state.error = action.payload || 'Login error'
    },
    setLoginData(state, action: PayloadAction<ILoginRequest>) {
      state.loginData = { ...(current(state).loginData || {}), ...action.payload }
    },
    setUserDocuments(state, action: PayloadAction<{ [type: string]: IDocument }>) {
      state.documents = { ...current(state).documents, ...action.payload }
    },
    getLinkResetPassword(state, action: PayloadAction<{ phoneNumber: string, phoneCountry: string }>) {
    },
    sendNewPasswordRequest(state, action: PayloadAction<ISendNewPasswordRequest>) {
      state.loading = 'pending'
    },
    sendNewPasswordSuccess(state) {
      state.loading = 'success'
    },
    sendNewPasswordFail(state) {
      state.loading = 'failed'
    },
    setSignupDetails(state, action: PayloadAction<Partial<ISignup>>) {
      if (state.signup) {
        const updated = { ...current(state).signup, ...action.payload }
        state.signup = updated
      } else {
        state.signup = action.payload
      }
    },
    signupRequest(state) {
      state.loading = 'pending'
    },
    signupFail(state, action: PayloadAction<string>) {
      state.loading = 'failed'
      state.error = action.payload || 'Signup error'
    },
    signupSuccess(state) {
      state.loading = 'success'
      if (state.error) {
        delete state.error
      }
    },
    editUserRequest(state, action: PayloadAction<Partial<IUserMeta> & { routeName?: string }>) {
      state.loading = 'pending'
    },
    editUserSuccess(state, action: PayloadAction<IUserMeta>) {
      state.loading = 'success'
      if (state.error) {
        delete state.error
      }
      state.userMeta = action.payload
    },
    editUserFail(state, action: PayloadAction<string>) {
      state.loading = 'failed'
      state.error = action.payload || 'Edit user error'
    },
    sendVerificationCode() {
    },
    sendSupportMessage(state, action: PayloadAction<{ userId: string; supportMessage: string }>) {
    },
    checkAccount(state, action: PayloadAction<Partial<ILoginRequest>>) {
    },
    setVerificationTimer(state, action: PayloadAction<number>) {
      state.verificationTimer = action.payload
    },
    setUploadFile(state, action: PayloadAction<{ [type: string]: Omit<IDocument, 'documentId'> }>) {
      state.upload = { ...current(state).upload, ...action.payload }
    },
    clearUploadFiles(state, action: PayloadAction<string | undefined>) {
      if (action.payload && state.upload[action.payload]) {
        delete state.upload[action.payload]
      } else {
        state.upload = {}
      }
    },
    updateDocuments() {
    },
    logout(state) {
      state.userId = undefined
      state.token = ''
      state.userMeta = {} as IUserMeta
      state.documents = {}
    },
    sendEmailMessage(state, action: PayloadAction<string>) {
    },
    getContactOptionsRequest(state) {
      state.loadingOptions = 'pending'
    },
    getContactOptionsSuccess(state, action: PayloadAction<EContactOptions[]>) {
      state.loadingOptions = 'success'
      state.contactOptions = action.payload
    },
    getContactOptionsFail(state) {
      state.loadingOptions = 'failed'
    },
    changeSettings(state, action: PayloadAction<IChangeSettingsRequest>) {
    },
    setContactOptions(state, action: PayloadAction<EContactOptions[]>) {
      state.contactOptions = action.payload
    },
    getNotificationsRequest(state, action: PayloadAction<INotificationsListRequest>) {
      state.loading = 'pendingNotification'
    },
    getNotificationsSuccess(state, action: PayloadAction<INotification[]>) {
      state.notifications = action.payload
      state.loading = 'successNotification'
    },
    getNotificationsFail(state) {
      state.loading = 'failedNotification'
    },
    deleteNotificationsRequest(state, action: PayloadAction<string[]>) {
      state.loading = 'pendingNotification'
    },
    deleteNotificationsFail(state) {
      state.loading = 'failedNotification'
    },
    setNotificationsQuantity(state, action: PayloadAction<{ size?: number, unread?: number }>) {
      state.notificationQuantity = !!action.payload.size || action.payload.size === 0
        ? action.payload.size
        : state.notificationQuantity
      state.unreadNotifications = !!action.payload.unread || action.payload.unread === 0
        ? action.payload.unread
        : state.unreadNotifications
    },
    createSessionChangePhoneRequest(state) {
      state.changePhoneNumber.errorMessage = ''
      state.changePhoneNumber.spinner = true
    },
    createSessionChangePhoneSuccess(state, action: PayloadAction<string>) {
      state.changePhoneNumber.spinner = false
      state.changePhoneNumber.sessionId = action.payload
    },
    createSessionChangePhoneFail(state) {
      state.changePhoneNumber.spinner = false
    },
    validateNewPhoneRequest(state, action: PayloadAction<{ phoneCountryCode: string, phoneNumber: string }>) {
      state.changePhoneNumber.errorMessage = ''
      state.changePhoneNumber.spinner = true
    },
    validateNewPhoneSuccess(state, action: PayloadAction<string>) {
      state.changePhoneNumber.newPhone = action.payload
      state.changePhoneNumber.spinner = false
    },
    validateNewPhoneFail(state, action: PayloadAction<string>) {
      state.changePhoneNumber.errorMessage = action.payload
      state.changePhoneNumber.spinner = false
    },
    approveNewPhoneRequest(state, action: PayloadAction<{ smsCode: string }>) {
      state.changePhoneNumber.errorMessage = ''
      state.changePhoneNumber.spinner = true
    },
    approveNewPhoneSuccess(state, action: PayloadAction<any>) {
      state.changePhoneNumber = { ...state.changePhoneNumber, ...action.payload }
      state.changePhoneNumber.spinner = false
    },
    approveNewPhoneFail(state, action: PayloadAction<string>) {
      state.changePhoneNumber.errorMessage = action.payload
    },
    cancelChangePhoneRequest(state, action: PayloadAction<{ comment: string, commentCode: string }>) {
      state.changePhoneNumber.errorMessage = ''
      state.changePhoneNumber.spinner = true
    },
    cancelChangePhoneSuccess(state) {
      state.changePhoneNumber = { ...state.changePhoneNumber, eventStatus: 'CANCEL' }
      state.changePhoneNumber.spinner = false
    },
    cancelChangePhoneFail(state, action: PayloadAction<string>) {
      state.changePhoneNumber.spinner = false
      state.changePhoneNumber.errorMessage = action.payload
    },
    finishRequiredAction(state, action: PayloadAction<string>) {
      const {actionRequiredReasons} = current(state).userMeta
      const reasons = (actionRequiredReasons || []).filter(reason => reason !== action.payload)
      state.userMeta.actionRequiredReasons = reasons
    }
  },
})

export const {
  loginFail,
  loginRequest,
  loginSuccess,
  setLoginData,
  setSignupDetails,
  signupFail,
  signupRequest,
  signupSuccess,
  editUserFail,
  editUserRequest,
  editUserSuccess,
  setVerificationTimer,
  checkAccount,
  sendVerificationCode,
  getLinkResetPassword,
  sendNewPasswordFail,
  sendNewPasswordRequest,
  sendNewPasswordSuccess,
  setUserDocuments,
  sendSupportMessage,
  setUploadFile,
  clearUploadFiles,
  updateDocuments,
  logout,
  sendEmailMessage,
  getContactOptionsFail,
  getContactOptionsRequest,
  getContactOptionsSuccess,
  changeSettings,
  setContactOptions,
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFail,
  setNotificationsQuantity,
  deleteNotificationsFail,
  deleteNotificationsRequest,
  createSessionChangePhoneRequest,
  createSessionChangePhoneSuccess,
  createSessionChangePhoneFail,
  validateNewPhoneRequest,
  validateNewPhoneSuccess,
  validateNewPhoneFail,
  approveNewPhoneSuccess,
  approveNewPhoneFail,
  approveNewPhoneRequest,
  cancelChangePhoneSuccess,
  cancelChangePhoneFail,
  cancelChangePhoneRequest,
  finishRequiredAction,
} = userSlice.actions

export const loginActions = {
  FAIL: loginFail,
  REQUEST: loginRequest,
  SUCCESS: loginSuccess,
}

export const editUserActions = {
  FAIL: editUserFail,
  REQUEST: editUserRequest,
  SUCCESS: editUserSuccess,
}

export const signupActions = {
  FAIL: signupFail,
  REQUEST: signupRequest,
  SUCCESS: signupSuccess,
}

export const sendNewPasswordActions = {
  FAIL: sendNewPasswordFail,
  REQUEST: sendNewPasswordRequest,
  SUCCESS: sendNewPasswordSuccess,
}

export const getContactOptionsActions = {
  FAIL: getContactOptionsFail,
  REQUEST: getContactOptionsRequest,
  SUCCESS: getContactOptionsSuccess,
}

export const deleteNotificationsActions = {
  FAIL: deleteNotificationsFail,
  REQUEST: deleteNotificationsRequest,
}

export const createSessionChangePhoneActions = {
  FAIL: createSessionChangePhoneFail,
  REQUEST: createSessionChangePhoneRequest,
  SUCCESS: createSessionChangePhoneSuccess,
}

export const validateNewPhoneActions = {
  FAIL: validateNewPhoneFail,
  REQUEST: validateNewPhoneRequest,
  SUCCESS: validateNewPhoneSuccess,
}

export const approveNewPhoneActions = {
  FAIL: approveNewPhoneFail,
  REQUEST: approveNewPhoneRequest,
  SUCCESS: approveNewPhoneSuccess,
}

export const cancelChangePhoneActions = {
  FAIL: cancelChangePhoneFail,
  REQUEST: cancelChangePhoneRequest,
  SUCCESS: cancelChangePhoneSuccess,
}

export default userSlice.reducer
