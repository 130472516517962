import React, { useCallback } from 'react'
import { Box, Typography, Button, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/app.hooks'
import { ACTIONS_MAPPING } from 'model/user.model'
import { closeModal } from 'redux/reducers/ui.reducer'

const ActionsRequired = () => {
  const { t } = useTranslation(['countries_list', 'main', 'rec_details'])

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { actionRequiredReasons } = useAppSelector(({ user }) => user.userMeta)

  const renderItem = useCallback((item: string) => {
    if (!ACTIONS_MAPPING[item]) {
      return null
    }
    const handler = (e: React.BaseSyntheticEvent) => {
      e.preventDefault()
      e.stopPropagation()
      navigate(ACTIONS_MAPPING[item].screen)
      dispatch(closeModal())
    }
    return (
      <Box key={item}>
        <Box py={2.5} className={"horizontalRow"}>
          <Box className={"verticalFlex"}>
            <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 600 }}>
              {t(ACTIONS_MAPPING[item].title)}
            </Typography>
            <Typography variant="body1" sx={{ color: 'designGrey.400', fontWeight: 500 }}>
              {t(ACTIONS_MAPPING[item].description)}
            </Typography>
          </Box>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handler}
          >
            {t('main:get_started')}
          </Button>
        </Box>
        <Divider/>
      </Box>
    )
  }, [navigate])

  return (
    <Box>
      <Typography variant="h4" align="center" sx={{ color: 'error.main', fontWeight: 700 }}>
        {t('questionnaire:additional_data_required')}
      </Typography>
      <Typography variant="subtitle2" align="center" sx={{ color: 'designGrey.500', fontWeight: 700, marginTop: 2.5 }}>
        {t('info:action_required_web')}
      </Typography>
      <Box mt={5}>
        {(actionRequiredReasons || []).map(renderItem)}
      </Box>
    </Box>
  )
}

export default ActionsRequired
