import { call, takeEvery, take, put, select, fork, all, delay, cancelled, ForkEffect } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import { get } from 'lodash'
import { actionsHandler } from 'services/apiServices/api'
import { IRecipient, ICardAccount } from 'model/recipient.model'
import * as recipientService from 'services/apiServices/recipient.servise'
import countryService from 'services/country.service'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'
import { GET_RECIPIENTS_ENDPOINT, DELETE_CARD_ACCOUNT_ENDPOINT } from 'constants/recipient.constants'
import {
  getRecipientsActions,
  editRecipientActions,
  createRecipientActions,
  selectRecipient,
  deleteCardAccountActions,
} from '../reducers/recipient.reducer'
import { openToast, openModal, closeModal } from '../reducers/ui.reducer'
import RecipientInfo from "../../features/ModalContent/RecipientInfo";

const organizationId = process.env.REACT_APP_ORGANIZATION_ID

function* getRecipientsBody() {
  const { userId: senderId } = yield select((state) => state.user)
  return { data: { organizationId, senderId } }
}

const getRecipientsListFork = fork(actionsHandler, {
  actions: getRecipientsActions,
  method: 'POST',
  paramsGetter: getRecipientsBody,
  responseParser: (response: { data: { recipients: IRecipient[] } }) =>
    response.data.recipients.map((recipient) => {
      if (!recipient.phoneNumber) {
        return recipient
      }
      const code = recipient.country === 'KV'
        ? 'XK'
        : recipient.country === 'AN' ? 'BQ' : recipient.country;
      const prefix = countryService.getPhonePrefix(recipient.country);
      const phone = parsePhoneNumber(recipient.phoneNumber, code as CountryCode)
      return {
        ...recipient,
        phoneNumber: phone?.nationalNumber || recipient.phoneNumber.slice((prefix || '').length),
        phoneCountry: recipient.country,
        phone: recipient.phoneNumber,
      }
    }),
  url: GET_RECIPIENTS_ENDPOINT,
})

const deleteCardAccountFork = fork(actionsHandler, {
  actions: deleteCardAccountActions,
  method: 'POST',
  paramsGetter: ({ cardAccountId, recipientId }: { cardAccountId: string, recipientId: string }) => {
    console.log(cardAccountId, recipientId)
    return { data: { cardAccountId } }
  },
  responseParser: (response: { data: { cardAccountData: ICardAccount[] } }, payload: { recipientId: string }) => ({
    cardAccounts: response.data.cardAccountData,
    recipientId: payload.recipientId,
  }),
  url: DELETE_CARD_ACCOUNT_ENDPOINT,
})

export function* editRecipientSaga({ payload }: PayloadAction<IRecipient>) {
  try {
    Object.keys(payload).forEach((field) => {
      if (payload[field as keyof IRecipient] && typeof payload[field as keyof IRecipient] === 'string') {
        payload[field as keyof IRecipient] = payload[field as keyof IRecipient].trim()
      }
      if (field === 'bankAccount') {
        Object.keys(payload[field as keyof IRecipient]).forEach((key) => {
          if (payload[field as keyof IRecipient][key] && typeof payload[field as keyof IRecipient][key] === 'string') {
            payload[field as keyof IRecipient][key] = payload[field as keyof IRecipient][key].trim()
          }
        })
      }
    })
    const { recipientsList } = yield select(({ recipients }) => recipients)

    const code = payload.country === 'KV'
      ? 'XK'
      : payload.country === 'AN' ? 'BQ' : payload.country;
    const prefix = countryService.getPhonePrefix(payload.country || '');
    const phone = parsePhoneNumber(payload.phoneNumber || '', code as CountryCode);

    const exist = recipientsList.find(
      (r: IRecipient) =>
        r.phoneNumber === (phone?.number || prefix + (payload.phoneNumber || '').trim()) &&
        r.firstName.toLowerCase() === (payload.firstName ? payload.firstName.toLowerCase() : '') &&
        r.lastName.toLowerCase() === (payload.lastName ? payload.lastName.toLowerCase() : '')
    )
    if (exist && exist.recipientId !== payload.recipientId) {
      yield put(openToast({ type: 'error', toastMessage: 'rec_details:recipient_already_exist' }))
      yield put(editRecipientActions[FAIL]({
        recipientId: payload.recipientId,
        error: 'Recipient exists',
      }))
      return
    }
    const { data } = yield call(recipientService.editRecipient, {
      ...payload,
      phoneNumber: phone?.number as string || payload.phone,
    })

    if (data.status !== 'SUCCESS') {
      throw new Error(data.message)
    }

    const edited = {
      ...data,
      phoneNumber: phone?.nationalNumber || payload.phoneNumber,
      phoneCountry: data.country || payload.phoneCountry,
      phone: data.phoneNumber,
      edited: !!payload.bankAccount
        ? 'bankAccount'
        : !!payload.cardAccounts ? 'cardAccount' : 'data'
    }

    yield put(
      editRecipientActions[SUCCESS](edited)
    )

    const { payoutType } = yield select(({ transfer }) => transfer.transferData)

    if (!['MOBILE_WALLET', 'QIWI_WALLET', 'ALIPAY'].includes(payoutType || 'NO') && !payload.bankAccount && !payload.cardAccounts) {
      yield put(openModal({ type: RecipientInfo, props: { recipient: edited }, paperClass: 'wide-modal' }))
    }
    yield put(
      openToast({
        type: 'success',
        toastMessage: 'rec_details:recipients_changed',
        messageOpts: { name: data.firstName + ' ' + data.lastName },
      })
    )
  } catch (err) {
    yield put(closeModal())
    yield put(openToast({ type: 'error', toastMessage: err.message || err.toString() }))
    yield put(editRecipientActions[FAIL]({
      recipientId: payload.recipientId,
      error: err.message || '',
    }))
  }
}

export function* createRecipientSaga({ payload }: PayloadAction<Partial<IRecipient>>) {
  try {
    Object.keys(payload).forEach((field) => {
      if (payload[field as keyof IRecipient] && typeof payload[field as keyof IRecipient] === 'string') {
        payload[field as keyof IRecipient] = payload[field as keyof IRecipient].trim()
      }
    })
    const { recipientsList: recipients } = yield select(({ recipients }) => recipients)
    const code = payload.country === 'KV'
      ? 'XK'
      : payload.country === 'AN' ? 'BQ' : payload.country;
    const prefix = countryService.getPhonePrefix(payload.country || '');
    const phone = parsePhoneNumber(payload.phoneNumber || '', code as CountryCode);

    const exist = recipients.find(
      (r: IRecipient) =>
        r.phoneNumber === (phone?.number || prefix + (payload.phoneNumber || '').trim()) &&
        r.firstName.toLowerCase() === (payload.firstName ? payload.firstName.toLowerCase() : '') &&
        r.lastName.toLowerCase() === (payload.lastName ? payload.lastName.toLowerCase() : '')
    )
    if (exist) {
      yield put(openToast({ type: 'error', toastMessage: 'rec_details:recipient_already_exist' }))
      yield put(createRecipientActions[FAIL]())
      return
    }

    const { data } = yield call(recipientService.createRecipient, {
      ...payload,
    })

    if (data.status !== 'SUCCESS') {
      throw new Error(data.message)
    }

    const { payoutType } = yield select(({ transfer }) => transfer.transferData)

    if (payoutType !== "WALLET_TO_WALLET") {
      yield put(openModal({ type: RecipientInfo, props: { recipient: data }, paperClass: 'wide-modal' }))
    }

    yield put(openToast({ type: 'success', toastMessage: 'rec_details:recipients_added' }))
    yield put(createRecipientActions[SUCCESS](data))
    yield put(selectRecipient(data))
  } catch (err) {
    if (err.message) {
      yield put(openToast({ type: 'error', toastMessage: err.message }))
    }
    yield put(createRecipientActions[FAIL]())
  }
}

export default function* rootSaga() {
  yield all<ForkEffect>([
    getRecipientsListFork,
    deleteCardAccountFork,
    yield takeEvery(editRecipientActions[REQUEST].toString(), editRecipientSaga),
    yield takeEvery(createRecipientActions[REQUEST].toString(), createRecipientSaga),
  ])
}
