import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, isHebrew}) => ({
  root: {
    paddingTop: 22,
    paddingBottom: 22,
    direction: 'ltr',
    '& label[data-shrink=false]': {
      '&  + .MuiInputBase-root input::placeholder': {
        color: '#ACB1B5',
        opacity: '0.9 !important',
      },
    },
    '& .MuiInputBase-formControl': {
      color: palette.grey["600"],
      height: 44,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 1,
      borderColor: palette.designGrey['300'],
      backgroundColor: palette.background.default,
      borderStyle: 'solid',
      borderRadius: 12,
      fontSize: '0.9375rem',
      fontWeight: 500,
      '&.Mui-focused': {
        borderColor: palette.primary.main,
      },
      '&.Mui-error': {
        borderColor: palette.error.main,
      },
      '&:hover:not(.Mui-focused):not(.Mui-disabled)': {
        borderColor: palette.primary.main,
        backgroundColor: palette.designGrey["100"],
      },
      '&.Mui-disabled': {
        backgroundColor: palette.grey['50'],
        borderColor: palette.grey['200'],
      },
      '&.Mui-readOnly': {
        backgroundColor: "#EEF3F6",
        borderColor: palette.designGrey['300'],
        color: palette.grey['500'],
      },
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
      lineHeight: '15px',
      direction: isHebrew ? 'rtl' : 'ltr',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: palette.designGrey['400'],
      '&:hover': {
        color: palette.designGrey['400'],
      },
      '&.Mui-focused': {
        color: palette.primary.main,
      },
      '&.Mui-error': {
        color: palette.error.main,
      },
    },
    '& .MuiInputAdornment-positionStart': {
      marginBottom: 2,
    },
  },
  amountRoot: {
    paddingTop: 22,
    paddingBottom: 22,
    '& label[data-shrink=false]': {
      '&  + .MuiInputBase-root input::placeholder': {
        color: '#ACB1B5',
        opacity: '0.9 !important',
      },
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
      transform: 'none',
      lineHeight: '15px',
      direction: isHebrew ? 'rtl' : 'ltr',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: palette.designGrey['400'],
    },
  },
  passwordBtn: {
    backgroundColor: palette.background.default,
    marginBottom: 3,
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}))

export default useStyles
