import React from 'react'
import { Typography, Box, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useAppSelector } from 'redux/app.hooks'

import useStyles from './styles'

const ChangePhoneSuccess = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  const { timeForActivate } = useAppSelector(({ user }) => user.changePhoneNumber)

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={6.25} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('info:request_sent')}
        </Typography>
        <Box mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {t('info:new_phone_attach')}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'orange.main', fontWeight: 500 }}>
            {!timeForActivate
              ? moment().add(3, 'days').format('DD MMMM YYYY')
              : moment(timeForActivate * 1000).format('DD MMMM YYYY, HH:mm')
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default (ChangePhoneSuccess)
