import React, { FC, useEffect, useCallback, useMemo, useState } from 'react'
import { Box, Typography, SelectChangeEvent } from '@mui/material'
import Input, { FormikInput, ISimpleInputProps } from '../Input'
import Select, { FormikSelect } from '../Select'
import { Field } from 'formik'
import Flag from 'features/Flag'
import countryService from 'services/country.service'

import useStyles from './styles'

interface IPhoneInputProps extends ISimpleInputProps {
  countryFieldName?: string
  numberFieldName?: string
  countries?: string[]
  countrySelectProps?: any
}

const PhoneInput: FC<IPhoneInputProps> = ({
                                            countryFieldName = 'phoneCountry',
                                            numberFieldName = 'phoneNumber',
                                            countries = [],
                                            countrySelectProps = {},
                                            ...props
                                          }) => {
  const { classes } = useStyles()

  const [countryValue, setCountryValue] = useState<string>(countries.length === 1 ? countries[0] : '')

  const options = useMemo(() => {
    return countries
      .map((country) => ({
        value: country,
        label: (
          <Box className={classes.label}>
            <Flag country={country} size={16}/>
            <Typography variant="subtitle2" color="inherit">
              {countryService.getPhonePrefix(country)}
            </Typography>
          </Box>
        ),
      }))
  }, [countries])

  const onChange = useCallback((event: SelectChangeEvent<unknown>) => {
    setCountryValue(event.target.value as string)
  }, [])

  return (
    <div className={classes.wrapper}>
      <Input
        className={classes.input}
        name={numberFieldName}
        {...props}
      />
      <Select
        disabled={!!props.disabled}
        name={countryFieldName}
        options={options}
        value={countryValue}
        onChange={onChange}
        withFlag={false}
        className={classes.phoneRoot}
        inputProps={{ readOnly: true }}
        renderValue={(value: unknown) => {
          if (!value) {
            return (
              <Box className={classes.label}>
                <Typography variant="subtitle2" color="textSecondary">
                  {"Code"}
                </Typography>
              </Box>
            )
          }

          return (
            <Box className={classes.label}>
              <Flag country={value as string} size={16}/>
              <Typography variant="subtitle2" color="textPrimary">
                {countryService.getPhonePrefix(value as string)}
              </Typography>
            </Box>
          )
        }}
        {...countrySelectProps}
      />
      <div className={classes.delimiter}/>
    </div>
  )
}

export const FormikPhoneInput: FC<IPhoneInputProps> = ({
                                                         countryFieldName = 'phoneCountry',
                                                         numberFieldName = 'phoneNumber',
                                                         countries = [],
                                                         ...props
                                                       }) => {
  const { classes } = useStyles()

  const options = useMemo(() => {
    return countries.map((country) => ({
      value: country,
      label: (
        <Box className={classes.label}>
          <Flag country={country} size={16}/>
          <Typography variant="subtitle2" color="inherit">
            {countryService.getPhonePrefix(country)}
          </Typography>
        </Box>
      ),
    }))
  }, [countries])

  return (
    <Box className={classes.wrapper}>
      <Field
        name={numberFieldName}
        className={classes.input}
        component={FormikInput}
        normalizer={(value: string | undefined) => !!value && value[0] === '0' ? value.substring(1) : value}
        {...props}
      />
      <Field
        name={countryFieldName}
        component={FormikSelect}
        options={options}
        className={classes.phoneRoot}
        withFlag={false}
        placeholder={"Code"}
        renderValue={(value: unknown) => {
          if (!value) {
            return (
              <Typography variant="subtitle2" color="textSecondary">
                {"Code"}
              </Typography>
            )
          }

          return (
            <Box className={classes.label}>
              <Flag country={value as string} size={16}/>
              <Typography variant="subtitle2" color="textPrimary">
                {countryService.getPhonePrefix(value as string)}
              </Typography>
            </Box>
          )
        }}
      />
      <Box className={classes.delimiter}/>
    </Box>
  )
}

export default PhoneInput
