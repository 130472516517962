import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, spacing}) => ({
  card: {
    width: 40,
    height: 24,
    borderRadius: '6px 1px',
    padding: 5,
  },
  verticalCard: {
    width: 24,
    height: 36,
    borderRadius: '6px 1px 6px 1px',
  },
  verticalTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '6px 1px 6px 1px',
  },
  verticalMarker: {
    width: 10,
    height: 2,
    borderRadius: 5,
    marginTop: 5,
    marginLeft: 10,
    marginRight: 4,
  },
  marker: {
    width: 10,
    height: 5,
    borderRadius: 5,
    alignSelf: 'flex-end',
  },
  account: {
    width: 40,
    height: 24,
    borderRadius: 4,
    padding: 5,
    flexDirection: 'column',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: palette.info.main,
    marginRight: 3,
  },
  smallLine: {
    width: 13,
    height: 1.5,
    borderRadius: 1,
    backgroundColor: palette.info.dark,
    marginBottom: 2,
  },
  middleLine: {
    width: 19,
    height: 3,
    borderRadius: 1,
    backgroundColor: palette.info.dark,
  },
  bottomLine: {
    width: 30,
    height: 3,
    borderRadius: 1,
    backgroundColor: palette.info.dark,
  },
}))

export default useStyles
