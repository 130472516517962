import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {
  initialState,
  IWallet,
  ICard,
  IQuestionnaire,
  IAnswer,
  IMccGroup,
  ICardProduct,
  TProductTariff,
  TProductLimits,
  ICardRequest,
  IChangeStatusRequest,
  TTotalDebts, ICloseCardRequest, IPaymentData,
} from 'model/wallet.model'
import { IPayoutPayload } from 'model/transfer.model'
import { clearTransferData } from './transfer.reducer'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialState(),
  reducers: {
    getWalletsRequest(state) {
      state.walletLoading = 'pending'
    },
    getWalletsSuccess(state, action: PayloadAction<IWallet[]>) {
      state.wallets = action.payload
      state.walletLoading = 'success'
    },
    getWalletsFailed(state) {
      state.walletLoading = 'failed'
    },
    getCardsRequest(state) {
      state.cardLoading = 'pending'
    },
    getCardsSuccess(state, action: PayloadAction<ICard[]>) {
      const walletList = [...current(state).wallets].map(wallet => {
        const walletCards: number[] = [];
        action.payload.forEach(card => {
          if (card.walletId === wallet.id) {
            walletCards.push(card.id);
          }
        });
        return { ...wallet, walletCards };
      });
      state.cards = action.payload
      state.wallets = walletList
      state.cardLoading = 'success'
    },
    getCardsFailed(state) {
      state.cardLoading = 'failed'
    },
    getMccGroupsRequest(state) {
    },
    getMccGroupsSuccess(state, action: PayloadAction<IMccGroup[]>) {
      state.mccGroups = action.payload
    },
    getMccGroupsFailed(state) {
    },
    getQuestionnaireQuestionsRequest(state, action: PayloadAction<string>) {
      state.questionnaire = { ...(current(state).questionnaire || {}), questionnaireLoading: 'pending' }
    },
    getQuestionnaireQuestionsSuccess(state, action: PayloadAction<Partial<IQuestionnaire>>) {
      state.questionnaire = { ...(current(state).questionnaire || {}), ...action.payload, questionnaireLoading: 'success' }
    },
    getQuestionnaireQuestionsFailed(state) {
      state.questionnaire = { ...(current(state).questionnaire || {}), questionnaireLoading: 'failed' }
    },
    setQuestionnaireAnswers(state, action: PayloadAction<{ [code: string]: IAnswer }>) {
      state.questionnaire = { ...(current(state).questionnaire || {}), answers: { ...(state.questionnaire?.answers || {}), ...action.payload } }
    },
    setIssueCardValues(state, action: PayloadAction<object>) {
      state.issueCard.issueValues = { ...(current(state).issueCard.issueValues || {}), ...action.payload }
    },
    sendQuestionnaireAnswersRequest(state, action: PayloadAction<any>) {
      state.questionnaire = { ...(current(state).questionnaire || {}), questionnaireLoading: 'pending' }
    },
    sendQuestionnaireAnswersSuccess(state) {
      state.questionnaire = { ...(current(state).questionnaire || {}), questionnaireLoading: 'success' }
    },
    sendQuestionnaireAnswersFailed(state) {
      state.questionnaire = { ...(current(state).questionnaire || {}), questionnaireLoading: 'failed' }
    },
    getCardProductsRequest(state) {
      state.issueCard.productLoading = 'pending'
    },
    getCardProductsSuccess(state, action: PayloadAction<ICardProduct[]>) {
      state.issueCard.cardProducts = action.payload
      state.issueCard.productLoading = 'success'
    },
    getCardProductsFailed(state) {
      state.issueCard.productLoading = 'failed'
    },
    getProductTariffRequest(state, action: PayloadAction<string | number>) {
    },
    getProductTariffSuccess(state, action: PayloadAction<{ cardProductId: string, tariff: TProductTariff }>) {
      const products = [...current(state).issueCard.cardProducts]
        .map(product => product.cardProductId === action.payload.cardProductId
          ? { ...product, tariff: action.payload.tariff }
          : product
        );
      state.issueCard.cardProducts = products
    },
    getProductTariffFailed(state) {
    },
    getProductLimitsRequest(state, action: PayloadAction<string>) {
    },
    getProductLimitsSuccess(state, action: PayloadAction<{ cardProductId: string, limits: TProductLimits }>) {
      const products = [...current(state).issueCard.cardProducts]
        .map(product => product.cardProductId === action.payload.cardProductId
          ? { ...product, limits: action.payload.limits }
          : product
        );
      state.issueCard.cardProducts = products
    },
    getProductLimitsFailed(state) {
    },
    getIssueCardsRequest(state, action: PayloadAction<{ afterCreate: boolean }>) {
      state.issueCard.issueCardLoading = 'pending'
    },
    getIssueCardsSuccess(state, action: PayloadAction<ICardRequest[]>) {
      state.issueCard.cardRequests = action.payload
      state.issueCard.issueCardLoading = 'success'
    },
    getIssueCardsFailed(state) {
      state.issueCard.issueCardLoading = 'failed'
    },
    getCloseCardsRequest(state) {
      state.closeCardLoading = 'pending'
    },
    getCloseCardsSuccess(state, action: PayloadAction<ICloseCardRequest[]>) {
      state.closeCardRequests = action.payload
      state.closeCardLoading = 'success'
    },
    getCloseCardsFailed(state) {
      state.closeCardLoading = 'failed'
    },
    createIssueCardRequestRequest(state, action: PayloadAction<object>) {
      state.issueCard.issueCardLoading = 'pending'
    },
    createIssueCardRequestSuccess(state, action: PayloadAction<any>) {
      state.issueCard.issueCardLoading = 'success'
    },
    createIssueCardRequestFailed(state) {
      state.issueCard.issueCardLoading = 'failed'
    },
    createCloseCardRequest(state, action: PayloadAction<{ cardId: number, reason: string }>) {
      state.closeCardLoading = 'pending'
    },
    createCloseCardSuccess(state) {
      state.closeCardLoading = 'success'
    },
    createCloseCardFailed(state) {
      state.closeCardLoading = 'failed'
    },
    checkQuestionnaire(state) {
    },
    changeCurrencyCardRequest(state, action: PayloadAction<{ accountNum: string, cardId: number }>) {
      state.changeCardLoading = 'pending'
    },
    changeCurrencyCardSuccess(state, action: PayloadAction<ICard>) {
      const updated = [...current(state).cards].map(card => {
        if (+card.id === +action.payload.id) {
          return action.payload
        }
        return card
      })
      state.cards = updated
      state.changeCardLoading = 'success'
    },
    changeCurrencyCardFailed(state) {
      state.changeCardLoading = 'success'
    },
    changeCardStatusRequest(state, action: PayloadAction<IChangeStatusRequest>) {
      state.changeCardLoading = 'pending'
    },
    changeCardStatusSuccess(state, action: PayloadAction<ICard>) {
      const updated = [...current(state).cards].map(card => {
        if (+card.id === +action.payload.id) {
          return action.payload
        }
        return card
      })
      state.cards = updated
      state.changeCardLoading = 'success'
    },
    changeCardStatusFailed(state) {
      state.changeCardLoading = 'success'
    },
    getCardDebtsRequest(state, action: PayloadAction<{ cardId: number, total: boolean }>) {
      state.cardDebtsLoading = 'pending'
    },
    getCardDebtsSuccess(state, action: PayloadAction<{ id: number, totalDebts?: TTotalDebts, debts?: any }>) {
      const updated = [...current(state).cards].map(card => {
        if (+card.id === +action.payload.id) {
          return { ...card, ...action.payload }
        }
        return card
      })
      state.cards = updated
      state.cardDebtsLoading = 'success'
    },
    getCardDebtsFailed(state) {
      state.cardDebtsLoading = 'failed'
    },
    getRecipientRequest(state, action: PayloadAction<string>) {
      state.paymentLoading = 'pending'
    },
    getRecipientSuccess(state, action: PayloadAction<IPaymentData>) {
      state.paymentData = action.payload
      state.paymentLoading = 'success'
    },
    getRecipientFailed(state, action: PayloadAction<IPaymentData>) {
      state.paymentLoading = 'failed'
      state.paymentData = action.payload
    },
    preparePayoutRequest(state, action: PayloadAction<IPayoutPayload>) {
      state.paymentLoading = 'pending'
    },
    preparePayoutSuccess(state, action: PayloadAction<any>) {
      state.payoutData = action.payload
      state.paymentLoading = 'success'
    },
    preparePayoutFailed(state, action: PayloadAction<any>) {
      state.payoutData = action.payload
      state.paymentLoading = 'failed'
    },
    confirmPayoutRequest(state) {
      state.paymentLoading = 'pending'
    },
    confirmPayoutSuccess(state, action: PayloadAction<{ transactionMeta: any }>) {
      state.paymentLoading = 'success'
      state.payoutData = action.payload
    },
    confirmPayoutFailed(state) {
      state.paymentLoading = 'failed'
    },
    setSmsServiceRequest(state, action: PayloadAction<any>) {
      state.changeCardLoading = 'pending'
    },
    setSmsServiceSuccess(state, action: PayloadAction<{ cardId: number }>) {
      const updated = [...current(state).cards].map(card => {
        if (+card.id === +action.payload.cardId) {
          return { ...card, sms: 'PAID' }
        }
        return card
      })
      state.cards = updated
      state.changeCardLoading = 'success'
    },
    setSmsServiceFailed(state) {
      state.changeCardLoading = 'failed'
    },
    deleteSmsServiceRequest(state, action: PayloadAction<string>) {
      state.changeCardLoading = 'pending'
    },
    deleteSmsServiceSuccess(state, action: PayloadAction<{ cardId: number }>) {
      const updated = [...current(state).cards].map(card => {
        if (+card.id === +action.payload.cardId) {
          return { ...card, sms: 'FREE' }
        }
        return card
      })
      state.cards = updated
      state.changeCardLoading = 'success'
    },
    deleteSmsServiceFailed(state) {
      state.changeCardLoading = 'failed'
    },
    resetPinCodeRequest(state, action: PayloadAction<string>) {
      state.changeCardLoading = 'pending'
    },
    resetPinCodeSuccess(state) {
      state.changeCardLoading = 'success'
    },
    resetPinCodeFailed(state) {
      state.changeCardLoading = 'failed'
    },
    receiveCvvRequest(state, action: PayloadAction<string>) {
      state.changeCardLoading = 'pending'
    },
    receiveCvvSuccess(state) {
      state.changeCardLoading = 'success'
    },
    receiveCvvFailed(state) {
      state.changeCardLoading = 'failed'
    },
  },
  extraReducers: {
    [clearTransferData.toString()]: (state, action) => {
      state.paymentData = undefined
      state.paymentLoading = 'idle'
    },
  },
})

export const {
  getWalletsFailed,
  getWalletsRequest,
  getWalletsSuccess,
  getCardsFailed,
  getCardsRequest,
  getCardsSuccess,
  getQuestionnaireQuestionsFailed,
  getQuestionnaireQuestionsRequest,
  getQuestionnaireQuestionsSuccess,
  sendQuestionnaireAnswersFailed,
  sendQuestionnaireAnswersRequest,
  sendQuestionnaireAnswersSuccess,
  setQuestionnaireAnswers,
  setIssueCardValues,
  getMccGroupsFailed,
  getMccGroupsRequest,
  getMccGroupsSuccess,
  getCardProductsSuccess,
  getCardProductsRequest,
  getCardProductsFailed,
  getProductTariffRequest,
  getProductTariffSuccess,
  getProductTariffFailed,
  getProductLimitsRequest,
  getProductLimitsFailed,
  getProductLimitsSuccess,
  getIssueCardsRequest,
  getIssueCardsFailed,
  getIssueCardsSuccess,
  createIssueCardRequestRequest,
  createIssueCardRequestSuccess,
  createIssueCardRequestFailed,
  checkQuestionnaire,
  changeCurrencyCardSuccess,
  changeCurrencyCardRequest,
  changeCurrencyCardFailed,
  changeCardStatusSuccess,
  changeCardStatusRequest,
  changeCardStatusFailed,
  getCardDebtsSuccess,
  getCardDebtsFailed,
  getCardDebtsRequest,
  getRecipientSuccess,
  getRecipientRequest,
  getRecipientFailed,
  preparePayoutSuccess,
  preparePayoutFailed,
  preparePayoutRequest,
  confirmPayoutSuccess,
  confirmPayoutFailed,
  confirmPayoutRequest,
  setSmsServiceSuccess,
  setSmsServiceFailed,
  setSmsServiceRequest,
  deleteSmsServiceSuccess,
  deleteSmsServiceFailed,
  deleteSmsServiceRequest,
  getCloseCardsFailed,
  getCloseCardsRequest,
  getCloseCardsSuccess,
  createCloseCardSuccess,
  createCloseCardFailed,
  createCloseCardRequest,
  resetPinCodeSuccess,
  resetPinCodeRequest,
  resetPinCodeFailed,
  receiveCvvRequest,
  receiveCvvSuccess,
  receiveCvvFailed,
} = walletSlice.actions

export const getWalletsActions = {
  [REQUEST]: getWalletsRequest,
  [SUCCESS]: getWalletsSuccess,
  [FAIL]: getWalletsFailed,
}

export const getCardProductsActions = {
  [REQUEST]: getCardProductsRequest,
  [SUCCESS]: getCardProductsSuccess,
  [FAIL]: getCardProductsFailed,
}

export const getProductTariffActions = {
  [REQUEST]: getProductTariffRequest,
  [SUCCESS]: getProductTariffSuccess,
  [FAIL]: getProductTariffFailed,
}

export const getProductLimitsActions = {
  [REQUEST]: getProductLimitsRequest,
  [SUCCESS]: getProductLimitsSuccess,
  [FAIL]: getProductLimitsFailed,
}

export const getCardsActions = {
  [REQUEST]: getCardsRequest,
  [SUCCESS]: getCardsSuccess,
  [FAIL]: getCardsFailed,
}

export const getMccGroupsActions = {
  [REQUEST]: getMccGroupsRequest,
  [SUCCESS]: getMccGroupsSuccess,
  [FAIL]: getMccGroupsFailed,
}

export const getQuestionnaireQuestionsActions = {
  [REQUEST]: getQuestionnaireQuestionsRequest,
  [SUCCESS]: getQuestionnaireQuestionsSuccess,
  [FAIL]: getQuestionnaireQuestionsFailed,
}

export const sendQuestionnaireAnswersActions = {
  [REQUEST]: sendQuestionnaireAnswersRequest,
  [SUCCESS]: sendQuestionnaireAnswersSuccess,
  [FAIL]: sendQuestionnaireAnswersFailed,
}

export const getIssueCardsActions = {
  [REQUEST]: getIssueCardsRequest,
  [SUCCESS]: getIssueCardsSuccess,
  [FAIL]: getIssueCardsFailed,
}

export const createIssueCardRequestActions = {
  [REQUEST]: createIssueCardRequestRequest,
  [SUCCESS]: createIssueCardRequestSuccess,
  [FAIL]: createIssueCardRequestFailed,
}

export const getCloseCardsActions = {
  [REQUEST]: getCloseCardsRequest,
  [SUCCESS]: getCloseCardsSuccess,
  [FAIL]: getCloseCardsFailed,
}

export const createCloseCardActions = {
  [REQUEST]: createCloseCardRequest,
  [SUCCESS]: createCloseCardSuccess,
  [FAIL]: createCloseCardFailed,
}

export const changeCurrencyCardActions = {
  [REQUEST]: changeCurrencyCardRequest,
  [SUCCESS]: changeCurrencyCardSuccess,
  [FAIL]: changeCurrencyCardFailed,
}

export const changeCardStatusActions = {
  [REQUEST]: changeCardStatusRequest,
  [SUCCESS]: changeCardStatusSuccess,
  [FAIL]: changeCardStatusFailed,
}

export const setSmsServiceActions = {
  [REQUEST]: setSmsServiceRequest,
  [SUCCESS]: setSmsServiceSuccess,
  [FAIL]: setSmsServiceFailed,
}

export const deleteSmsServiceActions = {
  [REQUEST]: deleteSmsServiceRequest,
  [SUCCESS]: deleteSmsServiceSuccess,
  [FAIL]: deleteSmsServiceFailed,
}

export const resetPinCodeActions = {
  [REQUEST]: resetPinCodeRequest,
  [SUCCESS]: resetPinCodeSuccess,
  [FAIL]: resetPinCodeFailed,
}

export const getCardDebtsActions = {
  [REQUEST]: getCardDebtsRequest,
  [SUCCESS]: getCardDebtsSuccess,
  [FAIL]: getCardDebtsFailed,
}

export const getRecipientActions = {
  [REQUEST]: getRecipientRequest,
  [SUCCESS]: getRecipientSuccess,
  [FAIL]: getRecipientFailed,
}

export const preparePayoutActions = {
  [REQUEST]: preparePayoutRequest,
  [SUCCESS]: preparePayoutSuccess,
  [FAIL]: preparePayoutFailed,
}

export const confirmPayoutActions = {
  [REQUEST]: confirmPayoutRequest,
  [SUCCESS]: confirmPayoutSuccess,
  [FAIL]: confirmPayoutFailed,
}

export const receiveCvvActions = {
  [REQUEST]: receiveCvvRequest,
  [SUCCESS]: receiveCvvSuccess,
  [FAIL]: receiveCvvFailed,
}

export default walletSlice.reducer
