export enum EUserStatuses {
  VERIFIED_FOR_MOBILE = 'VERIFIED_FOR_MOBILE',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
}

export type TNotificationsData = {
  type: string
  error?: string
  size?: number
  unread?: number
  page?: number
  filter?: string
  notifications?: INotification[]
}

export interface IUserMeta {
  firstName: string
  middleName?: string
  lastName: string
  country?: string
  phoneCountry: string
  phoneNumber: string
  phone: string
  dateOfBirth: string
  gender?: string
  email: string
  city: string
  address: string
  zipCode?: string
  houseNumber?: string
  idType: string
  idNumber: string
  idSerialNumber?: string
  idExpiryDate: string
  idIssuedDate: string
  idIssueByCountry: string
  userId?: string
  complianceStatus: string
  actionRequiredReasons?: string[]
  emailVerified: boolean
}

export interface ISignup extends IUserMeta {
  password: string
  verificationCode: string
  idScan: string
  selfieScan: string
  idBlob: Blob
  selfieBlob: Blob
  error?: any
}

export interface ILoginRequest {
  organizationId?: string
  phoneNumber?: string
  phoneCountry?: string
  email?: string
  password?: string
  origin?: string
  verificationCode?: string
  idNumber?: string
  exist?: boolean
}

export enum EContactOptions {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
}

export enum UPLOAD_TYPES {
  ID = 'ID',
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  SECOND_ID = 'SECOND_ID',
  SELFIE = 'SELFIE',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_OCCUPATION = 'PROOF_OF_OCCUPATION',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  INVOICE = 'INVOICE',
  AVATAR = 'AVATAR'
}

export interface IDocument {
  documentId: string
  documentType: UPLOAD_TYPES | string
  image?: string
  name?: string
  mimeType?: string
}

export interface INotification {
  id: string
  title?: string
  body: string
  data: {[key: string]: string}
  createdAt: number
  status: string
}

export interface IUser {
  userId: string | undefined
  token: string
  userMeta: IUserMeta
  documents: { [type: string]: IDocument }
  contactOptions: EContactOptions[]
  changePhoneNumber: any
  loading: string
  loadingOptions: string
  verificationTimer: number
  loginData?: Partial<ILoginRequest>
  signup?: Partial<ISignup>
  error?: string
  upload: { [type: string]: Omit<IDocument, 'documentId'> }
  startTime: number
  notifications: INotification[]
  notificationQuantity: number
  unreadNotifications: number
}

export const initialUserState = (): IUser => ({
  userId: undefined,
  token: '',
  userMeta: {} as IUserMeta,
  documents: {},
  contactOptions: [],
  upload: {},
  loading: 'idle',
  loadingOptions: 'idle',
  verificationTimer: 0,
  startTime: 0,
  notifications: [],
  notificationQuantity: 0,
  unreadNotifications: 0,
  changePhoneNumber: {}
})

type TDocType = {
  [type: string]: {
    icon: string
    value: string
    text: string
  }
}

export const DOC_TYPES: TDocType = {
  DRIVING_LICENCE: {
    icon: 'driving-license',
    value: 'DRIVING_LICENCE',
    text: 'driving_licence',
  },
  PASSPORT: {
    icon: 'passport',
    value: 'PASSPORT',
    text: 'Passport',
  },
  ID: {
    icon: 'id',
    value: 'ID',
    text: 'national_ID',
  },
  PERMIT: {
    icon: 'id',
    value: 'PERMIT',
    text: 'permit_ID',
  },
  UK_ID: {
    icon: 'id',
    value: 'UK_ID',
    text: 'UK_residency_permit',
  },
  EU_ID: {
    icon: 'id',
    value: 'EU_ID',
    text: 'EU_national_ID_card',
  },
  WORK_PERMIT: {
    icon: 'list',
    value: 'WORK_PERMIT',
    text: 'work_permit',
  },
  PAN_CARD: {
    icon: 'list',
    value: 'PAN_CARD',
    text: 'Pan Card',
  },
  AADHAAR_CARD: {
    icon: 'list',
    value: 'AADHAAR_CARD',
    text: 'Aadhaar Card',
  },
  OTHER: {
    icon: 'list',
    value: 'OTHER',
    text: 'other',
  },
}

type TUploadType = {
  [type: string]: {
    label: string
    info: string
  }
}

export const UPLOAD_SCANS: TUploadType = {
  ID: {
    label: 'upload_ID_scan',
    info: 'id_scan_info',
  },
  ID_BACK_SIDE: {
    label: 'upload_ID_scan',
    info: 'id_scan_info',
  },
  PASSPORT: {
    label: 'upload_ID_scan',
    info: 'id_scan_info',
  },
  DRIVER_LICENSE: {
    label: 'upload_ID_scan',
    info: 'id_scan_info',
  },
  SELFIE: {
    label: 'selfie',
    info: 'selfie_scan_info',
  },
  AVATAR: {
    label: 'avatar_label',
    info: 'avatar_scan_info',
  },
  PROOF_OF_ADDRESS: {
    label: 'upload_proof_of_address_scan',
    info: 'proof_of_address_info',
  },
  PROOF_OF_OCCUPATION: {
    label: 'upload_proof_of_occupation_scan',
    info: 'proof_of_occupation_info',
  },
  SECOND_ID: {
    label: 'upload_ID_scan',
    info: 'proof_of_occupation_info',
  }
}

type TActionMapping = {
  [type: string]: {
    title: string
    description: string
    icon: string
    screen: string
  }
}

export const ACTIONS_MAPPING: TActionMapping = {
  UPLOAD_ID: {
    title: 'action_required:change_id_photo',
    description: 'action_required:change_id_description',
    icon: 'passport2',
    screen: '/main-document',
  },
  SECOND_ID: {
    title: 'camera:upload_proof_of_address_scan',
    description: 'action_required:upload_second_id',
    icon: 'second_doc',
    screen: '/second-document',
  },
  UPLOAD_SELFIE: {
    title: 'action_required:change_selfie',
    description: 'action_required:change_selfie_description',
    icon: 'photocam',
    screen: '/change-selfie',
  },
  INPUT_ISRAEL_ADDRESS: {
    title: 'action_required:israel_address',
    description: 'action_required:israel_address_description',
    icon: 'home',
    screen: '/israel-address',
   // screen: '/change-selfie',
  },
  INCOME_PROOF: {
    title: 'action_required:proof_income',
    description: 'action_required:proof_income_description',
    icon: 'taxes',
    screen: '/proof-income',
  },
  KYC_FORM: {
    title: 'action_required:fill_questionnaire',
    description: 'action_required:fill_questionnaire_descript',
    icon: 'list',
    screen: '/kyc-questionnaire',
  },
}


export interface ICheckUserRequest {
  idNumber: string
  phoneCountry: string
  phoneNumber: string
}

export interface IPhoneExistRequest {
  organizationId: string
  country: string
  phoneNumber: string
}

export interface IEmailExistRequest {
  email: string
  organizationId: string
}

export interface ISignupVerificationRequest {
  organizationId: string
  country: string
  phoneNumber: string
}

export interface ISendNewPasswordRequest {
  token: string
  password: string
  phoneNumber: string
}

export interface IUploadDocument {
  file: File | Blob
  description: string
  userId: string
}

export interface ISendEmailRequest {
  userId: string
  supportMessage: string
}

export interface IChangeSettingsRequest {
  oldPassword: string
  newPassword: string
  confirmPassword: string
  userContactOptionsList: EContactOptions[]
}

export interface INotificationsListRequest {
  userId?: string
  page?: number
  filter?: string
}
