import { call, takeEvery, put, select, fork, all, ForkEffect } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import {
  IConfigResponse,
  IPayoutType,
  IPayoutTypesMap,
  ITransferDataByCountry,
  IMobileFundingSources,
  ICountryData,
  TListItems,
  TMapListItems,
  IOffice,
  IPaymentLimit
} from 'model/configuration.model'
import {
  getConfiguration,
  getAllCountries,
  getRelationShips,
  getBankAccount,
  getRates,
  getPaymentLimits,
} from 'services/apiServices/configuration.service'
import { getUserData } from 'services/apiServices/user.service'
import { setAuthToken, removeAuthToken, actionsHandler } from 'services/apiServices/api'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'
import {
  GET_PURPOSES_OF_TRANSFER_ENDPOINT,
  GET_SOURCE_OF_FUNDS_ENDPOINT,
  GET_OCCUPATIONS_ENDPOINT,
} from 'constants/configuration.constants'
import {
  configActions,
  countriesActions,
  getOfficesActions,
  getOperatorsActions,
  getExchangeRatesActions,
  getPaymentLimitsActions,
  getOccupationsActions,
  getSourcesOfFundsActions,
  getTransferPurposesActions,
} from '../reducers/configuration.reducer'
import { loginSuccess, approveNewPhoneSuccess } from '../reducers/user.reducer'

const organizationId = process.env.REACT_APP_ORGANIZATION_ID

function* getConfigurationSaga() {
  try {
    const { data }: { data: IConfigResponse } = yield call(getConfiguration, { organizationId })
    const {
      countries,
      agentId,
      agentCountry,
      hasAlert,
      hasReferralCampaign,
      referralCampaignData,
      title,
      message,
      payinTypes,
      payinTypesByCountry,
      sourceCountries,
      settings,
      operators,
    } = data

    const destinationCountries: TListItems = []
    const destCountriesByPayoutType: { [payout: string]: Set<string> } = {}
    const depositPayouts: string[] = [];

    const transferDataByCountry = countries.reduce((acc: ITransferDataByCountry, countryData: ICountryData) => {
      destinationCountries.push(countryData.country)

      let payoutList: IPayoutType[] = []

      if (countryData.country === "IL") {
        (countryData.payoutTypes || []).forEach(item => {
          if (item.payoutType.includes("WALLET_DEPOSIT") || item.payoutType === "SALARY") {
            depositPayouts.push(item.payoutType);
          } else {
            payoutList.push(item)
          }
        });
      } else {
        payoutList = countryData.payoutTypes
      }

      const payoutTypesMap = payoutList.reduce((output: IPayoutTypesMap, type: IPayoutType) => {
        const { payoutType, operatorId } = type

        if (destCountriesByPayoutType[payoutType]) {
          destCountriesByPayoutType[payoutType].add(countryData.country)
        } else {
          destCountriesByPayoutType[payoutType] = new Set<string>()
          destCountriesByPayoutType[payoutType].add(countryData.country)
        }

        if (output[payoutType]) {
          output[payoutType] = [...output[payoutType], operatorId]
        } else {
          output[payoutType] = [operatorId]
        }
        return output
      }, {})
      acc[countryData.country] = payoutTypesMap
      return acc
    }, {})

    const fundingSourceDataByCountry = payinTypesByCountry.reduce((acc: TMapListItems, curr) => {
      acc[curr.country] = curr.fundingSources
      return acc
    }, {})

    const cardSaveSupportedByCountry = payinTypesByCountry.reduce((acc: { [country: string]: boolean }, curr) => {
      acc[curr.country] = curr.cardSaveSupported
      return acc
    }, {})

    const mobileFundingSourcesByCountry = payinTypesByCountry.reduce((acc: { [country: string]: IMobileFundingSources[] }, curr) => {
      acc[curr.country] = curr.mobileFundingSources
      return acc
    }, {})

    const payload = {
      depositPayouts,
      transferDataByCountry,
      destCountriesByPayoutType,
      destinationCountries,
      fundingSourceDataByCountry,
      cardSaveSupportedByCountry,
      agentId,
      payinTypes,
      agentCountry,
      hasReferralCampaign,
      referralCampaignData,
      mobileFundingSourcesByCountry,
      sourceCountries: sourceCountries || [],
      hasAlert,
      title,
      message,
      settings,
      operators,
    }
    yield put(configActions[SUCCESS](payload))
  } catch (err) {
    yield put(configActions[FAIL]())
  }
}

function* getCountriesSaga() {
  try {
    const agentId: string = yield select(({ configuration }) => configuration.agentId)
    const { data } = yield call(getAllCountries, { organizationId, agentId })
    yield put(countriesActions[SUCCESS](data))
  } catch (err) {
    yield put(countriesActions[FAIL]())
  }
}

export function* getRelationshipsSaga() {
  try {
    const { data } = yield getRelationShips()
    yield put(configActions[SUCCESS]({ relationShips: data.relationships }))
  } catch (err) {
    yield put(configActions[FAIL]())
  }
}

export function* getBankAccountSaga() {
  try {
    const { data } = yield getBankAccount()
    yield put(configActions[SUCCESS]({ bankAccountDetails: data.bankAccountDetails }))
  } catch (err) {
    yield put(configActions[FAIL]())
  }
}

function openIndexedDb() {
  return new Promise((resolve) => {
    if (window.indexedDB) {
      try {
        const dbOpenRequest = window.indexedDB.open('stb-storage', 1);

        dbOpenRequest.onupgradeneeded = function () {
          const db = dbOpenRequest.result;
          db.onerror = (err) => console.log(err);
          if (!db.objectStoreNames.contains('signup')) {
            db.createObjectStore('signup', { keyPath: 'type' });
            resolve(true);
          } else {
            console.log('db exist');
            resolve(true);
          }
        };

        dbOpenRequest.onsuccess = resolve;

        dbOpenRequest.onerror = function () {
          resolve(false);
        }

        dbOpenRequest.onblocked = function () {
          resolve(false);
        }
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    } else {
      resolve(false)
    }
  })
}

export function* getPaymentLimitsSaga() {
  try {
    const { paymentLimits } = yield select(({ configuration }) => configuration);
    if (!paymentLimits) {
      const { data: { fundingSources } } = yield call(getPaymentLimits);
      const limits = fundingSources.reduce((acc: object, source: IPaymentLimit) => ({ ...acc, [source.name]: source }), {});
      yield put(getPaymentLimitsActions[SUCCESS](limits));
    }
  } catch (err) {
    yield put(getPaymentLimitsActions[FAIL]());
  }
}

export function* getExchangeRatesSaga({ payload }: PayloadAction<any>) {
  try {
    const { data: { ratesWithTiersData } } = yield call(getRates, {
      "operatorId": "881c4376-9a8a-4497-81f8-0e24699db02b",
      "destinationCountry": "IL",
      "payoutType": "WALLET_EXCHANGE",
      "currencies": ["ILS", "USD", "EUR"],
      ...payload
    })
    const result = [...ratesWithTiersData].map(rate => {
      const sorted = [...rate.corridorRatesWithTiersData]
        .sort((a, b) => a.minAmount - b.minAmount);
      return {...rate, corridorRatesWithTiersData: sorted}
    })
    yield put(getExchangeRatesActions[SUCCESS](result))
  } catch (err) {
    yield put(getExchangeRatesActions[FAIL]());
  }
}

function* initConfigsSaga() {
  const start = Date.now()
  yield* getConfigurationSaga()
  yield* getCountriesSaga()

  const token = sessionStorage.getItem('@AuthToken')
  if (token) {
    try {
      setAuthToken(token)
      const { data } = yield getUserData()
      if (data.status === 'SUCCESS' && !!data.userId) {
        const { userPhoneLoggerData, organizationData, ...userMeta } = data.userData
        const phone = parsePhoneNumber(userMeta.phone, userMeta.country as CountryCode)
        yield put(
          loginSuccess({
            token,
            userId: data.userId,
            startTime: start,
            userMeta: {
              ...userMeta,
              phoneNumber: phone?.nationalNumber || userMeta.phone.slice(4),
              phoneCountry: phone?.country || userMeta.country,
            },
          })
        )
        yield put(
          approveNewPhoneSuccess(userPhoneLoggerData || {})
        );
        yield openIndexedDb();
      } else {
        sessionStorage.removeItem('@AuthToken')
        removeAuthToken()
      }
    } catch (err) {
      console.log(err)
      sessionStorage.removeItem('@AuthToken')
      removeAuthToken()
    }
  }
}

const getOfficesFork = fork(actionsHandler, {
  actions: getOfficesActions,
  method: 'GET',
  paramsGetter: () => ({ data: { organizationId } }),
  responseParser: (response: { data: { agents: IOffice[] } }) => response.data.agents.filter((agent) => agent.cardIssuing || agent.walletDepositing),
  url: `/agent/findByOrganization?organizationId=${organizationId}`,
})

const getOperatorsFork = fork(actionsHandler, {
  actions: getOperatorsActions,
  method: 'POST',
  paramsGetter: () => ({ data: { showActiveOnly: false } }),
  responseParser: (response: { data: { operatorDetails: { id: string, name: string }[] } }) =>
    response.data.operatorDetails.reduce((acc: object, operator: any) => ({
      ...acc,
      [operator.id]: operator.name,
    }), {}),
  url: `/organization/getOperators`,
})

const getSourcesOfFundsFork = fork(actionsHandler, {
  actions: getSourcesOfFundsActions,
  method: 'GET',
  responseParser: (response: { data: { sourcesOfFunds: string[] } }) => response.data.sourcesOfFunds,
  url: GET_SOURCE_OF_FUNDS_ENDPOINT,
})

const getTransferPurposesFork = fork(actionsHandler, {
  actions: getTransferPurposesActions,
  method: 'GET',
  responseParser: (response: { data: { transferPurposes: string[] } }) => response.data.transferPurposes,
  url: GET_PURPOSES_OF_TRANSFER_ENDPOINT,
})

const getOccupationsFork = fork(actionsHandler, {
  actions: getOccupationsActions,
  method: 'GET',
  responseParser: (response: { data: { occupations: { [key: string]: string } } }) => Object.keys(response.data.occupations),
  url: GET_OCCUPATIONS_ENDPOINT,
})


export default function* rootSaga() {
  yield all<ForkEffect>([
    getOfficesFork,
    getOperatorsFork,
    getSourcesOfFundsFork,
    getTransferPurposesFork,
    getOccupationsFork,
    takeEvery(getPaymentLimitsActions[REQUEST].toString(), getPaymentLimitsSaga),
    takeEvery(getExchangeRatesActions[REQUEST].toString(), getExchangeRatesSaga),
  ])
  yield initConfigsSaga()
}
