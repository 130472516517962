import React, { ChangeEvent, FC, useEffect, useCallback, useMemo } from 'react'
import { Typography, Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { Validators, createValidationSchema } from 'services/validation.service'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import Input from 'components/Input'
import Select, { TOption } from 'components/Select'
import PhoneInput from 'components/PhoneInput'
import Button from 'components/Button'
import countryService from 'services/country.service'
import { phoneNumberExamples } from 'constants/global.constants'
import { editRecipientRequest } from 'redux/reducers/recipient.reducer'
import { closeModal } from 'redux/reducers/ui.reducer'
import { IRecipient } from 'model/recipient.model'

import useStyles from './styles'

interface IFormProps {
  recipient: IRecipient
}

const validationSchema = createValidationSchema({
  firstName: Validators.firstName,
  lastName: Validators.lastName,
  middleName: Validators.middleName,
  address: Validators.notRequired.matches(/^[A-Za-z0-9-\u2019\u0027\s]+$/, {
    excludeEmptyString: true,
    message: 'must_contain_letters_or_numbers'
  }),
  phoneNumber: Validators.phoneNumber,
  country: Validators.required,
  relationshipToSender: Validators.required,
})

const RecipientForm: FC<IFormProps> = ({ recipient }) => {
  const { classes } = useStyles()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { destinationCountries, relationShips } = useAppSelector(({ configuration }) => configuration)
  const { loading } = useAppSelector(({ recipients }) => recipients)

  const countriesOptions = useMemo(() => {
    const options = destinationCountries
      .map((country) => ({
        label: t(`countries_list:${countryService.getFullName(country)}`),
        value: country,
        flag: country,
      }))
      .filter((data) => data.label)
    return [...options].sort((a, b) => (a.label > b.label ? 1 : -1))
  }, [destinationCountries, t])

  const relationshipsOptions = useMemo(() => {
    const options = relationShips.map((relation) => ({ label: t(`relationship_list:${relation}`), value: relation }))
    return [...options].sort()
  }, [relationShips])

  const formik = useFormik({
    initialValues: {
      firstName: get(recipient, 'firstName', '') || '',
      middleName: get(recipient, 'middleName', '') || '',
      lastName: get(recipient, 'lastName', '') || '',
      country: get(recipient, 'country', ''),
      phoneCountry: get(recipient, 'country', '') || '',
      phoneNumber: get(recipient, 'phoneNumber', '') || '',
      relationshipToSender: get(recipient, 'relationshipToSender', '') || '',
      address: get(recipient, 'address', '') || '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        editRecipientRequest({
          ...values,
          recipientId: recipient.recipientId,
          phoneNumber: values.phoneNumber ? values.phoneNumber : phoneNumberExamples[values.country],
        })
      )
    },
  })

  const { values, touched, errors, setFieldValue, setFieldTouched } = formik

  useEffect(() => {
    if (!!values.firstName) {
      const name = values.firstName.split(/\s/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      setFieldValue('firstName', name)
    }
  }, [values.firstName])

  useEffect(() => {
    if (!!values.lastName) {
      const name = values.lastName.split(/\s/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      setFieldValue('lastName', name)
    }
  }, [values.lastName])

  const setCountry = useCallback((e: ChangeEvent<{}>, data: TOption | null) => {
      if (data && data.value) {
        setFieldValue('country', data.value)
        setFieldValue('phoneCountry', data.value)
      }
    },
    [formik]
  )

  const onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name, false)
  }, [setFieldTouched])

  const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.name, true)
  }, [setFieldTouched])

  const onCancel = useCallback(() => {
    dispatch(closeModal())
  }, [])

  if (loading === 'pending') {
    return (
      <Box height={450} className="box-flex-center">
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Box width={1} sx={{ overflowY: 'auto' }}>
      <Typography variant={'h3'} align={'center'} className={classes.mainTitleMargin}>
        {t('rec_details:edit_recipient')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Input
            name="firstName"
            label={t('main:first_name')}
            onChange={formik.handleChange}
            value={values.firstName}
            onFocus={onFocus}
            onBlur={onBlur}
            error={touched.firstName ? errors.firstName || '' : ''}
          />
          {values.country === 'RU' && (
            <Input
              name="middleName"
              label={t('main:middle_name')}
              onChange={formik.handleChange}
              value={values.middleName}
              onFocus={onFocus}
              onBlur={onBlur}
              error={touched.middleName ? errors.middleName || '' : ''}
            />
          )}
          <Input
            name="lastName"
            label={t('main:last_name')}
            onChange={formik.handleChange}
            value={values.lastName}
            onFocus={onFocus}
            onBlur={onBlur}
            error={touched.lastName ? errors.lastName || '' : ''}
          />
          <Select
            search
            withFlag
            name="country"
            label={t('main:country')}
            options={countriesOptions}
            handleChange={setCountry}
            value={values.country}
            onFocus={onFocus}
            onBlur={onBlur}
            errorMessage={touched.country ? errors.country || '' : ''}
          />
          <PhoneInput
            label={t('main:phone_number')}
            countries={values.country ? [values.country] : []}
            countrySelectProps={{ value: values.phoneCountry }}
            onChange={formik.handleChange}
            value={values.phoneNumber}
            onFocus={onFocus}
            onBlur={onBlur}
            error={touched.phoneNumber ? errors.phoneNumber || '' : ''}
            // title={phoneNumberExamples[values.phoneCountry] ? `${t('main:e_g')} ${phoneNumberExamples[values.phoneCountry]}` : ''}
            // placeholder={phoneNumberExamples[values.phoneCountry] || ''}
          />
          <Select
            name="relationshipToSender"
            label={t('main:relationship')}
            options={relationshipsOptions}
            onChange={formik.handleChange}
            value={values.relationshipToSender}
            onFocus={onFocus}
            onBlur={onBlur}
            errorMessage={touched.relationshipToSender ? errors.relationshipToSender || '' : ''}
          />
          <Input
            name="address"
            label={t('main:address')}
            onChange={formik.handleChange}
            value={values.address}
            title={t('main:optional')}
            onFocus={onFocus}
            onBlur={onBlur}
            error={touched.address ? errors.address || '' : ''}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={3}>
          <Button
            color="primary"
            variant="contained"
            size={"large"}
            type="submit"
            disabled={!formik.dirty}
          >
            {t('main:save')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default RecipientForm
