import axios, { AxiosInstance } from 'axios'
import { call, put, select, take } from 'redux-saga/effects'
import isFunction from 'lodash/isFunction'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'
import * as transferConstants from 'constants/transfer.constants'
import { openModal, openToast } from 'redux/reducers/ui.reducer'
import { logout } from 'redux/reducers/user.reducer'
import SessionExpired from 'features/ModalContent/SessionExpired'
import store from 'redux/store'

export const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
})

export const firebaseApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_FIREBASE_URL,
})

const version = process.env.REACT_APP_VERSION

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error)
    const originalRequest = error.config

    if (error?.response?.status === 401) {
      const token = sessionStorage.getItem('@AuthToken')
      if (!!token) {
        store.dispatch(logout());
        store.dispatch(openModal({ type: SessionExpired }));
      }
    }
    return Promise.reject(error)
  }
)

let requestInterceptor: number = -1

export const setAuthToken = (token: string) => {
  requestInterceptor = api.interceptors.request.use(
    (config) => {
      config.headers["X-App-Origin"] = 'WEB'
     //  config.headers["X-App-Version"] = version || "2"
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => {
      console.log(error)
      return Promise.reject(error)
    }
  )
}

export const removeAuthToken = () => {
  api.interceptors.request.eject(requestInterceptor)
}

export function* actionsHandler({ url, method, actions, paramsGetter = (data: any) => ({ data }), responseParser = (res: any) => res.data, navigate = {}, toastMessage = {} }: any) {
  const messages = isFunction(toastMessage) ? toastMessage() : toastMessage

  while (true) {
    try {
      const action: { type: string, payload: any } = yield take(actions[REQUEST].type)
      const params: object = yield call(paramsGetter, action.payload)
      const response: { data: any } = yield call(api as any, {
        method,
        url,
        ...params,
      })

      if (response?.data?.status === 'FAIL' && (url !== transferConstants.GET_PRICE_REQUEST || Number(response?.data?.amountSentSrc || '0') === 0)) {
        throw new Error(response.data.message)
      }

      const result = responseParser(response, action.payload)

      if (actions[SUCCESS]) {
        yield put(actions[SUCCESS](result))
      }

      if (navigate[SUCCESS]) {
        const { navigation } = yield select(({ ui }) => ui)
        navigation.navigate(navigate[SUCCESS])
      }

      if (messages[SUCCESS]) {
        yield put(openToast({ type: 'success', toastMessage: messages[SUCCESS] }))
      }
    } catch (err) {
      console.log(err)
      if (actions[FAIL]) {
        yield put(actions[FAIL](err.message || err.toString()))
      }
      if (navigate[FAIL]) {
        const { navigation } = yield select(({ ui }) => ui)
        navigation.navigate(navigate[FAIL])
      }
      if (messages[FAIL]) {
        yield put(openToast({ type: 'error', toastMessage: err.message || err.toString() }))
      }
    }
  }
}
