import {memoize} from 'lodash'
import * as transferConstants from 'constants/transfer.constants'
import { ICurrencyPayload, IPricePayload, IValidatePayload, IDTOnePayload, IPayoutPayload } from 'model/transfer.model'
import {api} from './api'
import { CHECK_FDH_ACCOUNT, GET_LOCATIONS_SETTINGS } from "constants/transfer.constants";

type TSagaCall = (...args: any[]) => any

export const getDestinationCurrencies = memoize(
  async function (payload: ICurrencyPayload) {
    const {data} = await api.post(transferConstants.GET_DESTINATION_CURRENCIES, payload)
    return data.currencies as TSagaCall
  },
  ({destinationCountry, operatorId, payoutType, sourceCountry}) => JSON.stringify({destinationCountry, operatorId, payoutType, sourceCountry})
)

export const getSourceCurrencies = memoize(
  async function (payload: ICurrencyPayload) {
    const {data} = await api.post(transferConstants.GET_SOURCE_CURRENCIES, payload)
    return data.currencies as TSagaCall
  },
  ({destinationCountry, operatorId, payoutType, sourceCountry}) => JSON.stringify({destinationCountry, operatorId, payoutType, sourceCountry})
)

export const getPrice = (payload: IPricePayload) => api.post(transferConstants.GET_PRICE_REQUEST, payload)

export const getTransactionLimit = memoize(
  async function (payload) {
    return api.post(transferConstants.GET_PRICE_LIMIT, payload)
  },
  (...args) => JSON.stringify(args)
)

export const getIfscCodeData = (payload: { ifscCode: string }) => api.post(transferConstants.GET_IFSC_CODE, payload)

export const checkFdhAccountRequest = (payload: any) => api.post(transferConstants.CHECK_FDH_ACCOUNT, payload)

export const createTransaction = (payload: any) => api.post(transferConstants.CREATE_TRANSACTION, payload)

export const getPaymentLink = (payload: any) => api.post(transferConstants.GET_PAYMENT_URL, payload)

export const confirmTransaction = (payload: any) => api.post(transferConstants.CONFIRM_TRANSACTION, payload)

export const validateTransaction = (payload: IValidatePayload) => api.post(transferConstants.VALIDATE_TRANSACTION, payload)

export const getLocationsSettings = (payload: object) => api.post(transferConstants.GET_LOCATIONS_SETTINGS, payload)

export const getDTOneData = memoize(
  async function ({url, ...payload}: IDTOnePayload) {
    const {data} = await api.post(url, payload)
    return data
  },
  ({phoneNumber}) => JSON.stringify({phoneNumber})
)

export const getDiscountByPromoCode = (payload: any) =>
  api.post('/campaign/mobile/verifyPromoCode', payload);

export const getPayoutOperatorsRequest = () => api.get('/mobile-configuration/payout')

export const getPayoutParameters = (payload: {operatorId: string}) =>
  api.post('/payout/getParameters', payload)

export const verifyPayout = (payload: IPayoutPayload) =>
  api.post('/payout/verify', payload);

export const sendPreparePayout = (payload: any) =>
  api.post('/payout/prepare', payload);

export const sendConfirmPayout = (payload: any) =>
  api.post('/payout/confirm', payload);

export const checkAcquiringSystem = () => api.get('/organization/activeAcquiringSystem');

export const getTokenCards = () => api.get('user/cards');

export const deleteTokenCard = (id: string) => api.delete(`user/cards/${id}`);
