import React, {useMemo} from 'react'
import {Grid} from '@mui/material'
import {makeStyles} from 'tss-react/mui'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'redux/app.hooks'
import Input from 'components/Input'
import {CopyButton} from 'components/Button'

const useStyles = makeStyles()(({palette, breakpoints}) => ({
    inputElem: {
      '&:nth-of-type(odd)': {
        paddingRight: 15,
      },
      '&:nth-of-type(even)': {
        paddingLeft: 15,
      },
      '& .MuiInputBase-root': {
        backgroundColor: palette.designGrey['100'],
        paddingRight: 8,
      },
      [breakpoints.down(600)]: {
        '&:nth-of-type(odd)': {
          paddingRight: 0,
        },
        '&:nth-of-type(even)': {
          paddingLeft: 0,
        },
      },
    },
    copyBtn: {
      padding: 0,
      marginBottom: 3,
      backgroundColor: "white",
      '&:hover, &:active, &:hover:active': {
        backgroundColor: "white",
      }
    },
  })
)

const Requisites = () => {
  const {t} = useTranslation('settings')
  const {classes} = useStyles()

  const {bankAccountDetails} = useAppSelector((state) => state.configuration)

  const requisites = useMemo(() => {
    return !!bankAccountDetails
      ? [...bankAccountDetails].sort((a, b) => a.orderPriority - b.orderPriority)
      : // .reduce((acc: object, el: { fieldName: string, fieldValue: string }) =>
        // ({...acc, [el.fieldName]: el.fieldValue}), {})
        null
  }, [bankAccountDetails])

  return (
    <Grid container>
      <Grid item xs={12} sm={6} className={classes.inputElem}>
        <Input
          label={t('main:bank_name')}
          value={requisites ? requisites[0].fieldValue : ''}
          readOnly
          endAdornment={<CopyButton copyText={requisites ? requisites[0].fieldValue : ''} copyName={t('main:bank_name')} className={classes.copyBtn} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.inputElem}>
        <Input
          label={t('main:branch')}
          value={requisites ? requisites[1].fieldValue : ''}
          readOnly
          endAdornment={<CopyButton copyText={requisites ? requisites[1].fieldValue : ''} copyName={t('main:branch')} className={classes.copyBtn} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.inputElem}>
        <Input
          label={t('transaction:account name')}
          value={requisites ? requisites[2].fieldValue : ''}
          readOnly
          endAdornment={<CopyButton copyText={requisites ? requisites[2].fieldValue : ''} copyName={t('transaction:account name')} className={classes.copyBtn} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.inputElem}>
        <Input
          label={t('main:account_number')}
          value={requisites ? requisites[3].fieldValue : ''}
          readOnly
          endAdornment={<CopyButton copyText={requisites ? requisites[3].fieldValue : ''} copyName={t('main:account_number')} className={classes.copyBtn} />}
        />
      </Grid>
    </Grid>
  )
}

export default Requisites
