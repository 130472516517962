import React, { useCallback, useEffect } from 'react'
import { Typography, Box, CardMedia, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { openModal, closeModal } from 'redux/reducers/ui.reducer'
import { finishRequiredAction } from 'redux/reducers/user.reducer'
import ActionsRequired from "./ActionsRequired";

const ActionSuccess = ({ action }: { action: string }) => {

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()
  const { actionRequiredReasons } = useAppSelector(({ user }) => user.userMeta)

  useEffect(() => {
    dispatch(finishRequiredAction(action))
  }, [])

  const onFinish = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const onContinue = useCallback(() => {
    dispatch(openModal({
      type: ActionsRequired,
      paperClass: 'wide-modal',
    }))
  }, [])

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}{'!'}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={6.25} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('action_required:thank_provided_data')}
        </Typography>
        <Box mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {t('action_required:will_check_data')}
          </Typography>
        </Box>
        <Box mt={5} display={"flex"} justifyContent={"center"}>
          <Button
            variant={"contained"}
            color={!(actionRequiredReasons || []).length ? 'secondary' : 'primary'}
            onClick={!(actionRequiredReasons || []).length ? onFinish : onContinue}
          >
            {!(actionRequiredReasons || []).length
              ? t('main:finish')
              : t('main:continue')
            }
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionSuccess
