import {fork, all, ForkEffect} from 'redux-saga/effects'
import user from './user.sagas'
import configuration from './configuration.sagas'
import recipients from './recipients.saga'
import transfer from './transfer.saga'
import transactions from './transactions.saga'
import wallet from './wallet.sagas'

export default function* rootSaga() {
  yield all<ForkEffect>([
    fork(user),
    fork(configuration),
    fork(recipients),
    fork(transfer),
    fork(transactions),
    fork(wallet),
  ])
}
