import React, { FC, useCallback } from 'react'
import { Typography, Box, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'
import Button from 'components/Button'

const ResetPinSuccess = ({receiveCvv = false}: {receiveCvv: boolean}) => {

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={6.25} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {!receiveCvv ? t('cards:change_pin_success') : t('info:request_sent')}
        </Typography>
        <Box px={"20%"} mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {!receiveCvv ? t('cards:will_receive_new_pin') : t('cards:wait_sms_for_cvv')}
          </Typography>
        </Box>
        <Box mt={8} display="flex" justifyContent="center">
          <Button variant="contained" color="secondary" onClick={onClose}>
            {t('main:finish')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default (ResetPinSuccess)
