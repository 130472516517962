import React, { FC, memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Typography, Box, CardMedia, SxProps } from '@mui/material'
import classnames from 'classnames'
import Icon from '../Icon'
import AmountRow from '../AmountRow'
import { ICard } from 'model/wallet.model'
import { ShekelSquareSign, DollarSquareSign, EuroSquareSign } from '../WalletViews'

import useStyles from './styles'

interface ICurrencySignProps {
  size?: number
  currency: string
  isBlocked?: boolean
}

interface IAccountProps {
  caption: string
  amount: number | string
  currency: string
  pan: string
  className?: string
  sx?: SxProps
  selected?: boolean
}

interface ICardViewProps {
  card: ICard
  className?: string
  sx?: SxProps
}

export const ShekelAccountVertical = ({ size, isBlocked }: Omit<ICurrencySignProps, 'currency'>) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.verticalCard} bgcolor={!isBlocked ? "info.dark" : "#E7EDF3"}>
      <Box
        className={classnames(classes.verticalTop, 'currencySign')}
        style={{
          background: !isBlocked
            ? 'linear-gradient(104.95deg, #1D97F3 0%, #4FF5F5 100%)'
            : 'linear-gradient(104.95deg, #ACBCC9 0%, #E7EDF3 100%)'
        }}
      >
        <Icon icon="shekel" size={12} color="#FFF"/>
      </Box>
      <Box className={classes.verticalMarker} bgcolor={!isBlocked ? "shekel.dark" : "designGrey.300"}/>
    </Box>
  )
}

export const DollarAccountVertical = ({ size, isBlocked }: Omit<ICurrencySignProps, 'currency'>) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.verticalCard} bgcolor={!isBlocked ? "#BCF2F2" : "#E7EDF3"}>
      <Box
        className={classnames(classes.verticalTop, 'currencySign')}
        style={{
          background: !isBlocked
            ? 'linear-gradient(104.95deg, #00BFBF 0%, #94FFB6 100%)'
            : 'linear-gradient(104.95deg, #ACBCC9 0%, #E7EDF3 100%)'
        }}
      >
        <Icon icon="dollar" size={12} color="#FFF"/>
      </Box>
      <Box flex={1}>
        <Box className={classes.verticalMarker} bgcolor={!isBlocked ? "#00E4E4" : "designGrey.300"}/>
      </Box>
    </Box>
  )
}

export const EuroAccountVertical = ({ size, isBlocked }: Omit<ICurrencySignProps, 'currency'>) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.verticalCard} bgcolor={!isBlocked ? "#CBD2F3" : "#E7EDF3"}>
      <Box
        className={classnames(classes.verticalTop, 'currencySign')}
        style={{
          background: !isBlocked
            ? 'linear-gradient(104.95deg, #526DFF 0%, #DCBCFF 100%)'
            : 'linear-gradient(104.95deg, #ACBCC9 0%, #E7EDF3 100%)'
        }}
      >
        <Icon icon="euro1" size={12} color="#FFF"/>
      </Box>
      <Box flex={1}>
        <Box className={classes.verticalMarker} bgcolor={!isBlocked ? "#4560F2" : "designGrey.300"}/>
      </Box>
    </Box>
  )
}

export const CurrencyVerticalSign = ({ currency, size, isBlocked }: ICurrencySignProps) => {
  if (currency === 'ILS') {
    return <ShekelAccountVertical size={size} isBlocked={isBlocked}/>
  } else if (currency === 'USD') {
    return <DollarAccountVertical size={size} isBlocked={!!isBlocked}/>
  } else if (currency === 'EUR') {
    return <EuroAccountVertical size={size} isBlocked={!!isBlocked}/>
  }
  return <ShekelAccountVertical size={size} isBlocked={!!isBlocked}/>
}

export const CurrencySquareSign = ({ currency }: ICurrencySignProps) => {
  if (currency === 'ILS') {
    return <ShekelSquareSign/>
  } else if (currency === 'USD') {
    return <DollarSquareSign/>
  } else if (currency === 'EUR') {
    return <EuroSquareSign/>
  }
  return <ShekelSquareSign/>
}


export const CardAccountData = memo(({
                                       caption,
                                       amount,
                                       currency,
                                       pan,
                                       className = '',
                                       sx = {},
                                       selected = false,
                                     }: IAccountProps) => {

  return (
    <Box className={classnames('horizontalFlex', className)} sx={sx}>
      <CurrencySquareSign currency={currency}/>
      <Box mx={1.25} className={"verticalFlex"}>
        <Box className={"horizontalFlex"} gap={1.5}>
          <Typography variant={"subtitle1"} color={selected ? 'info.main' : 'designGrey.500'}>
            {caption}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={selected ? 'info.main' : 'designGrey.500'}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={selected ? 'info.main' : 'designGrey.500'}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={selected ? 'info.main' : 'designGrey.500'}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={selected ? 'info.main' : 'designGrey.500'}
              ml={0.25} mr={0.5} mt={0.25}
            />
            <Typography variant={"subtitle1"} color={selected ? 'info.main' : 'designGrey.500'} className={"weight500"}>
              {pan.slice(-4)}
            </Typography>
          </Box>
        </Box>
        <AmountRow
          currency={currency}
          amount={Number(amount || '0')}
          variant={"subtitle2"}
          sx={{ fontWeight: 500, color: selected ? 'info.main' : 'grey.600' }}
        />
      </Box>
    </Box>
  )
})

export const CardAccountVisa = memo(({
                                       caption,
                                       amount,
                                       currency,
                                       pan,
                                       className = '',
                                       sx = {},
                                     }: IAccountProps) => {

  return (
    <Box className={classnames('horizontalRow', className)} sx={sx}>
      <Box className={"verticalFlex"} flex={1} gap={0.25}>
        <Box className={"horizontalFlex"} gap={1.5}>
          <Typography variant={"body1"} sx={{ color: 'designGrey.400', fontWeight: 500 }}>
            {caption}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={"designGrey.400"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={"designGrey.400"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={"designGrey.400"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={"designGrey.400"}
              ml={0.25} mr={0.5} mt={0.25}
            />
            <Typography variant={"body1"} sx={{ color: 'designGrey.400', fontWeight: 500 }}>
              {pan.slice(-4)}
            </Typography>
          </Box>
        </Box>
        <AmountRow
          currency={currency}
          amount={Number(amount || 0)}
          variant={"subtitle2"}
          sx={{ color: 'grey.600', fontWeight: 600 }}
        />
      </Box>
      <CardMedia
        component={"img"}
        src={require('assets/images/visa.png')}
        sx={{ width: 40, height: 13 }}
      />
    </Box>
  )
})

export const CardView = memo(({ card, className = '', sx = {} }: ICardViewProps) => {

  const { t, i18n } = useTranslation()

  const primaryAccount = useMemo(() => {
    const primary = card.cardAccountsInfo.find(account => +account.accountType === 1)
    return primary || card.cardAccountsInfo[0]
  }, [card]);

  return (
    <Box className={classnames('horizontalRow', className)} sx={sx}>
      <Box className={"horizontalFlex"} flex={1}>
        <CurrencyVerticalSign currency={primaryAccount.currency} isBlocked={card.pcStatus?.status !== 'VALID_CARD'}/>
        <Box mx={1.25} className={"verticalFlex"}>
          <Typography variant={"body1"} color={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "textPrimary"}>
            {card.productNameForCustomer || card.productCaption}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', direction: 'ltr' }}>
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              ml={0.25} mr={0.5} mt={0.25}
            />
            <Typography
              variant={"body2"}
              color={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "textSecondary"}
              className={"weight500"}
            >
              {card.pan.slice(-4)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        pt={0.2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-end"}
        alignSelf={"stretch"}
        justifyContent={"space-between"}
      >
        <AmountRow
          currency={primaryAccount.currency}
          amount={Number(primaryAccount.availableBalance || 0)}
          variant={"body1"}
          sx={{ color: card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : 'grey.600', fontWeight: 600 }}
        />
        <Typography variant={"body2"} color={"textSecondary"} className={"weight500"}>
          {card.isVirtualCard ? 'digital' : card.cardAccountsInfo.length > 1 ? 'multi' : ''}
        </Typography>
      </Box>
    </Box>
  )
})

export const CardForSelect = memo(({ card, select, className = '', sx = {} }: ICardViewProps & { select: boolean }) => {

  const { t, i18n } = useTranslation()

  const primaryAccount = useMemo(() => {
    const primary = card.cardAccountsInfo.find(account => +account.accountType === 1)
    return primary || card.cardAccountsInfo[0]
  }, [card]);

  return (
    <Box className={classnames('horizontalFlex', { onHoverMenu: select }, className)} sx={sx}>
      <CurrencyVerticalSign currency={primaryAccount.currency} isBlocked={card.pcStatus?.status !== 'VALID_CARD'}/>
      <Box mx={1.5} className={"verticalFlex"}>
        <Box display={"flex"} gap={1.5}>
          <Typography variant={"body2"} color={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}>
            {card.productNameForCustomer || card.productCaption}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              mx={0.25} mt={0.25}
            />
            <Box
              display={"inline-block"}
              width={2} height={2}
              borderRadius={1}
              bgcolor={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              ml={0.25} mr={0.5} mt={0.25}
            />
            <Typography
              variant={"body2"}
              color={card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : "designGrey.500"}
              className={"weight500"}
            >
              {card.pan.slice(-4)}
            </Typography>
          </Box>
        </Box>
        <AmountRow
          currency={primaryAccount.currency}
          amount={Number(primaryAccount.availableBalance || 0)}
          variant={"subtitle2"}
          sx={{ color: card.pcStatus?.status !== 'VALID_CARD' ? 'designGrey.400' : 'grey.600', fontWeight: 500 }}
        />
      </Box>
    </Box>
  )
})
