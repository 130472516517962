import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({breakpoints, palette}) =>
  ({
    containerRoot: {
      paddingLeft: 315,
      paddingRight: 315,
      display: 'flex',
      flexGrow: 1,
      [breakpoints.down(1520)]: {
        paddingLeft: 120,
        paddingRight: 120,
      },
      [breakpoints.down(1440)]: {
        paddingLeft: 80,
        paddingRight: 80,
      },
      [breakpoints.down(768)]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [breakpoints.down(560)]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [breakpoints.down(460)]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    paperRoot: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    mainBackground: {
      background: palette.background.default,
    },
    linearBackGround: {
      background: 'linear-gradient(110.54deg, #FFFFFF 55%, #B6DEFF 100%)',
    },
    startContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    headerRoot: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    toolbarRoot: {
      height: 124,
      paddingLeft: 40,
      paddingRight: 40,
      justifyContent: 'space-between',
    },
    mediaRoot: {
      width: 160,
      height: 46,
    },
    rightHeader: {
      color: '#252627',
      display: 'flex',
      alignItems: 'center',
    },
    bottomRoot: {
      height: 40,
      backgroundColor: '#45494D',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#fff',
    },
    relative: {
      position: 'relative',
    },
  }))

export default useStyles
