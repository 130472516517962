import React, { FC } from 'react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'

import useStyles from './styles'

interface IConfirm {
  title?: string
  text: string
  cancelHandler: () => void
  confirmHandler: () => void
}

const ConfirmModal: FC<IConfirm> = ({ title, text, cancelHandler, confirmHandler }) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  return (
    <Box width={1}>
      {!!title && (
        <Typography variant={'h3'} align={'center'} className={classes.mainTitleMargin}>
          {title}
        </Typography>
      )}
      <Typography variant={'h5'} align={'center'} sx={{ color: 'grey.600', fontWeight: 500 }}>
        {text}
      </Typography>

      <Box width={1} className={"horizontalFlex"} justifyContent={"center"} gap={3.75} mt={5}>
        <Button
          color="inherit"
          variant="outlined"
          onClick={cancelHandler}
        >
          {t('main:cancel')}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={confirmHandler}
        >
          {t('main:confirm')}
        </Button>
      </Box>
    </Box>
  )
}

export default (ConfirmModal)
