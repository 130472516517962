import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { Grid, Paper, Box } from '@mui/material'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import Header from 'features/AuthHeader'
import Footer from 'features/Footer'
import TopFooter from 'features/TopFooter'
import { MainHelpSquare, SendMessageSquare, IntercomSquare, WhatsAppSquare } from 'components/Button'
import { toggleSideBar } from 'redux/reducers/ui.reducer'
import Layout from './Layout'

import useStyles from './styles'

const Empty = lazy(() => import('./Empty'))
const Dashboard = lazy(() => import('../Dashboard'))
const PaymentsAndTransfers = lazy(() => import('../PaymentsAndTransfers'))
const TransferDetails = lazy(() => import('../TransferDetails'))
const TransferFailed = lazy(() => import('../TransferFailed'))
const TransferFinish = lazy(() => import('../TransferFinish'))
const TransferCompliance = lazy(() => import('../TransferCompliance'))
const PaymentByCard = lazy(() => import('../PaymentByCard'))
const AdditionalFields = lazy(() => import('../AdditionalFields'))
const History = lazy(() => import('../History'))
const Profile = lazy(() => import('../Profile'))
const Settings = lazy(() => import('../Settings'))
const Offices = lazy(() => import('../Offices'))
const CreateWidget = lazy(() => import('../CreateWidget'))
const FAQ = lazy(() => import('../FAQ'))
const Questionnaire = lazy(() => import('../Questionnaire'))
const CardProducts = lazy(() => import('../CardProducts'))
const IssueCard = lazy(() => import('../IssueCard'))
const CloseCardInfo = lazy(() => import('../CloseCardInfo'))
const CardIssueSuccess = lazy(() => import('../CardIssueSuccess'))
const TariffsLimits = lazy(() => import('../TariffsLimits'))
const OpenWalletSuccess = lazy(() => import('../Questionnaire/OpenWalletSuccess'))
const Success = lazy(() => import('unauthPages/Signup/SignupSuccess'))
const MainAnalytics = lazy(() => import('../Analytics'))
const Analytics = lazy(() => import('../Analytics/Analytics'))
const Category = lazy(() => import('../Analytics/Category'))
const TransactionDetails = lazy(() => import('../TransactionDetails'))
const OperationDetails = lazy(() => import('../OperationDetails'))
const WalletInfo = lazy(() => import('../WalletInfo'))
const CardInfo = lazy(() => import('../CardInfo'))
const WorldTransfer = lazy(() => import('../WorldTransfer'))
const Recipients = lazy(() => import('../Recipients'))
const ExchangeCurrency = lazy(() => import('../ExchangeCurrency'))
const CurrencyRates = lazy(() => import('../ExchangeCurrency/CurrencyRates'))
const CurrencyExchange = lazy(() => import('../ExchangeCurrency/CurrencyExchange'))
const BetweenAccounts = lazy(() => import('../ExchangeCurrency/BetweenAccounts'))
const ExchangeSummary = lazy(() => import('../ExchangeCurrency/ExchangeSummary'))
const DepositWallet = lazy(() => import('../DepositWallet'))
const WithdrawWallet = lazy(() => import('../WithdrawWallet'))
const WithdrawSummary = lazy(() => import('../WithdrawWallet/WithdrawSummary'))
const StbClientPayment = lazy(() => import('../StbClientPayment'))
const PayoutWallet = lazy(() => import('../PayoutWallet'))
const PayoutSuccess = lazy(() => import('../PayoutWallet/PayoutSuccess'))
const IsraelAddress = lazy(() => import('../IsraelAddress'))
const ProofOfIncome = lazy(() => import('../ProofOfIncome'))
const EditMainDocument = lazy(() => import('../EditMainDocument'))
const SecondDocument = lazy(() => import('../SecondDocument'))
const ChangeSelfie = lazy(() => import('../ChangeSelfie'))
const KycQuestionnaire = lazy(() => import('../KycQuestionnaire'))
const QuestionnaireSummary = lazy(() => import('../KycQuestionnaire/QuestionnaireSummary'))
const TransferGuide = lazy(() => import('../TransferGuide'))

const Main = () => {
  const { classes } = useStyles()

  const location = useLocation()

  const { sideBarOpen, barHeight } = useAppSelector(state => state.ui)
  const dispatch = useAppDispatch()

  const [screenWidth, setScreenWidth] = useState<number>(0)

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    if (window.innerWidth < 1280) {
      dispatch(toggleSideBar())
    }
    const onResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    if (screenWidth > 0 && sideBarOpen && screenWidth < 768) {
      dispatch(toggleSideBar())
    }
  }, [location.pathname])

  useEffect(() => {
    if (screenWidth > 0 && sideBarOpen && screenWidth < 1280) {
      dispatch(toggleSideBar())
    }
  }, [screenWidth])

  return (
    <Paper elevation={0} className={location.pathname !== '/something' ? classes.paperRoot : classes.successRoot}>
      <Header/>
      <Box className={classes.container} minHeight={barHeight + 60}>
        <Grid container sx={{ height: '100%' }}>
          <Suspense
            fallback={
              <Box className="box-flex-center" height={"80vh"}>
              </Box>
            }
          >
            <Routes>
              <Route path="/" element={<Layout/>}>
                <Route index element={<Empty/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/transfer-guide" element={<TransferGuide/>}/>
                <Route path="/transfer-create" element={<PaymentsAndTransfers/>}/>
                <Route path="/transfer-details" element={<TransferDetails/>}/>
                <Route path="/transfer-finish" element={<TransferFinish/>}/>
                <Route path="/transfer-failed" element={<TransferFailed/>}/>
                <Route path="/transfer-compliance" element={<TransferCompliance/>}/>
                <Route path="/compliance-block" element={<TransferCompliance/>}/>
                <Route path="/payment-by-other-card" element={<PaymentByCard/>}/>
                <Route path="/additional-fields" element={<AdditionalFields/>}/>
                <Route path="/history" element={<History/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/wallet/:walletNumber" element={<WalletInfo/>}/>
                <Route path="/card-info/:cardId" element={<CardInfo/>}/>
                <Route path="/world-transfers" element={<WorldTransfer/>}/>
                <Route path="/analytics" element={<MainAnalytics/>}>
                  <Route index element={<Analytics/>}/>
                  <Route path=":month/:mode/:currency/:name" element={<Category/>}/>
                </Route>
                <Route path="/questionnaire" element={<Questionnaire/>}/>
                <Route path="/card-products" element={<CardProducts/>}/>
                <Route path="/issue-card/:cardProductId" element={<IssueCard/>}/>
                <Route path="/tariffs/:cardProductId" element={<TariffsLimits/>}/>
                <Route path="/open-wallet-success" element={<OpenWalletSuccess/>}/>
                <Route path="/issue-success/:requestId" element={<CardIssueSuccess/>}/>
                <Route path="/issue-success" element={<CardIssueSuccess/>}/>
                <Route path="/close-card-info/:requestId" element={<CloseCardInfo/>}/>
                <Route path="/offices" element={<Offices/>}/>
                <Route path="/recipients" element={<Recipients/>}/>
                <Route path="/frequency-questions" element={<FAQ/>}/>
                <Route path="/israel-address" element={<IsraelAddress/>}/>
                <Route path="/proof-income" element={<ProofOfIncome/>}/>
                <Route path="/second-document" element={<SecondDocument/>}/>
                <Route path="/main-document" element={<EditMainDocument/>}/>
                <Route path="/change-selfie" element={<ChangeSelfie/>}/>
                <Route path="/kyc-questionnaire" element={<KycQuestionnaire/>}/>
                <Route path="/questionnaire-summary" element={<QuestionnaireSummary/>}/>
                <Route path="/check-success" element={<Success/>}/>
                <Route path="/add-widget" element={<CreateWidget/>}/>
                <Route path="/transaction-details" element={<TransactionDetails/>}/>
                <Route path="/operation-details" element={<OperationDetails/>}/>
                <Route path="/exchange-currency" element={<ExchangeCurrency/>}>
                  <Route index element={<CurrencyRates/>}/>
                  <Route path="/exchange-currency/exchange" element={<CurrencyExchange/>}/>
                  <Route path="/exchange-currency/between-accounts" element={<BetweenAccounts/>}/>
                  <Route path="/exchange-currency/summary" element={<ExchangeSummary/>}/>
                </Route>
                <Route path="/deposit-card/:cardId" element={<DepositWallet/>}/>
                <Route path="/deposit-wallet/:walletId" element={<DepositWallet/>}/>
                <Route path="/deposit" element={<DepositWallet/>}/>
                <Route path="/withdraw-card/:cardId" element={<WithdrawWallet/>}/>
                <Route path="/withdraw-wallet/:walletId" element={<WithdrawWallet/>}/>
                <Route path="/withdraw" element={<WithdrawWallet/>}/>
                <Route path="/withdraw-summary" element={<WithdrawSummary/>}/>
                <Route path="/stb-payment" element={<StbClientPayment/>}/>
                <Route path="/payout-wallet" element={<PayoutWallet/>}/>
                <Route path="/payout-success" element={<PayoutSuccess/>}/>
                <Route path="*" element={<Navigate to={"/dashboard"}/>}/>
              </Route>
            </Routes>
          </Suspense>
        </Grid>
      </Box>

      <TopFooter sideBarOpen={sideBarOpen}/>

      <Footer/>

      <Box className="helpSection">
        <Box className="slideBlock">
          <SendMessageSquare className={classes.supportBtn}/>
          <IntercomSquare className={classes.supportBtn}/>
          <WhatsAppSquare/>
        </Box>
        <MainHelpSquare/>
      </Box>
    </Paper>
  )
}

export default Main
