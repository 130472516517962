import * as walletConstants from 'constants/wallet.constants'
import { IChangeStatusRequest } from 'model/wallet.model'
import { api } from './api'

export const getWalletList = () => api.get('/api/wallet/api/v1/public/mobile/wallet');

// export const getRecipientWalletList = (userId: string) => api.get(`/api/wallet/wallet/${userId}/part`);

export const getRecipientWallet = (params: any) => api.get(`/transaction/recipient`, {params});

export const getWalletCards = (walletId: number) =>
  api.get(`/api/card/api/v1/public/mobile/cards/card-info?walletId=${walletId}`);

export const getUserByPhone = (payload: { query: string, queryType: string }) =>
  api.post(`/user/queryOrg`, payload);

export const getIssueCardRequests = () =>
  api.get(`/users/all/issue/card/`);

export const closeCardRequest = (payload: object) =>
  api.post('/users/close/cards', payload);

export const getListCardProducts = () =>
  api.get(`/api/card/api/v1/public/card-products?size=50&page=0`);

export const changeCardCurrencyRequest = ({ cardId, accountNum }: { cardId: string, accountNum: string }) =>
  api.post(`/api/card/api/v1/public/mobile/cards/${cardId}/active-account`, { accountNum });

export const getRequestInfo = (reqId: string) =>
  api.get(`/api/card/api/v1/public/card-app-reqs/${reqId}`);

export const checkQuestionnaireAnswers = (payload: object) =>
  api.post(`/questionnaire/answer/get`, payload);

export const getQuestionnaireList = ({ questionnaireName }: { questionnaireName: string }) =>
  api.post(`/mobile/questionnaire/get`, { questionnaireName });

export const setQuestionnaireAnswers = (payload: any) =>
  api.post(`/mobile/questionnaire/answer/update`, payload);

export const resetPinRequest = (cardId: string) =>
  api.post(`/api/card/api/v1/public/mobile/cards/${cardId}/pin-sms`);

export const changeStatusCard = (cardId: number, payload: Omit<IChangeStatusRequest, 'cardId'>) =>
  api.post(`/api/card/api/v1/public/mobile/cards/${cardId}/status`, payload);

export const activateCard = (cardId: number) =>
  api.post(`api/card/api/v1/public/mobile/cards/${cardId}/activate`, {});

export const getTotalDebtsByCard = (cardId: number) =>
  api.get(`api/tariff/api/v1/public/mobile/accruals/cards/${cardId}/debts/total`);

export const getDebtsByCard = (cardId: number) =>
  api.get(`api/tariff/api/v1/public/mobile/accruals/cards/${cardId}/debts`);

export const sendSmsNotification = (payload: { phoneNumber: string }) =>
  api.post('/user/send/transactionNotification', payload);

export const getSmsServiceInfo = (cardId: string) =>
  api.get(`/api/card/api/v1/public/cards/${cardId}/sms-service`);

export const setSmsServiceCard = (cardId: string, payload: any) =>
  api.post(`/api/card/api/v1/public/mobile/cards/${cardId}/sms-service`, payload);

export const deleteSmsServiceCard = (cardId: string) =>
  api.delete(`/api/card/api/v1/public/mobile/cards/${cardId}/sms-service`);

export const receiveCvvRequest = (cardId: string) =>
  api.post(`/api/card/api/v1/public/mobile/cards/${cardId}/resend-vc-data`);
