import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, signInAnonymously, Auth } from 'firebase/auth';
import { getMessaging, isSupported, getToken, onMessage, MessagePayload, Messaging } from 'firebase/messaging';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-9PN25GLEQT',
}

// export const messageListener = (listener: (payload: MessagePayload) => void) => onMessage(messaging, listener)

class NotificationService {
  private app: FirebaseApp;

  private messaging: Messaging;

  private auth: Auth | null = null;

  private firebaseAuthToken: string | undefined = undefined;

  private notificationToken: string | undefined = undefined;

  constructor() {
    const app = initializeApp(config)
    this.app = app
    this.messaging = getMessaging(app)
    this.startFirebase()
  }

  checkNotificationPermission() {
    if ("Notification" in window) {
      isSupported().then(supported => {
        if (Notification.permission === "default" && supported) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              this.startNotificationService()
            }
          });
        } else if (Notification.permission === "granted" && supported) {
          this.startNotificationService()
        }
      })
    }
  }

  startFirebase() {
    this.auth = getAuth(this.app)

    this.createFirebaseAuthToken()

    if ('serviceWorker' in navigator) {
      const start = Date.now();
      while (navigator.serviceWorker.controller?.state !== 'activated') {
        if (Date.now() - start > 12000) {
          break
        }
      }

      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'firebaseConfig',
          config,
          firebaseUrl: process.env.REACT_APP_FIREBASE_URL,
        })
      }

      this.checkNotificationPermission()
    } else {
      this.checkNotificationPermission()
    }
  }

  startNotificationService() {
    if (navigator?.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'startMessaging',
      })
    }
    this.createNotificationToken()
  }

  public getMessaging() {
    return (listener: (payload: MessagePayload) => void) => onMessage(this.messaging as Messaging, listener)
  }

  public getAuth() {
    return this.auth
  }

  public getFirebaseAuthToken() {
    return this.firebaseAuthToken
  }

  public getNotificationToken() {
    return this.notificationToken
  }

  async createFirebaseAuthToken() {
    try {
      if (!!this.auth) {
        const { user } = await signInAnonymously(this.auth)
        const firebaseToken = await user?.getIdToken(true)
        this.firebaseAuthToken = firebaseToken
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'firebaseAuthToken',
            firebaseAuthToken: firebaseToken,
          })
        }
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  async createNotificationToken() {
    try {
      if (!!this.messaging) {
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.getRegistration('/service-worker.js')
            || await navigator.serviceWorker.ready;

          const token = await getToken(this.messaging, {
            vapidKey: 'BApxzK5Qcg5IKvznMSL7Lc7WV6OxMM3YHH_xzwB6AoyR4EQAzMauyHRqBYioaKlCot45aaaibHBeBsRh44pra_0',
            serviceWorkerRegistration: registration,
          })
          this.notificationToken = token
        } else {
          const token = await getToken(this.messaging, {
            vapidKey: 'BApxzK5Qcg5IKvznMSL7Lc7WV6OxMM3YHH_xzwB6AoyR4EQAzMauyHRqBYioaKlCot45aaaibHBeBsRh44pra_0',
          })
          this.notificationToken = token
        }
      }
    } catch (err) {
      console.log(err.message)
    }
  }
}

const notificationService = new NotificationService()

export default notificationService

