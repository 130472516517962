import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { IntercomProvider } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'
import createAppTheme from './theme'
import store from './redux/store'
import Router from './Router'
import Toast from './features/Toast'
import ModalContainer from './features/ModalContainer'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''

const App: FC = () => {
  const { i18n } = useTranslation()

  return (
    <ThemeProvider theme={createAppTheme({ language: i18n.language })}>
      <CssBaseline/>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <Router/>
              <ModalContainer/>
            </BrowserRouter>
            <Toast/>
          </LocalizationProvider>
        </Provider>
      </IntercomProvider>
    </ThemeProvider>
  )
}

export default App
