import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(({ zIndex, palette, breakpoints, spacing, isHebrew }) => ({
  headerRoot: {
    boxShadow: '0px 4px 20px rgba(0, 46, 79, 0.1)',
  },
  zIndexHeader: {
    zIndex: zIndex.drawer + 1,
  },
  zIndexNotification: {
    zIndex: zIndex.modal + 1,
  },
  toolbarRoot: {
    height: 84,
    paddingLeft: 40,
    paddingRight: 40,
    direction: isHebrew ? 'rtl' : 'ltr',
    [breakpoints.down(600)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  central: {
    display: 'flex',
    height: '100%',
    width: 1312,
    maxWidth: 1312,
    margin: '0 auto',
    justifyContent: 'center',
   // flexDirection: isHebrew ? 'row-reverse' : 'row',
    [breakpoints.down(1360)]: {
      width: 960,
      maxWidth: 960,
    },
  },
  linkNavigation: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    cursor: 'pointer',
    borderRadius: 4,
    color: palette.grey["700"],
    textDecoration: 'none',
    '&:hover': {
      color: palette.primary.main,
      '& svg path': {
        fill: palette.primary.main,
      },
    }
  },
  commonLink: {
    color: 'inherit',
    textDecoration: 'none !important',
  },
  activeLink: {
    background: 'linear-gradient(rgba(240, 245, 250, 0) 0%, #F0F5FA 100%)',
    borderWidth: 0,
    borderBottomWidth: 4,
    borderBottomColor: palette.primary.main,
    borderStyle: 'solid',
    height: 'calc(100% + 4px)',
    color: palette.primary.main,
  },
  right: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacing(2.5),
  },
  mediaRoot: {
    width: 160,
    height: 46,
    [breakpoints.down(768)]: {
      width: 42,
      height: 42,
    }
  },
  mediaNotFound: {
    width: 280,
    height: 218,
    margin: '30px auto'
  },
  mediaEmpty: {
    width: 258,
    height: 190,
    margin: '30px auto'
  },
  titleImage: {
    color: palette.designGrey["500"],
    textAlign: 'center',
  },
  iconWrap: {
    width: 44,
    height: 44,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: palette.grey['200'],
    cursor: 'pointer',
    marginRight: 30,
    marginLeft: 0,
    [breakpoints.down(600)]: {
      marginRight: 0,
      marginLeft: 30,
    },
  },
  userSelect: {
    width: 194,
    '& .MuiTypography-root.MuiTypography-subtitle2': {
      direction: 'ltr',
    },
    '& .MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-input': {
      paddingRight: isHebrew ? 16 : 32,
      paddingLeft: isHebrew ? 36 : 16,
      '& .MuiAvatar-root': {
        marginLeft: isHebrew ? 4 : 0,
        marginRight: isHebrew ? 0 : 10,
      },
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      right: isHebrew ? 'auto' : 6,
      left: isHebrew ? 6 : 'auto',
    },
    [breakpoints.down(600)]: {
      width: 44,
      '& .MuiOutlinedInput-input': {
        padding: 7,
      },
    },
  },
  paperMenu: {
    width: 210,
    backgroundColor: palette.background.default,
    borderRadius: 12,
    overflow: 'hidden',
    '& .MuiMenuItem-root': {
      backgroundColor: palette.background.default,
      direction: isHebrew ? 'rtl' : 'ltr',
      marginTop: 2,
      '& .MuiListItemIcon-root': {
        minWidth: 28,
      },
      '& .MuiListItemText-primary': {
        fontSize: '0.875rem',
        textAlign: isHebrew ? 'right' : 'left',
      },
      '&:hover': {
        backgroundColor: palette.background.default,
        '& .MuiListItemIcon-root svg path': {
          fill: palette.primary.light,
        },
        '& .MuiListItemText-primary': {
          color: palette.primary.light,
        },
      },
    },
  },
  notificationHeader: {
    backgroundColor: palette.background.default,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    flexDirection: isHebrew ? 'row-reverse' : 'row',
    boxShadow: '0px 4px 12px rgba(0, 46, 79, 0.08)',
    marginBottom: 5,
  },
  sideHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
  },
  paperNotification: {
    marginTop: 22,
    backgroundColor: palette.background.default,
    borderRadius: 20,
    width: 690,
    boxShadow: '0px 4px 16px rgba(0, 46, 79, 0.15)',
    overflow: 'hidden',
    [breakpoints.down(768)]: {
      width: '100vw',
      maxWidth: '100vw',
      left: '0 !important',
    }
  },
  notificationsList: {
    height: 'calc(100vh - 280px)',
    overflow: 'auto',
  },
  searchInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  notificationContainer: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 15,
    paddingBottom: 15,
  },
  notificationWrapper: {
    paddingLeft: 27,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 14,
  },
  notificationBody: {
    backgroundColor: palette.background.default,
    borderRadius: 12,
    position: 'relative',
    paddingTop: 15,
    paddingBottom: 20,
    paddingLeft: 42,
    paddingRight: 20,
    boxShadow: '0px 2px 6px rgba(0, 46, 79, 0.15)',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.background.default,
    '&:hover': {
      borderColor: palette.info.main,
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  logoWrapper: {
    backgroundColor: palette.background.default,
    boxShadow: '0px 1px 6px rgba(0, 46, 79, 0.15)',
    position: 'absolute',
    top: -2,
    left: -22,
  },
  dateHeader: {
    color: palette.grey['900'],
    fontWeight: 700,
    marginBottom: 5,
    direction: isHebrew ? 'rtl' : 'ltr',
  },
  timeText: {
    color: palette.grey['900'],
  },
  bodyText: {
    color: palette.grey['700'],
  },
  checkBox: {
    position: 'absolute',
    left: 8,
    bottom: 8,
    backgroundColor: 'transparent',
    [breakpoints.down(768)]: {
      left: 6,
      bottom: 6,
    },
  },
  unread: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: palette.error.main,
    position: 'absolute',
    top: 6,
    right: 6,
  },
  unreadContainer: {
    backgroundColor: palette.grey["100"],
    marginLeft: 2,
    marginRight: 2,
  },
  notificationQty: {
    height: 30,
    border: '1px solid #CFDAE4',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    padding: '0 13px',
    '& .MuiTypography-root': {
      color: palette.primary.main,
    },
    '& svg path': {
      fill: palette.primary.main,
    },
  },
  copyBtn: {
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: palette.designGrey['100'],
  },
  headerTitle: {
    fontWeight: 600,
    color: palette.designGrey["400"],
    textAlign: 'center',
  },
  activeNotification: {
    backgroundColor: palette.info.dark,
  },
  langSelect: {
    width: 110,
    '& .MuiInputBase-root': {
      '& .MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: isHebrew ? spacing(2) : spacing(4),
        paddingLeft: isHebrew ? spacing(4) : spacing(2),
      },
      '& .MuiIconButton-root': {
        right: isHebrew ? 'initial' : 4,
        left: isHebrew ? 4 : 'initial',
      },
    },
  },
}))

export default useStyles
