import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, zIndex, breakpoints}) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  //  justifyContent: 'space-between',
    backgroundColor: palette.grey['700'],
    zIndex: 900,
    paddingRight: 20,
    paddingLeft: 20,
    marginTop: 'auto',
    flexWrap: 'wrap',
    [breakpoints.down(460)]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  box: {
    flex: 1,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.background.default,
  },
  centerLink: {
    fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      backgroundColor: palette.designGrey['900'],
    },
  },
  left: {
    minWidth: 320,
    height: 40,
    color: palette.designGrey['200'],
    fontWeight: 500,
    justifyContent: 'flex-start',
    [breakpoints.down(960)]: {
      justifyContent: 'center',
    },
    [breakpoints.down(520)]: {
      justifyContent: 'flex-start',
      gap: 20,
      paddingLeft: 10,
    },
  },
  unAuthLeft: {
    [breakpoints.down(1280)]: {
      justifyContent: 'center',
    },
    [breakpoints.down(520)]: {
      justifyContent: 'flex-start',
    },
  },
  center: {
    minWidth: 320,
    justifyContent: 'center',
    [breakpoints.down(1280)]: {
      justifyContent: 'flex-end',
    },
    [breakpoints.down(960)]: {
      justifyContent: 'center',
    },
    [breakpoints.down(520)]: {
      justifyContent: 'flex-start',
    },
  },
  right: {
    minWidth: 620,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    [breakpoints.down(1360)]: {
      justifyContent: 'center',
    },
    [breakpoints.down(680)]: {
      minWidth: 560,
      paddingBottom: 4,
    },
    [breakpoints.down(560)]: {
      minWidth: 520,
      justifyContent: 'flex-start',
      paddingBottom: 4,
    },
  },
  rightLink: {
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: palette.designGrey['900'],
    },
    [breakpoints.down(520)]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  cookie: {
    display: 'none',
    [breakpoints.down(520)]: {
      display: 'flex',
    },
  },
  divider: {
    backgroundColor: palette.designGrey['200'],
    width: 1,
    height: 20,
    margin: '0 15px',
    [breakpoints.down(520)]: {
      display: 'none',
    },
  },
}))

export default useStyles
