import React, { KeyboardEvent, useCallback, useEffect, useState, useRef } from 'react'
import { Typography, Box, Fade } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import Input from 'components/Input'
import Button from 'components/Button'
import { createSessionChangePhoneRequest, validateNewPhoneRequest, approveNewPhoneRequest } from 'redux/reducers/user.reducer'
import { approveActivePhone } from 'services/apiServices/user.service'
import { openModal } from 'redux/reducers/ui.reducer'
import EnterNewPhone from './EnterNewPhone'

import useStyles from './styles'

interface IProps {
  phoneNumber: string
  newPhone: boolean
}

const ConfirmPhone = ({ phoneNumber, newPhone }: IProps) => {
  const { classes } = useStyles()

  const timer = useRef(0)
  const cursorTimer = useRef<number>(-1)
  const cursorValue = useRef<boolean>(true)
  const inputRef = useRef(null)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { sessionId, errorMessage, spinner } = useAppSelector(({ user }) => user.changePhoneNumber)
  const { phoneCountry } = useAppSelector(({ user }) => user.userMeta)

  const [code, setCode] = useState<string>('')
  const [timerValue, setTimerValue] = useState(60)
  const [error, setError] = useState(false)
  const [cursor, setCursor] = useState<boolean>(false)

  useEffect(() => {
    if (!newPhone) {
      dispatch(createSessionChangePhoneRequest())
      createTimer()
    }
    cursorTimer.current = window.setInterval(() => {
      setCursor(cursorValue.current)
      cursorValue.current = !cursorValue.current
    }, 450)

    return () => {
      setError(false)
      setCode('')
      window.clearInterval(cursorTimer.current)
      window.clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    if (!!code && error) {
      setError(false)
    }
  }, [code])

  useEffect(() => {
    if (!!errorMessage) {
      setError(true);
      setCode('');
      if (!!inputRef?.current) {
        (inputRef.current as HTMLInputElement).focus();
      }
    }
  }, [errorMessage])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setCode('')
    } else if (e.target.value.length <= 4) {
      setCode(e.target.value)
    }
  }, [])

  const handleCode = useCallback(async () => {
    try {
      if (!newPhone) {
        const { data: { status, message } } = await approveActivePhone({
          sessionId,
          smsCode: code,
        });
        if (status !== 'SUCCESS') {
          throw new Error(message || '')
        }
        dispatch(openModal({ type: EnterNewPhone }))
      } else {
        dispatch(approveNewPhoneRequest({ smsCode: code }))
      }
    } catch (err) {
      setError(true);
      setCode('');
      if (!!inputRef?.current) {
        (inputRef.current as HTMLInputElement).focus();
      }
    }
  }, [code, newPhone, sessionId, inputRef?.current])

  const createTimer = useCallback(() => {
    window.clearInterval(timer.current);
    setTimerValue(60);
    let counter = 60;
    let start = Date.now();
    // @ts-ignore
    timer.current = window.setInterval(() => {
      const duration = counter - Math.floor((Date.now() - start) / 1000);
      if (duration <= 0) {
        window.clearInterval(timer.current);
        setTimerValue(0);
      } else {
        setTimerValue(duration);
      }
    }, 990);
  }, [])

  const handleResend = useCallback(() => {
    if (!newPhone) {
      dispatch(createSessionChangePhoneRequest())
      createTimer()
    } else {
      dispatch(validateNewPhoneRequest({
        phoneCountryCode: phoneCountry,
        phoneNumber,
      }))
    }
  }, [phoneNumber, phoneCountry, newPhone])

  const handleKeyboard = useCallback((e: KeyboardEvent) => {
      if (code.length === 4 && e.key === 'Enter') {
        handleCode()
      }
    }, [code, handleCode])

  return (
    <Box width={1}>
      <Typography variant={'h4'} align={'center'} className={classes.mainTitleMargin}>
        {t('enter_code:confirm_change_phone')}
      </Typography>
      <Box>
        <Typography variant={'subtitle2'} sx={{ color: 'designGrey.500', textAlign: "center" }}>
          {t('enter_code:we_sent_sms_to')}
        </Typography>
        <Typography variant={'subtitle2'} sx={{ fontWeight: 700, color: 'designGrey.500', textAlign: "center", direction: 'ltr' }}>
          {`${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 10)} ${phoneNumber.slice(10)}`}
        </Typography>
      </Box>
      <Box className="box-flex-center" mt={3}>
        <Button disabled={timerValue > 0} onClick={handleResend} className={classes.textBtn}>
          {t('enter_code:resend')}
        </Button>
      </Box>
      <Typography variant={'subtitle2'} sx={{ color: 'designGrey.400', textAlign: 'center' }}>
        {'('}{timerValue}{' '}{t('enter_code:sec_remain')}{')'}
      </Typography>
      <Box position={"relative"} mt={2}>
        <Input
          inputRef={inputRef}
          autoFocus
          label={''}
          value={code}
          type={"number"}
          onChange={onChange}
          onKeyPress={handleKeyboard}
          sx={{ opacity: 0, zIndex: 3 }}
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={2.5} zIndex={1}
          position={"absolute"}
          top={22} left={0}
          right={0} bottom={0}
        >
          <Box className={classes.digitInput}>
            {!code[0] ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[0]}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[0] && !code[1]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[1] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[1] && !code[2]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[2] || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.digitInput}>
            {(!!code[2] && !code[3]) ? (
              <Fade in={cursor}>
                <Box width={"1px"} height={17} bgcolor={"grey.500"}/>
              </Fade>
            ) : (
              <Typography variant={"title"} color={"inherit"}>
                {code[3] || ''}
              </Typography>
            )}
          </Box>
        </Box>
        {error && (
          <Box
            zIndex={1}
            position={"absolute"}
            left={0} right={0} bottom={0}
            color={"error.main"}
          >
            <Typography variant={"body1"} color={"inherit"} align={"center"}>
              {t('validation:wrong_verification_code')}{'.'}
            </Typography>
          </Box>
        )}
      </Box>
      {errorMessage && (
        <Typography variant={"body1"} color={"error.main"} align={"center"}>
          {errorMessage}
        </Typography>
      )}
      <Box mt={4} display={"flex"} justifyContent={"center"}>
        <Button
          color="primary"
          variant="contained"
          disabled={spinner || !code || code.length !== 4}
          onClick={handleCode}
        >
          {t('main:confirm')}
        </Button>
      </Box>
    </Box>
  )
}

export default ConfirmPhone
