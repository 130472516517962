import React from 'react'
import { Typography, Box, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChatButton, WhatsAppButton } from 'components/Button'
import Icon from 'components/Icon'
import { phoneSupport } from 'constants/configuration.constants'

import useStyles from './styles'

const MismatchData = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  return (
    <Box width={1}>
      <Box className={classes.imageContainer}>
        <CardMedia
          component={"img"}
          src={require('assets/images/mismatch-data.png')}
          sx={{ height: 'auto', width: 102 }}
        />
      </Box>
      <Box pt={5}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('verify_number:combination_not_registered')}
        </Typography>
        <Box mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {t('verify_number:try_another')}
          </Typography>
        </Box>

        <Box mt={5}>
          <Typography variant={"h6"}  align={"center"} sx={{ color: 'designGrey.500', fontWeight: 600 }}>
            {t('transaction:contact_us')}
          </Typography>
          <Box className={"horizontalFlex"} justifyContent={"center"} mt={2.5} gap={3.75}>
            <ChatButton/>
            <WhatsAppButton/>
          </Box>
          <Box className={"horizontalFlex"} justifyContent={"center"} gap={1.75} mt={3.75}>
            <Icon icon={"number1"} size={16}/>
            <Typography variant={"body1"} sx={{ fontWeight: 600, color: 'grey.600', direction: 'ltr' }}>
              {`${phoneSupport.slice(0, 4)} ${phoneSupport.slice(4, 6)} ${phoneSupport.slice(6, 10)} ${phoneSupport.slice(10)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default (MismatchData)
