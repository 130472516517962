import React, {FC, useCallback} from 'react'
import MuiButton, {ButtonProps} from '@mui/material/Button'
import Box from '@mui/material/Box'
import {useIntercom} from 'react-use-intercom'
import copy from 'copy-to-clipboard'
import classnames from 'classnames'
import {useAppDispatch} from 'redux/app.hooks'
import {useTranslation} from 'react-i18next'
import {openToast, openModal, closeModal} from 'redux/reducers/ui.reducer'
import SendMessage from 'features/ModalContent/SendMessage'
import {whatsAppNumber} from 'constants/configuration.constants'
import Icon from '../Icon'

import useStyles from './styles'

interface IButtonProps extends ButtonProps {
  className?: string
  copyText?: string
  copyName?: string
}

type ICustomButton = IButtonProps

type INotificationButton = ICustomButton & { unread: number }

const Button: FC<IButtonProps> = ({className = '', ...props}) => {
  return <MuiButton disableElevation disableFocusRipple disableRipple className={className} {...props} />
}

export const MainHelpSquare = (({className = '', ...props}: ICustomButton) => {
  const {classes} = useStyles()

  return (
    <MuiButton color="primary" variant="outlined" className={classnames(classes.squareRoot, className)} {...props}>
      <Icon color="#33A7FF" icon="faquestion" size={20}/>
    </MuiButton>
  )
})

export const SendMessageSquare = (({className = '', ...props}: ICustomButton) => {
  const {classes} = useStyles()

  const dispatch = useAppDispatch()

  const sendMessage = useCallback(() => {
    dispatch(openModal({type: SendMessage}))
  }, [])

  return (
    <MuiButton color="primary" variant="outlined" onClick={sendMessage}
               className={classnames(classes.squareRoot, className)} {...props}>
      <Icon color="#33A7FF" icon="email" size={19}/>
    </MuiButton>
  )
})

export const WhatsAppSquare = (({className = ''}: ICustomButton) => {
  const {classes} = useStyles()

  return (
    <MuiButton
      variant="outlined"
      color={"inherit"}
      className={classnames(classes.squareRoot, classes.whatsappRoot, className)}
      href={`http://wa.me/${whatsAppNumber}`}
      target="_blank"
    >
      <Icon color="#25D366" icon="whatsapp" size={26}/>
    </MuiButton>
  )
})

export const IntercomSquare = (({className = '', ...props}: ICustomButton) => {
  const {classes} = useStyles()

  return (
    <MuiButton id={'intercom-button'} color="primary" variant="outlined" disableRipple disableFocusRipple
               className={classnames(classes.squareRoot, className)} {...props}>
      <Icon icon="chat" size={19}/>
    </MuiButton>
  )
})

export const CopyButton = (({className = '', copyText, copyName, ...props}: ICustomButton) => {
  const {classes} = useStyles()

  const dispatch = useAppDispatch()

  const {t} = useTranslation('main')

  const copyToClipboard = useCallback(() => {
    copy(copyText || '')
    dispatch(
      openToast({
        type: 'success',
        toastMessage: `${copyName || t('transaction:deposit_code')} ${t('copied_to_clipboard')}`,
      })
    )
  }, [copyText, copyName])

  return (
    <MuiButton
      className={classnames(classes.circleRoot, 'box-flex-center', classes.infoBackground, className)}
      onClick={copyToClipboard}
      {...props}
    >
      <Icon icon="copy" size={20}/>
    </MuiButton>
  )
})

export const InfoButton = (({className = '', ...props}: ICustomButton) => {
  const {classes} = useStyles()

  const openTooltip = useCallback(() => {
  }, [])

  return (
    <MuiButton
      className={classnames(classes.circleRoot, 'box-flex-center', classes.defaultBackground, className)}
      onClick={openTooltip}
      {...props}
    >
      <Icon icon="info-circle" size={16}/>
    </MuiButton>
  )
})

export const SendMessageButton = (({className = '', ...props}: ICustomButton) => {
  const {classes} = useStyles()

  const {t} = useTranslation<string>()

  const dispatch = useAppDispatch()

  const sendMessage = useCallback(() => {
    dispatch(openModal({type: SendMessage}))
  }, [])

  return (
    <MuiButton variant="outlined" color="primary" onClick={sendMessage} className={classnames(className)}
               startIcon={<Icon icon="email" size={16} color="#0090FF"/>} {...props}>
      {t('settings:send_us_message') as string}
    </MuiButton>
  )
})

export const ChatButton = (({className = '', ...props}: ICustomButton) => {
  const dispatch = useAppDispatch()

  const {t} = useTranslation()

  const {show} = useIntercom()

  return (
    <MuiButton
      variant="outlined" color="primary"
      className={classnames(className)}
      startIcon={<Icon icon="chat" size={16} color="#0090FF"/>}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e)
        } else {
          dispatch(closeModal())
        }
        show()
      }}
      {...props}
    >
      {t('settings:chat_us') as string}
    </MuiButton>
  )
})

export const WhatsAppButton = (({className = '', onClick}: ICustomButton) => {
  const {classes} = useStyles()

  const dispatch = useAppDispatch()

  return (
    <MuiButton
      variant="outlined"
      href={`http://wa.me/${whatsAppNumber}`}
      target="_blank"
      className={classnames(classes.whatsappRoot, className)}
      onClick={(e: any) => {
        if (onClick) {
          onClick(e)
        } else {
          dispatch(closeModal())
        }
      }}
      startIcon={<Icon icon="whatsapp" size={21} color="green"/>}
    >
      Whatsapp
    </MuiButton>
  )
})

export const NotificationButton = (({
                                      className = '',
                                      unread,
                                      ...props
                                    }: INotificationButton) => {
  const {classes} = useStyles()

  return (
    <MuiButton
      disableRipple
      variant="outlined"
      className={classnames(classes.squareRoot, classes.notification, className)}
      {...props}
    >
      <Icon icon="notification" size={20}/>
      {unread > 0 && <Box className={classes.unread}/>}
    </MuiButton>
  )
})

export default Button
