import React, { useCallback } from 'react'
import { Typography, Box, CardMedia, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/app.hooks'
import { closeModal } from 'redux/reducers/ui.reducer'

const KycNotValid = () => {

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()
  const { activeComplianceRules } = useAppSelector(({ transfer }) => transfer)

  const navigate = useNavigate()

  const onFinish = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()

    dispatch(closeModal())
    navigate('/dashboard')
  }, [navigate])

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {!activeComplianceRules.CHECK_DOSSIER?.KYC
                ? ''
                : t('compliance_failure:transaction_cant_execute') + '!'
              }
            </Typography>
            <CardMedia
              image={require('assets/images/verification-denied.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={10} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('questionnaire:kyc_not_valid')}
        </Typography>

        <Box mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ fontWeight: 500, color: 'designGrey.500' }}>
            {!activeComplianceRules.CHECK_DOSSIER?.KYC
              ? t('questionnaire:thanks_answering')
              : t('questionnaire:kyc_transaction_not_finished')
            }
          </Typography>
        </Box>


        <Box mt={3.75} display={"flex"} justifyContent={"center"}>
          <Button
            variant={"outlined"}
            color={"inherit"}
            size={"large"}
            onClick={onFinish}
          >
            {t('main:back_to_home')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default KycNotValid
