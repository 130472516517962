import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(({ palette, breakpoints, isHebrew, spacing }) => ({
  root: {
    width: '100%',
    height: 44,
    borderRadius: 12,
    padding: 0,
    overflow: 'hidden',
    backgroundColor: palette.background.default,
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: palette.background.default,
      fontSize: '0.9375rem',
      fontWeight: 500,
      direction: isHebrew ? 'rtl' : 'ltr',
      '&.MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: isHebrew ? spacing(2.5) : spacing(5.5),
        paddingLeft: isHebrew ? spacing(5.5) : spacing(2.5),
      },
      '&.Mui-disabled': {
        backgroundColor: palette.grey['100'],
        '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey['200'],
        },
      },
      '&:hover': {
        backgroundColor: palette.grey["100"],
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: palette.designGrey['300'],
      borderRadius: 12,
      backgroundColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.error.main,
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  language: {
    '&.MuiOutlinedInput-input.MuiInputBase-input': {
      paddingRight: isHebrew ? spacing(2) : spacing(4.5),
      paddingLeft: isHebrew ? spacing(4.5) : spacing(2.5),
    },
    '& .MuiIconButton-root': {
      right: isHebrew ? 'initial' : 6,
      left: isHebrew ? 6 : 'initial',
    },
  },
  formikRoot: {
    paddingTop: 22,
    paddingBottom: 22,
    '& .MuiInputLabel-formControl': {
      transform: 'none',
      lineHeight: '15px',
      top: 0,
      left: isHebrew ? 'auto' : 0,
      right: isHebrew ? 1 : 'auto',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: palette.designGrey['400'],
      '&:hover': {
        color: palette.designGrey['400'],
      },
      '&.Mui-focused': {
        color: palette.primary.main,
      },
      '&.Mui-error': {
        color: palette.error.main,
      },
    },
  },
  paper: {
    borderRadius: 12,
    backgroundColor: palette.background.default,
    boxShadow: '0px 4px 12px rgba(0, 46, 79, 0.08)',
    maxHeight: 460,
  },
  chevronBtn: {
    position: 'absolute',
    backgroundColor: 'transparent',
    left: isHebrew ? spacing(1.25) : 'auto',
    right: isHebrew ? 'auto' : spacing(1.25),
  },
  menuLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    direction: isHebrew ? 'rtl' : 'ltr',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: isHebrew ? 'row-reverse' : 'row',
    marginTop: 4,
    marginBottom: 4,
  },
  menuItemCommon: {
    flexDirection: isHebrew ? 'row-reverse' : 'row',
    backgroundColor: palette.background.default,
    borderRadius: 6,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    marginTop: 10,
    marginBottom: 10,
    marginLeft: spacing(2.5),
    marginRight: spacing(2.5),
    color: palette.text.primary,
    '&.Mui-selected, &.Mui-focusVisible': {
      backgroundColor: palette.background.default,
      color: palette.info.main,
      '& .box-flag-wrapper': {
        borderColor: palette.grey.A700,
        backgroundColor: palette.grey.A700,
      },
      '&:hover': {
        backgroundColor: palette.background.default,
      },
      '&:not(.currencySign) > svg path': {
        fill: palette.info.main,
      },
    },
    '&:hover': {
      color: palette.primary.light,
      backgroundColor: palette.background.default,
      '& .MuiBox-root': {
        borderColor: palette.primary.light,
        '&:not(.currencySign) > svg path': {
          fill: palette.primary.light,
        },
      },
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #EEF3F6',
    }
  },
  menuItemMultiple: {
    direction: isHebrew ? 'rtl' : 'ltr',
    borderRadius: 12,
    color: palette.text.primary,
    margin: '4px 20px',
    paddingRight: 6,
    paddingLeft: 6,
    '&.Mui-selected': {
      backgroundColor: palette.background.default,
      color: palette.primary.light,
      '& .MuiBox-root svg path': {
        fill: palette.primary.light,
      },
      '&:hover': {
        backgroundColor: palette.grey["100"],
      },
    },
    '&:hover:not(.Mui-selected)': {
      color: palette.primary.light,
      backgroundColor: palette.background.default,
      '& svg path': {
        fill: palette.primary.light,
      },
    },
  },
  option: {
    backgroundColor: palette.background.default,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    direction: isHebrew ? 'rtl' : 'ltr',
    '&[aria-selected="true"]': {
      backgroundColor: palette.background.default,
      color: palette.info.main,
      '& .box-flag-wrapper': {
        backgroundColor: palette.grey.A700,
        borderColor: palette.grey.A700,
      },
      '& path': {
        fill: palette.info.main,
      },
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.background.default,
      color: palette.primary.light,
      '& .box-flag-wrapper': {
        borderColor: palette.primary.light,
      },
    },
  },
  optionMultiple: {
    direction: isHebrew ? 'rtl' : 'ltr',
    backgroundColor: palette.background.default,
    paddingRight: 8,
    paddingLeft: 8,
    borderRadius: 12,
    margin: '4px 20px',
    '&[aria-selected="true"]': {
      backgroundColor: palette.background.default,
      color: palette.primary.light,
      '&:hover': {
        backgroundColor: palette.info.light,
        color: palette.text.primary,
        borderColor: palette.primary.light,
      },
    },
    '&[data-focus="true"]:not([aria-selected="true"])': {
      backgroundColor: palette.background.default,
      color: palette.primary.light,
      borderColor: palette.primary.light,
    },
  },
  flagWrapper: {
    width: 30,
    height: 30,
    minWidth: 30,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: palette.designGrey['200'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  flag: {
    marginRight: isHebrew ? 0 : 9,
    marginLeft: isHebrew ? 9 : 0,
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: isHebrew ? 0 : 10,
    marginLeft: isHebrew ? 10 : 0,
    marginBottom: 2,
  },
  recipientAvatar: {
    marginRight: isHebrew ? 0 : 10,
    marginLeft: isHebrew ? 10 : 0,
    color: palette.primary.light,
  },
  image: {
    width: 'auto',
    height: 22,
    marginRight: isHebrew ? 0 : 10,
    marginLeft: isHebrew ? 10 : 0,
  },
  icon: {
    marginRight: isHebrew ? 0 : 10,
    marginLeft: isHebrew ? 10 : 0,
  },
  addRecipient: {
    height: 64,
    borderBottom: '1px solid #EEF3F7',
  },
  infoTooltip: {
    display: 'flex',
    direction: isHebrew ? 'rtl' : 'ltr',
    marginLeft: isHebrew ? 0 : 'auto',
    marginRight: isHebrew ? 'auto' : 0,
  },
  multiCheckbox: {
    backgroundColor: 'transparent',
    marginRight: isHebrew ? 0 : 8,
    marginLeft: isHebrew ? 8 : 0,
  },
  rightPart: {
    cursor: 'pointer',
    marginLeft: isHebrew ? 8 : 'auto',
    marginRight: isHebrew ? 'auto' : 8,
    padding: 1,
  },
  selectedItem: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.success.main,
  },
  height56: {
    height: 56,
  },
  autoCompleteRoot: {
    direction: isHebrew ? 'rtl' : 'ltr',
    '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
      paddingRight: isHebrew ? spacing(2.5) : spacing(2),
      paddingLeft: isHebrew ? spacing(2) : spacing(2.5),
      '& .MuiAutocomplete-endAdornment': {
        right: isHebrew ? 'initial' : 9,
        left: isHebrew ? 9 : 'initial',
      },
      '& .MuiInputAdornment-root': {
        marginRight: isHebrew ? 0 : spacing(1),
        marginLeft: isHebrew ? spacing(1) : 0,
      }
    },
  }
}))

export default useStyles
