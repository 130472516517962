import React, {FC} from 'react'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box'
import flags from 'assets/flags'

const Flag: FC<{ country: string; size?: number }> = ({country, size = 22}) => {
  if (flags[country]) {
    return (
      <Box width={size} height={size} borderRadius={4} boxShadow={2}>
        <CardMedia style={{width: size, height: size}} component={'img'} src={flags[country]} />
      </Box>
    )
  }
  return <Box width={size} height={size} borderRadius={4} bgcolor="grey.400" />
}

export default Flag
