import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, breakpoints}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 630,
    padding: '50px 90px',
    backgroundColor: palette.background.default,
    borderRadius: 20,
    border: 'none',
    outline: 'none',
    overflow: 'hidden',
    maxHeight: '95vh',
    [breakpoints.down(640)]: {
      width: '100%',
      padding: '36px 60px',
    },
    [breakpoints.down(520)]: {
      width: '100%',
      padding: '30px 40px',
      height: '95vh',
      alignSelf: 'flex-end',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'auto',
    },
    [breakpoints.down(420)]: {
      width: '100%',
      padding: 24,
      height: '95vh',
      alignSelf: 'flex-end',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'auto',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
}))

export default useStyles
