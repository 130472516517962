import React, {useState, useCallback} from 'react'
import Tooltip, {TooltipProps} from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Icon from '../Icon'

interface IInfoProps extends Omit<TooltipProps, 'children'> {
  className?: string
  size?: number
}

const InfoTooltip = ({className = '', size = 20, title, ...props}: IInfoProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <Tooltip
      title={title}
      className={className}
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      arrow
      leaveDelay={250}
      enterTouchDelay={200}
      leaveTouchDelay={2500}
      {...props}
    >
      <Box width={size} minWidth={size} height={size} className="box-flex-center">
        <Icon icon="info-circle" size={size === 20 ? 16 : size * 0.6} color="#556169" />
      </Box>
    </Tooltip>
  )
}

export default InfoTooltip
