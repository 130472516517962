import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { IRecipient, ICardAccount, initialState } from 'model/recipient.model'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'

const recipientSlice = createSlice({
  name: 'recipients',
  initialState: initialState(),
  reducers: {
    getRecipientsRequest(state) {
      state.loading = 'pending'
    },
    getRecipientsSuccess(state, action: PayloadAction<IRecipient[]>) {
      state.loading = 'success'
      state.recipientsList = action.payload
    },
    getRecipientsFail(state) {
      state.loading = 'failed'
      state.recipientsList = []
    },
    createRecipientRequest(state, action: PayloadAction<Partial<IRecipient>>) {
      state.loading = 'pending'
    },
    createRecipientSuccess(state, action: PayloadAction<IRecipient>) {
      state.loading = 'success'
      state.recipientsList = [...current(state).recipientsList, action.payload]
    },
    createRecipientFail(state) {
      state.loading = 'failed'
    },
    editRecipientRequest(state, action: PayloadAction<Partial<IRecipient>>) {
      state.loading = 'pending'
      const selected = current(state).selectedRecipient
      if (!!selected) {
        state.selectedRecipient = { ...selected, edited: '' }
      }
    },
    editRecipientSuccess(state, action: PayloadAction<IRecipient>) {
      state.loading = 'success'
      const list = [...current(state).recipientsList]
        .map((recipient) =>
          (recipient.recipientId === action.payload.recipientId ? { ...action.payload, edited: '' } : recipient))
      state.recipientsList = list
      const selected = current(state).selectedRecipient
      if (!!selected) {
        state.selectedRecipient = { ...action.payload }
      }
    },
    editRecipientFail(state, action: PayloadAction<{ recipientId: string, error: string }>) {
      state.loading = 'failed'
      const list = current(state).recipientsList
        .map((recipient) => (recipient.recipientId === action.payload.recipientId
            ? { ...recipient, edited: action.payload.error }
            : recipient
        ))
      state.recipientsList = list
    },
    selectRecipient(state, action: PayloadAction<IRecipient | null>) {
      state.selectedRecipient = action.payload
    },
    deleteCardAccountRequest(state, action: PayloadAction<{ recipientId: string, cardAccountId: string }>) {
      state.deleteCardLoading = 'pending'
    },
    deleteCardAccountSuccess(state, action: PayloadAction<{ cardAccounts: ICardAccount[], recipientId: string }>) {
      state.deleteCardLoading = 'success'
      const list = current(state).recipientsList
        .map((recipient) => (recipient.recipientId === action.payload.recipientId
            ? { ...recipient, cardAccounts: action.payload.cardAccounts }
            : recipient
        ))
      state.recipientsList = list
      const selected = current(state).selectedRecipient
      if (selected) {
        state.selectedRecipient = { ...selected, cardAccounts: action.payload.cardAccounts }
      }
    },
    deleteCardAccountFail(state) {
      state.deleteCardLoading = 'failed'
    },
  },
})

export const {
  getRecipientsFail,
  getRecipientsRequest,
  getRecipientsSuccess,
  selectRecipient,
  editRecipientFail,
  editRecipientRequest,
  editRecipientSuccess,
  createRecipientFail,
  createRecipientRequest,
  createRecipientSuccess,
  deleteCardAccountFail,
  deleteCardAccountRequest,
  deleteCardAccountSuccess,
} = recipientSlice.actions

export const getRecipientsActions = {
  [REQUEST]: getRecipientsRequest,
  [SUCCESS]: getRecipientsSuccess,
  [FAIL]: getRecipientsFail,
}

export const createRecipientActions = {
  [REQUEST]: createRecipientRequest,
  [SUCCESS]: createRecipientSuccess,
  [FAIL]: createRecipientFail,
}

export const editRecipientActions = {
  [REQUEST]: editRecipientRequest,
  [SUCCESS]: editRecipientSuccess,
  [FAIL]: editRecipientFail,
}

export const deleteCardAccountActions = {
  [REQUEST]: deleteCardAccountRequest,
  [SUCCESS]: deleteCardAccountSuccess,
  [FAIL]: deleteCardAccountFail,
}

export default recipientSlice.reducer
