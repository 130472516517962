import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({breakpoints, palette, spacing}) => ({
  paperRoot: {
    background: palette.background.default,
  },
  successRoot: {
    background: 'linear-gradient(180deg, #CFEAFF 0%, #FFFFFF 100%)',
  },
  container: {
    width: 1312,
    maxWidth: 1312,
    margin: '84px auto 0',
   // minHeight: 'calc(100vh - 314px)',
    paddingBottom: spacing(5),
    [breakpoints.down(1360)]: {
      width: 944,
      maxWidth: 944,
    },
    [breakpoints.down(960)]: {
      width: 608,
      maxWidth: 608,
    },
  },
  supportBtn: {
    marginRight: 10,
  },
  backArrow: {
    marginTop: 16,
    marginBottom: 28,
    justifyContent: 'flex-start',
    '& .MuiButton-label': {
      color: palette.grey['900'],
    },
  },
}))

export default useStyles
