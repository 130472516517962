import React from 'react'
import {Box, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import Requisites from '../Requisites'

export default () => {
  const {t} = useTranslation()

  return (
    <Box>
      <Typography variant="h6" align="center" style={{color: '#556169', fontWeight: 600, marginBottom: 56}}>
        {`${t('info:requisites')} STB Union`}
      </Typography>
      <Requisites/>
    </Box>
  )
}
