import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, breakpoints, scrollbarWidth, spacing, isHebrew}) =>
  ({
    root: {
      display: 'flex',
      direction: isHebrew ? 'rtl' : 'ltr',
      position: 'relative',
      backgroundColor: palette.grey['100'],
      width: '100vw',
      height: 190,
      paddingTop: 38,
      paddingLeft: `calc(50vw - 656px + ${scrollbarWidth / 2}px)`,
      paddingRight: 'calc(50vw - 656px)',
      [breakpoints.down(1360)]: {
        paddingLeft: `calc(50vw - 472px + ${scrollbarWidth / 2}px)`,
        paddingRight: `calc(50vw - 472px - ${scrollbarWidth / 2}px)`,
      },
      [breakpoints.down(960)]: {
        paddingLeft: `calc(50vw - 304px + ${scrollbarWidth / 2}px)`,
        paddingRight: `calc(50vw - 304px - ${scrollbarWidth / 2}px)`,
      },
      [breakpoints.down(620)]: {
        flexWrap: 'wrap',
      },
    },
    sectionTitle: {
      color: palette.grey['300'],
      fontWeight: 'bold',
    },
    socialWrapper: {
      width: 44,
      height: 44,
      borderRadius: 14,
      backgroundColor: palette.background.default,
      border: '1px solid',
      borderColor: palette.grey['100'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: palette.info.light,
        borderColor: palette.primary.main,
      },
      '&:hover': {
        borderColor: palette.primary.main,
      },
    },
    socialIconWrap: {
      width: 24,
      height: 24,
      borderRadius: 12,
      marginRight: 0,
    },
    fb: {backgroundColor: '#3B5998'},
    twitter: {backgroundColor: '#55ACEE'},
    linkedin: {backgroundColor: '#0077B5'},
    storeTitle: {
      width: '100%',
      marginLeft: 'calc(100% - 408px)',
      [breakpoints.down(960)]: {
        marginLeft: 0,
      },
    },
    storeSection: {
      display: 'flex',
      gap: spacing(2.5),
      [breakpoints.down(960)]: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      },
      [breakpoints.down(620)]: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      },
    },
    storeImage: {
      width: 130,
      height: 44,
      marginBottom: 0,
      borderRadius: 12,
      '&:hover': {
        border: '1px solid #33A7FF',
      },
      [breakpoints.down(960)]: {
        '&:first-child': {
          marginBottom: 20,
        },
      },
    },
    qrCode: {
      marginTop: -32,
      borderRadius: 14,
      padding: 6,
      backgroundColor: 'white',
      [breakpoints.down(960)]: {
        display: 'none',
      },
    },
    linkPage: {
      color: palette.grey['500'],
      fontWeight: 700,
      '&:hover': {
        color: palette.primary.light,
      },
    },
  }))

export default useStyles
