import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, transitions}) =>
  ({
    squareRoot: {
      width: 44,
      minWidth: 44,
      height: 44,
      borderRadius: 14,
      padding: 0,
      backgroundColor: palette.background.default,
      '& .MuiButton-label': {
        paddingTop: 0,
      },
    },
    whatsappRoot: {
      borderColor: '#25D366',
      color: '#25D366',
      '& svg path': {
        fill: '#25D366',
      },
      '&:hover svg path': {
        fill: palette.background.default,
      },
      '&:hover': {
        borderColor: '#25D366',
        backgroundColor: '#25D366',
        color: palette.background.default,
      },
      '&.Mui-focusVisible, &:active, &:hover:active': {
        borderColor: '#20BD5B',
        backgroundColor: '#20BD5B',
        color: palette.background.default,
      },
    },
    circleRoot: {
      width: 30,
      minWidth: 30,
      height: 30,
      borderRadius: 15,
      cursor: 'pointer',
    },
    infoBackground: {
      backgroundColor: palette.info.dark,
    },
    defaultBackground: {
      backgroundColor: palette.background.default,
    },
    notification: {
      borderRadius: 12,
      borderColor: palette.designGrey["300"],
      '&:hover path': {
        fill: palette.background.default,
      },
      '&:hover': {
        borderColor: palette.info.main,
        backgroundColor: palette.info.main,
      },
      '&.Mui-focusVisible, &:active': {
        borderColor: palette.primary.light,
        backgroundColor: palette.info.light,
        '& path': {
          fill: palette.primary.light,
        }
      },
    },
    unread: {
      width: 8,
      height: 8,
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: palette.background.default,
      backgroundColor: palette.error.main,
      borderWidth: 1,
      position: 'absolute',
      top: 6,
      right: 6,
    },
  }))

export default useStyles
