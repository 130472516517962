import React, { FC } from 'react'
import { Typography, Box, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IChangeProps {
  sms: string
}

const ChangeNotificationSuccess: FC<IChangeProps> = ({ sms }) => {

  const { t, i18n } = useTranslation()

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={6.25} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {sms === 'PAID' ? t('cards:sms_enabled') : t('cards:sms_disabled')}
        </Typography>
        <Box px={"25%"} mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {sms === 'PAID' ? t('cards:receive_sms_paid') : t('cards:receive_sms_free')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default (ChangeNotificationSuccess)
