import React, { FC, useMemo, useCallback, useState, memo, useReducer, useEffect } from 'react'
import moment from "moment"
import { throttle, debounce } from 'lodash'
import {
  AppBar,
  Toolbar,
  Typography,
  CardMedia,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Popover,
  IconButton,
  Link,
  LinearProgress,
  useMediaQuery
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import Icon from 'components/Icon'
import { NotificationButton, CopyButton } from 'components/Button'
import Input from 'components/Input'
import { Checkbox } from 'components/Checkbox'
import Select, { LanguageSelect } from 'components/Select'
import {
  getNotificationsRequest,
  getNotificationsSuccess,
  setNotificationsQuantity,
  deleteNotificationsRequest,
  logout,
} from 'redux/reducers/user.reducer'
import { toggleSideBar, openModal, closeModal } from 'redux/reducers/ui.reducer'
import { IUserMeta } from 'model/user.model'
import ConfirmModal from 'features/ModalContent/ConfirmModal'
import { setUserLanguage } from 'services/apiServices/user.service'

import useStyles from './styles'

interface IHeaderProps {
  withLogo?: boolean
}

interface INotificationState {
  select: boolean;
  search: boolean;
  remove: boolean;
  activeList: string[];
  copyText: string;
  page: number;
  filter: string;
  currentUnread: number;
}

interface IAction {
  type: string
  value?: boolean | string | number
}

const initialState = {
  select: false,
  search: false,
  remove: false,
  activeList: [] as string[],
  copyText: '',
  page: 0,
  filter: '',
  currentUnread: 0,
}

function reducer(state: INotificationState, action: IAction): INotificationState {
  switch (action.type) {
    case 'select':
    case 'search':
    case 'remove':
      return { ...state, [action.type]: action.value };
    case 'activeList':
      let list = [...state.activeList];
      const idx = list.findIndex(id => id === action.value);
      if (idx >= 0) {
        list.splice(idx, 1);
      } else {
        list = [...list, action.value as string]
      }
      return { ...state, activeList: list };
    case 'clearActiveList':
      return { ...state, activeList: [] };
    case 'copyText':
      return { ...state, copyText: action.value as string };
    case 'setPage':
      return { ...state, page: action.value as number };
    case 'setFilter':
      return { ...state, filter: action.value as string };
    case 'currentUnread':
      return { ...state, currentUnread: action.value as number };
    case 'setSearchMode':
      return { ...state, search: !state.search, activeList: [] };
    case 'setSelectMode':
      return { ...state, select: !state.select, activeList: [] };
    default:
      return state;
  }
}

const AuthHeader: FC<IHeaderProps> = ({ ...props }) => {
  const { classes } = useStyles()

  const match768 = useMediaQuery('(min-width:768px)')
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    userMeta,
    documents,
    notifications,
    loading,
    unreadNotifications,
    notificationQuantity,
  } = useAppSelector((state) => state.user)

  const [anchorUser, setAnchorUser] = useState<null | HTMLElement>(null)
  const [anchorNotification, setAnchorNotification] = React.useState<HTMLElement | null>(null)
  const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(null)
  const [anchorHelp, setAnchorHelp] = useState<null | HTMLElement>(null)

  const [notificationState, notificationDispatch] =
    useReducer<React.Reducer<INotificationState, IAction>>(reducer, initialState)

  useEffect(() => {
    if (loading === 'successNotification') {
      const currentUnread = notifications.reduce((counter, note) => {
        return note.status !== 'read' ? counter + 1 : counter
      }, 0)
      notificationDispatch({ type: 'currentUnread', value: currentUnread })
    }
  }, [loading])

  useEffect(() => {
    if (notificationState.remove) {
      notificationDispatch({ type: 'remove', value: false })
      notificationDispatch({ type: 'clearActiveList' })
    }
  }, [notificationQuantity])

  useEffect(() => {
    handleFiltration(notificationState.filter.trim())
  }, [notificationState.filter])

  useEffect(() => {
    setUserLanguage(i18n.language)
      .catch(err => console.log(err.message))
  }, [i18n.language])

  const DateHeader = useMemo(
    () => ({ date, unread }: { date: string, unread: boolean }) => {
      return (
        <Box
          px={3} py={2}
          className={classnames({ [classes.unreadContainer]: unread })}
        >
          <Typography variant="body2" className={classes.dateHeader}>
            {date}
          </Typography>
          <Divider/>
        </Box>
      )
    },
    [classes]
  )

  const userName = useMemo(() => (userMeta
    ? `${(userMeta as IUserMeta).firstName} ${(userMeta as IUserMeta).lastName}`
    : ''), [userMeta])

  const handleOpenMenu = useCallback((mode: string) => (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget
    switch (mode) {
      case 'user':
        setAnchorUser(target);
        break;
      case 'notification':
        setAnchorNotification(!anchorNotification ? target : null);
        if (!anchorNotification) {
          dispatch(getNotificationsRequest({ page: 0 }))
        }
        break;
      case 'settings':
        setAnchorSettings(target);
        break;
      case 'help':
        setAnchorHelp(target);
        break;
    }
  }, [anchorNotification])

  const handleCloseMenu = useCallback((mode: string) => () => {
    switch (mode) {
      case 'user':
        setAnchorUser(null);
        break;
      case 'notification':
        setAnchorNotification(null);
        break;
      case 'settings':
        setAnchorSettings(null);
        break;
      case 'help':
        setAnchorHelp(null);
        break;
    }
  }, [])

  const openProfile = useCallback(() => {
    handleCloseMenu('user')()
    handleCloseMenu('settings')()
    handleCloseMenu('notification')()
    navigate('/profile')
  }, [navigate])

  const openSettings = useCallback(() => {
    handleCloseMenu('user')()
    handleCloseMenu('settings')()
    handleCloseMenu('notification')()
    navigate('/settings')
  }, [navigate])

  const openSidebar = useCallback(() => {
    dispatch(toggleSideBar())
  }, [])

  const handleLogout = useCallback(() => {
    handleCloseMenu('user')()
    dispatch(logout())
  }, [])

  const setSearchMode = useCallback(() => {
    notificationDispatch({ type: 'setSearchMode' })
    if (notificationState.search) {
      notificationDispatch({ type: 'setFilter', value: '' })
    }
  }, [notificationState.search])

  const setSelectMode = useCallback(() => {
    notificationDispatch({ type: 'setSelectMode' })
    // handleCloseMenu('settings')()
  }, [])

  const toggleActive = useCallback((id: string) => (e: React.MouseEvent) => {
    e.stopPropagation()
    notificationDispatch({ type: 'activeList', value: id })
  }, [])

  const getActiveNotifications = useCallback(() => {
    const content = notifications.filter(note => notificationState.activeList.includes(note.id));
    return content;
  }, [notifications, notificationState.activeList])

  const copyNotifications = useCallback(throttle(() => {
    if (!!notificationState.activeList.length) {
      const content = getActiveNotifications()
      const stringNotifications = content.reduce((acc, note) => {
        let result = acc;
        result += `[${moment(note.createdAt).format('DD.MM.YYYY H:mm')}]\n`;
        result += `${note.title}\n`;
        result += `${note.body}\n\n`;
        return result;
      }, '');
      notificationDispatch({ type: 'copyText', value: stringNotifications })
    }
  }, 100), [notifications, notificationState.activeList])

  const handleDeleteNotifications = useCallback(() => {
    if (!!notificationState.activeList.length) {
      notificationDispatch({ type: 'remove', value: true })

      const cancelHandler = () => {
        dispatch(closeModal())
      }

      const confirmHandler = () => {
        dispatch(deleteNotificationsRequest(notificationState.activeList));
        dispatch(closeModal())
      }

      dispatch(openModal({
        type: ConfirmModal,
        props: {
          title: `${t('settings:delete_notifications')}?`,
          text: t('settings:cant_restored'),
          cancelHandler,
          confirmHandler,
        }
      }))
    }
  }, [notifications, notificationState.activeList])

  const onNotificationScroll = useCallback(throttle((e) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target
    if (scrollHeight - scrollTop < clientHeight + 120
      && loading !== 'pendingNotification' && !notificationState.filter
    ) {
      dispatch(getNotificationsRequest({ page: notificationState.page + 1 }))
      notificationDispatch({ type: 'setPage', value: notificationState.page + 1 })
    }
  }, 80), [loading, notificationState])

  const onNotificationEnter = useCallback(() => {
    if (unreadNotifications > 0 && notificationState.currentUnread > 0) {
      const unreadValue = unreadNotifications - notificationState.currentUnread;
      dispatch(setNotificationsQuantity({ unread: unreadValue >= 0 ? unreadValue : 0 }))
    }
  }, [unreadNotifications, notificationState.currentUnread])

  const onNotificationExit = useCallback(() => {
    onNotificationEnter()
    const readedNotifications = notifications.map(notification => ({ ...notification, status: 'read' }))
    dispatch(getNotificationsSuccess(readedNotifications))
  }, [unreadNotifications, notificationState.currentUnread, notifications])

  const handleFiltration = useCallback(debounce((filter: string) => {
    if (!!filter && filter.length > 2) {
      dispatch(getNotificationsRequest({ filter }))
      notificationDispatch({ type: 'setPage', value: 0 })
    } else if (!filter) {
      dispatch(getNotificationsRequest({ page: 0 }))
    }
  }, 750, { leading: false, trailing: true }), [])

  const renderNotifications = useCallback(() => {
    let currentDate = ''
    const resultList: JSX.Element[] = []

    notifications.map(notification => {
      const isActive = notificationState.activeList.includes(notification.id)
      const isUnread = notification.status !== 'read'
      const notificationDate = moment.utc(notification.createdAt).local().format('YYYY, D MMMM, dddd')
      if (currentDate !== notificationDate) {
        currentDate = notificationDate
        resultList.push(<DateHeader key={currentDate} date={currentDate} unread={isUnread}/>)
      }
      resultList.push(
        <Box
          key={notification.id}
          className={classnames(classes.notificationContainer, { [classes.unreadContainer]: isUnread })}
        >
          <Box
            className={classnames(classes.notificationWrapper, { [classes.activeNotification]: isActive })}
          >
            <Box
              className={classnames(classes.notificationBody, { [classes.cursor]: notificationState.select })}
              onClick={notificationState.select ? toggleActive(notification.id) : () => {
              }}
            >
              <Box className={classnames('Wrapper-filled-44', classes.logoWrapper)}>
                <img src={require('assets/images/logo.png')} width={24}/>
              </Box>
              {notificationState.select && (
                <Checkbox
                  checked={isActive}
                  onClick={toggleActive(notification.id)}
                  className={classes.checkBox}
                />
              )}
              {isUnread && <Box className={classes.unread}/>}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body1" style={{
                  fontWeight: 600,
                  color: notification.data.type === 'WARNING'
                    ? '#EB5757'
                    : notification.data.type === 'SUCCESS' ? '#3AC066' : '#252627'
                }}>
                  {notification.title}
                </Typography>
                <Typography variant="body2" className={classes.timeText}>
                  {moment(notification.createdAt).format('H:mm')}
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.bodyText}>
                {notification.body}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    })
    return resultList
  }, [notifications, notificationState])

  return (
    <AppBar
      color="default"
      position="fixed"
      className={classnames(
        classes.headerRoot,
        !anchorNotification ? classes.zIndexHeader : classes.zIndexNotification
      )}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <Box className={classes.left}>
          {!match768 && (
            <Box className={classes.iconWrap} onClick={openSidebar}>
              <Icon icon="menu2" size={20}/>
            </Box>
          )}
          <Link component={RouterLink} to="/dashboard">
            <CardMedia
              image={match768 ? require('assets/images/logo-row.svg').default : require('assets/images/logo.png')}
              classes={{ root: classes.mediaRoot }}
            />
          </Link>
        </Box>
        <Box className={classes.central}>
          <Box
            className={classnames(classes.linkNavigation, {
              [classes.activeLink]: location.pathname === '/dashboard'
            })}
          >
            <Link component={RouterLink} to="/dashboard" className={classes.commonLink}>
              <Typography variant={"subtitle2"} className={"weight700"} color={"inherit"}>
                {t('nav_bar:main')}
              </Typography>
            </Link>
          </Box>
          <Box
            className={classnames(classes.linkNavigation, {
              [classes.activeLink]: location.pathname === '/transfer-create'
            })}
          >
            <Link component={RouterLink} to="/transfer-create" className={classes.commonLink}>
              <Typography variant={"subtitle2"} className={"weight700"} color={"inherit"}>
                {t('main:transfers')}{' '}{t('main:and')}{' '}
                <Typography
                  variant={"subtitle2"}
                  className={"weight700"}
                  component={"span"}
                  color={"inherit"}
                  style={{ textTransform: 'lowercase' }}
                >
                  {t('main:payments')}
                </Typography>
              </Typography>
            </Link>
          </Box>
          <Box
            className={classnames(classes.linkNavigation, {
              [classes.activeLink]: location.pathname === '/history'
            })}
          >
            <Link component={RouterLink} to="/history" className={classes.commonLink}>
              <Typography variant={"subtitle2"} className={"weight700"} color={"inherit"}>
                {t('nav_bar:history')}
              </Typography>
            </Link>
          </Box>
          <Box
            className={classnames(classes.linkNavigation, {
              [classes.activeLink]: location.pathname === '/offices'
            })}
          >
            <Link component={RouterLink} to="/offices" className={classes.commonLink}>
              <Typography variant={"subtitle2"} className={"weight700"} color={"inherit"}>
                {t('main:offices')}
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box className={classnames(classes.right)}>
          <LanguageSelect className={classes.langSelect} key={i18n.language}/>

          <NotificationButton
            unread={unreadNotifications}
            onClick={handleOpenMenu('notification')}
          />
          <Popover
            id="notifications-popover"
            open={Boolean(anchorNotification)}
            anchorEl={anchorNotification}
            onClose={handleCloseMenu('notification')}
            // hideBackdrop
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{ paper: classes.paperNotification }}
            // onEntered={onNotificationEnter}
            // onExit={onNotificationExit}
            TransitionProps={{
              onEntered: onNotificationEnter,
              onExit: onNotificationExit,
            }}
          >
            <Box>
              <Box className={classes.notificationHeader}>
                {(notificationState.select || true) && (
                  <Box className={"horizontalFlex"}>
                    <Box className={classes.notificationQty}>
                      <Icon icon="check" size={15}/>
                      <Typography variant="subtitle1">
                        {notificationState.activeList.length}
                      </Typography>
                    </Box>
                    <CopyButton
                      copyText={notificationState.copyText}
                      copyName={t('main:notifications')}
                      className={classes.copyBtn}
                      onMouseEnter={copyNotifications}
                      onClick={copyNotifications}
                    />
                    <IconButton
                      onClick={handleDeleteNotifications}
                    >
                      <Icon icon="trash" size={14} color="#EB5757"/>
                    </IconButton>
                  </Box>
                )}

                <Box flexGrow={1} px={3.75}>
                  {!notificationState.search ? (
                    <Typography variant="h6" className={classes.headerTitle}>
                      {t('main:notifications')}
                    </Typography>
                  ) : (
                    <Input
                      className={classes.searchInput}
                      value={notificationState.filter}
                      onChange={e => notificationDispatch({ type: 'setFilter', value: e.target.value })}
                    />
                  )}
                </Box>

                <Box className={"horizontalFlex"} gap={2.5}>
                  <IconButton
                    onClick={setSearchMode}
                  >
                    <Icon icon="search" size={14}/>
                  </IconButton>
                  <IconButton
                    onClick={setSelectMode}
                  >
                    <Icon icon="edit" size={14}/>
                  </IconButton>
                  <Menu
                    id="notification-menu"
                    elevation={1}
                    anchorEl={anchorSettings}
                    keepMounted
                    open={Boolean(anchorSettings)}
                    onClose={handleCloseMenu('settings')}
                    // getContentAnchorEl={null}
                    MenuListProps={{ className: classes.paperMenu }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: -10,
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={setSelectMode}>
                      <ListItemIcon>
                        <Icon icon="check-list" size={18}/>
                      </ListItemIcon>
                      <ListItemText>{t('settings:select_notifications')}</ListItemText>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={openSettings}>
                      <ListItemIcon>
                        <Icon icon="settings" size={19}/>
                      </ListItemIcon>
                      <ListItemText>{t('settings:settings')}</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Box
                className={classes.notificationsList}
                onScroll={onNotificationScroll}>
                {loading === 'pendingNotification' && (
                  <Box mt={4}>
                    <LinearProgress/>
                  </Box>
                )}
                {!notifications.length && notificationState.filter.length > 0 && loading === 'successNotification' && (
                  <Box mt={5}>
                    <Typography variant="h6" className={classes.titleImage}>
                      {t('settings:request_not_found')}
                    </Typography>
                    <CardMedia
                      image={require('assets/images/notification-not-found.png')}
                      className={classes.mediaNotFound}
                    />
                  </Box>
                )}
                {!notifications.length && !notificationState.filter && loading === 'successNotification' && (
                  <Box mt={5}>
                    <Typography variant="h6" className={classes.titleImage}>
                      {t('settings:have_not_notifications')}
                    </Typography>
                    <CardMedia
                      image={require('assets/images/notifications-empty.png')}
                      className={classes.mediaEmpty}
                    />
                  </Box>
                )}
                {renderNotifications()}
              </Box>
            </Box>
          </Popover>
          <Select
            className={classes.userSelect}
            onClick={handleOpenMenu('user')}
            value={'auth-user'}
            options={[
              {
                label: userName,
                value: 'auth-user',
                avatar: !documents?.AVATAR ? require('assets/images/face-incognito.png') : documents.AVATAR.image,
              },
            ]}
          />
          <Menu
            id="user-menu"
            elevation={1}
            anchorEl={anchorUser}
            keepMounted
            open={Boolean(anchorUser)}
            onClose={handleCloseMenu('user')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: 'right',
            }}
            MenuListProps={{ className: classes.paperMenu }}
          >
            <MenuItem onClick={openProfile}>
              <ListItemIcon>
                <Icon icon="user" size={19}/>
              </ListItemIcon>
              <ListItemText>{t('settings:my_profile')}</ListItemText>
            </MenuItem>
            <Box height={"2px"}/>
            <MenuItem onClick={openSettings}>
              <ListItemIcon>
                <Icon icon="settings" size={19}/>
              </ListItemIcon>
              <ListItemText>{t('settings:settings')}</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Icon icon="logout" size={14}/>
              </ListItemIcon>
              <ListItemText>{t('settings:logout')}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default memo(AuthHeader)
