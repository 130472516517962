import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IOffice, IConfig, TMapItems, IExchangeRates, IPaymentLimit} from 'model/configuration.model'
import {REQUEST, SUCCESS, FAIL} from 'constants/global.constants'

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {} as IConfig,
  reducers: {
    getConfigRequest(state) {
      state.loading = 'pending'
    },
    getConfigSuccess(state, action: PayloadAction<Partial<IConfig>>) {
      state.loading = 'success'
      for (const field in action.payload) {
        state[field] = action.payload[field]
      }
    },
    getConfigFail(state) {
      state.loading = 'idle'
    },
    getAllCountriesRequest(state) {
      state.loading = 'pending'
    },
    getAllCountriesFail(state) {
      state.loading = 'fail'
    },
    getAllCountriesSuccess(state, action: PayloadAction<{ countries: string[] }>) {
      state.loading = 'success'
      state.countries = action.payload.countries || []
    },
    getOfficesRequest(state) {
      state.officesLoading = 'pending'
    },
    getOfficesFail(state) {
      state.officesLoading = 'fail'
    },
    getOfficesSuccess(state, action: PayloadAction<IOffice[]>) {
      state.officesLoading = 'success'
      state.offices = action.payload
    },
    getOperatorsRequest(state) {
      state.operatorsLoading = 'pending'
    },
    getOperatorsFail(state) {
      state.operatorsLoading = 'fail'
    },
    getOperatorsSuccess(state, action: PayloadAction<TMapItems>) {
      state.operatorsLoading = 'success'
      state.operators = action.payload
    },
    getExchangeRatesRequest(state, action: PayloadAction<any>) {},
    getExchangeRatesFail(state) {},
    getExchangeRatesSuccess(state, action: PayloadAction<IExchangeRates[]>) {
      state.exchangeRates = action.payload
    },
    getPaymentLimitsRequest(state) {},
    getPaymentLimitsFail(state) {},
    getPaymentLimitsSuccess(state, action: PayloadAction<{[name: string]: IPaymentLimit}>) {
      state.paymentLimits = action.payload
    },
    getSourcesOfFundsRequest(state) {},
    getSourcesOfFundsSuccess(state, action: PayloadAction<string[]>) {
      state.sourcesOfFunds = action.payload
    },
    getTransferPurposesRequest(state) {},
    getTransferPurposesSuccess(state, action: PayloadAction<string[]>) {
      state.transferPurposes = action.payload
    },
    getOccupationsRequest(state) {},
    getOccupationsSuccess(state, action: PayloadAction<string[]>) {
      state.occupations = action.payload
    },
  },
})

export const {
  getConfigFail,
  getConfigRequest,
  getConfigSuccess,
  getAllCountriesFail,
  getAllCountriesRequest,
  getAllCountriesSuccess,
  getOfficesFail,
  getOfficesRequest,
  getOfficesSuccess,
  getOperatorsRequest,
  getOperatorsSuccess,
  getOperatorsFail,
  getExchangeRatesFail,
  getExchangeRatesRequest,
  getExchangeRatesSuccess,
  getPaymentLimitsFail,
  getPaymentLimitsRequest,
  getPaymentLimitsSuccess,
  getSourcesOfFundsRequest,
  getTransferPurposesRequest,
  getOccupationsRequest,
  getTransferPurposesSuccess,
  getSourcesOfFundsSuccess,
  getOccupationsSuccess
} = configurationSlice.actions

export const configActions = {
  [REQUEST]: getConfigRequest,
  [SUCCESS]: getConfigSuccess,
  [FAIL]: getConfigFail,
}

export const countriesActions = {
  [REQUEST]: getAllCountriesRequest,
  [SUCCESS]: getAllCountriesSuccess,
  [FAIL]: getAllCountriesFail,
}

export const getOfficesActions = {
  [REQUEST]: getOfficesRequest,
  [SUCCESS]: getOfficesSuccess,
  [FAIL]: getOfficesFail,
}

export const getOperatorsActions = {
  [REQUEST]: getOperatorsRequest,
  [SUCCESS]: getOperatorsSuccess,
  [FAIL]: getOperatorsFail,
}

export const getExchangeRatesActions = {
  [REQUEST]: getExchangeRatesRequest,
  [SUCCESS]: getExchangeRatesSuccess,
  [FAIL]: getExchangeRatesFail,
}

export const getPaymentLimitsActions = {
  [REQUEST]: getPaymentLimitsRequest,
  [SUCCESS]: getPaymentLimitsSuccess,
  [FAIL]: getPaymentLimitsFail,
}

export const getSourcesOfFundsActions = {
  [REQUEST]: getSourcesOfFundsRequest,
  [SUCCESS]: getSourcesOfFundsSuccess,
}

export const getTransferPurposesActions = {
  [REQUEST]: getTransferPurposesRequest,
  [SUCCESS]: getTransferPurposesSuccess,
}

export const getOccupationsActions = {
  [REQUEST]: getOccupationsRequest,
  [SUCCESS]: getOccupationsSuccess,
}

export default configurationSlice.reducer
