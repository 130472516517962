import React, { SyntheticEvent, useCallback } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'redux/app.hooks'
import Flag from 'features/Flag'
import countryService from 'services/country.service'
import { IRecipient } from 'model/recipient.model'
import Icon from 'components/Icon'
import { openModal, closeModal } from 'redux/reducers/ui.reducer'
import { setTransferData } from 'redux/reducers/transfer.reducer'
import { selectRecipient } from 'redux/reducers/recipient.reducer'
import EditRecipientForm from "./EditRecipientForm"

interface IProps {
  recipient: IRecipient
}

const RecipientInfo = ({ recipient }: IProps) => {
  const { t } = useTranslation(['countries_list', 'main', 'rec_details'])

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const handleEdit = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openModal({ type: EditRecipientForm, props: { recipient } }))
  }, [recipient])

  const handleTransfer = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(closeModal())
    dispatch(setTransferData({ destinationCountry: recipient.country, mode: 'recipient' }))
    navigate('/world-transfers')
    dispatch(selectRecipient(recipient))
  }, [recipient, navigate])

  return (
    <>
      <Typography variant="h4" align="center" sx={{ color: 'grey.500', fontWeight: 700 }}>
        {`${recipient.firstName || ''} ${recipient.lastName || ''}`}
      </Typography>
      <Box
        className={"horizontalFlex"}
        justifyContent={"center"}
        gap={1.25} mt={2.5}
      >
        <Flag country={recipient.country} size={24}/>
        <Typography variant="h5" align="center" sx={{ color: 'grey.600', fontWeight: 600 }}>
          {t(countryService.getFullName(recipient.country))}
        </Typography>
      </Box>
      <Box>
        {!!recipient.address && (
          <Box
            className={"horizontalFlex"}
            alignItems={"flex-start"}
            gap={2} mt={5}
          >
            <Box className={"Wrapper-filled-44"}>
              <Icon icon={"map"} size={24} color={"#0090FF"}/>
            </Box>
            <Box className={"verticalFlex"}>
              <Typography variant="body1" sx={{ color: 'designGrey.400', fontWeight: 500 }}>
                {t('main:address')}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 600 }}>
                {recipient.address}
              </Typography>
            </Box>
          </Box>
        )}
        {!!recipient.phoneNumber && (
          <Box
            className={"horizontalFlex"}
            alignItems={"flex-start"}
            gap={2} mt={3.75}
          >
            <Box className={"Wrapper-filled-44"}>
              <Icon icon={"topup_phone"} size={24} color={"#0090FF"}/>
            </Box>
            <Box className={"verticalFlex"}>
              <Typography variant="body1" sx={{ color: 'designGrey.400', fontWeight: 500 }}>
                {t('main:phone_number')}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 600 }}>
                {`${countryService.getPhonePrefix(recipient.phoneCountry)} ${recipient.phoneNumber.slice(0, 3)} ${recipient.phoneNumber.slice(3)}`}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          className={"horizontalFlex"}
          alignItems={"flex-start"}
          gap={2} mt={3.75}
        >
          <Box className={"Wrapper-filled-44"}>
            <Icon icon={"recipients"} size={24} color={"#0090FF"}/>
          </Box>
          <Box className={"verticalFlex"}>
            <Typography variant="body1" sx={{ color: 'designGrey.400', fontWeight: 500 }}>
              {t('main:relationship')}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 600 }}>
              {recipient.relationshipToSender || ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={"horizontalFlex"} gap={3.75} mt={5}>
        <Button
          variant={"outlined"}
          color={"inherit"}
          size={"large"}
          onClick={handleEdit}
        >
          {t('main:edit')}
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"large"}
          onClick={handleTransfer}
        >
          {t('rec_details:make_transfer')}
        </Button>
      </Box>
    </>
  )
}

export default RecipientInfo
