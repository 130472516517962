import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, isHebrew}) => ({
  success: {
    backgroundColor: palette.secondary.light,
    '& .MuiSnackbarContent-root': {
      color: palette.success.dark,
      '& .MuiSnackbarContent-message': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.72,
        direction: isHebrew ? 'rtl' : 'ltr',
      },
    },
  },
  error: {
    backgroundColor: palette.error.light,
    '& .MuiSnackbarContent-root': {
      color: palette.error.main,
      '& .MuiSnackbarContent-message': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.72,
        direction: isHebrew ? 'rtl' : 'ltr',
      },
    },
  },
}))

export default useStyles
