import React, { useCallback } from 'react'
import MuiModal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import classnames from 'classnames'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { closeModal, openModal } from 'redux/reducers/ui.reducer'
import Icon from 'components/Icon'
import Fade from '@mui/material/Fade'
import ConfirmRejectTransaction from '../ModalContent/ConfirmRejectTransaction'
import useStyles from './styles'

const Modal = () => {
  const { classes } = useStyles()

  const { openModal: isOpen, ContentModal, paperClass } = useAppSelector(({ ui }) => ui)
  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    if (ContentModal.props?.name === 'ConfirmTransaction') {
      dispatch(openModal({
        type: ConfirmRejectTransaction,
        paperClass: 'wide-modal',
        props: { name: 'ConfirmRejectTransaction' },
      }))
      return
    }
    if (ContentModal.props?.name === 'ConfirmRejectTransaction') {
      dispatch(openModal({
        type: ConfirmRejectTransaction,
        paperClass: 'wide-modal',
        props: { name: 'ConfirmRejectTransaction' },
      }))
      return
    }
    dispatch(closeModal())
  }, [ContentModal])

  return (
    <MuiModal
      open={isOpen}
      closeAfterTransition
      onClose={onClose}
      className={classes.root}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classnames(classes.paper, paperClass)}>
          {ContentModal}
          <IconButton className={classes.closeIcon} onClick={onClose} id="close-modal-icon">
            <Icon icon={'cross'} size={12} color={'#252627'}/>
          </IconButton>
        </div>
      </Fade>
    </MuiModal>
  )
}

export default (Modal)
