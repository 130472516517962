import React, {FC, useMemo, memo} from 'react'

const iconSet = require('./selection.json')

interface IIconProps {
  icon: string
  size?: number
  color?: string
  className?: string
}

const IcoMoon: FC<IIconProps> = ({icon, size, color, className}) => {
  const currentIcon = useMemo(() => iconSet?.icons
    .find((item: any) => item.properties.name === icon
      || (item.properties.name.includes(',') && item.properties.name.includes(icon))), [icon, iconSet?.icons])

  if (!currentIcon) {
    console.warn(`"${icon}" icon not found.`)
    return null
  }

  const viewBox = `0 0 ${currentIcon.icon.width || '1024'} 1024`

  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width={size} height={size} role="img" version="1.1" viewBox={viewBox} className={className}>
      {currentIcon.icon.paths.map((path: string, index: number) => (
        <path
          key={icon + index}
          d={path}
          fill={color}
        />
      ))}
    </svg>
  )
}

const Icon: FC<IIconProps> = ({icon, size = 14, color = '#45494D', className = ''}) => {
  return <IcoMoon icon={icon} size={size} color={color} className={className} />
}

export default memo(Icon)
