import * as configConstants from 'constants/configuration.constants'
import {api} from './api'
import {} from 'model/configuration.model'

type TSagaCall = (...args: any[]) => any

export const getConfiguration = ((payload: { organizationId: string }) => api.post(configConstants.GET_CONFIGURATION_ENDPOINT, payload)) as TSagaCall

export const getAllCountries = ((payload: { organizationId: string; agentId: string }) => api.post(configConstants.GET_COUNTRIES_ENDPOINT, payload)) as TSagaCall

export const getRelationShips = () => api.get(configConstants.GET_RELATION_SHIPS)

export const getBankAccount = () => api.post('organization/bankAccount', {country: 'IL'})

export const getOperators = () => api.post('organization/getOperators', {showActiveOnly: false})

export const getRates = (payload: any) => api.post('/price/getRatesWithTiers', payload)

export const getPaymentLimits = () => api.get('price/funding-source-types');
