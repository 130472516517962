import React from 'react'
import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {NavigateFunction} from 'react-router-dom'
import Empty from 'features/ModalContent/EmptyModal'

interface IUiState {
  toast: boolean
  toastMessage: string | string[]
  messageOpts?: { [key: string]: string }
  toastType: string
  openModal: boolean
  ContentModal: React.ReactElement
  sideBarOpen: boolean
  spinnerOpen: boolean
  paperClass: string
  barHeight: number
  navigation?: {navigate: NavigateFunction}
}

const initialState: IUiState = {
  toast: false,
  toastMessage: '',
  toastType: '',
  openModal: false,
  ContentModal: React.createElement(Empty),
  sideBarOpen: true,
  spinnerOpen: false,
  paperClass: '',
  barHeight: 207,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openToast(state, action: PayloadAction<{ toastMessage: string | string[], type?: string, messageOpts?: { [key: string]: string } }>) {
      state.toast = true
      state.toastMessage = action.payload.toastMessage || ''
      state.toastType = action.payload.type || 'success'
      if (action.payload.messageOpts) {
        state.messageOpts = action.payload.messageOpts
      }
    },
    closeToast(state) {
      state.toast = false
      state.toastMessage = ''
      delete state.messageOpts
    },
    openModal(state, action: PayloadAction<{ type: React.ComponentType<any>; props?: any; paperClass?: string }>) {
      state.openModal = true
      state.ContentModal = React.createElement(action.payload.type, action.payload.props)
      state.paperClass = action.payload.paperClass || ''
    },
    closeModal(state) {
      state.openModal = false
      state.ContentModal = React.createElement(Empty)
      state.paperClass = ''
    },
    toggleSideBar(state) {
      state.sideBarOpen = !state.sideBarOpen
    },
    openSpinner(state) {
      state.spinnerOpen = true
    },
    closeSpinner(state) {
      state.spinnerOpen = false
    },
    setNavigate(state, action: PayloadAction<{navigate: NavigateFunction}>) {
      state.navigation = action.payload
    },
    setBarHeight(state, action: PayloadAction<number>) {
      state.barHeight = action.payload
    },
  },
})

export const {
  openToast,
  closeToast,
  openModal,
  closeModal,
  toggleSideBar,
  openSpinner,
  closeSpinner,
  setNavigate,
  setBarHeight,
} = uiSlice.actions

export default uiSlice.reducer
