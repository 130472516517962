import React, { FC } from 'react'
import { Typography, Box, Divider, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardAccountVisa } from 'components/CardViews'
import countryService from 'services/country.service'
import { ICard } from 'model/wallet.model'

import useStyles from './styles'

interface IChangeProps {
  card: ICard
  currency: string
}

const ChangeCurrencySuccess: FC<IChangeProps> = ({ card, currency }) => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  return (
    <Box width={1}>
      <Box width={1}>
        <CardMedia
          image={require('assets/images/success-background.png')}
          sx={{ backgroundSize: 'cover', height: '100%' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 7.5, paddingBottom: 3.75 }}>
            <Typography variant={"h3"} align={"center"} sx={{ fontWeight: 700, color: 'white', fontSize: 28 }}>
              {t('main:success')}
            </Typography>
            <Typography variant={"h5"} align={"center"} sx={{ fontWeight: 600, color: 'white' }}>
              {`${t('cards:new_currency')} - ${currency}`}
            </Typography>
            <CardMedia
              image={require('assets/images/success-sign.png')}
              sx={{ width: 105, height: 105, marginTop: 3.5 }}
            />
          </Box>
        </CardMedia>
      </Box>
      <Box pt={5} px={6.25} pb={6.25}>
        <Typography variant={"h4"} align={"center"} sx={{ fontWeight: 700, color: 'grey.500' }}>
          {t('cards:can_pay_new_currency')}
        </Typography>
        <Box px={"25%"} mt={2.5}>
          <Typography variant={"subtitle2"} align={"center"} sx={{ color: 'designGrey.500' }}>
            {t('cards:another_currency_info')}
          </Typography>
        </Box>
        <Box mt={3.5} mb={1}>
          <CardAccountVisa
            caption={card.productNameForCustomer || card.productCaption}
            amount={card.accountsData[currency].availableBalance}
            currency={card.accountsData[currency].currency}
            pan={card.pan}
          />
        </Box>
        <Divider/>
        <Box mt={1} className={"verticalFlex"} gap={0.25}>
          <Typography variant={"body1"} sx={{ color: 'designGrey.400', fontWeight: 500 }}>
            {t('cards:new_currency')}
          </Typography>
          <Typography variant={"subtitle2"} sx={{ color: 'grey.600', fontWeight: 600 }}>
            {countryService.getCurrencyNameByCurrencyCode(currency)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default (ChangeCurrencySuccess)
