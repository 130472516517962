import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import MuiCheckbox, {CheckboxProps} from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import {FieldProps} from 'formik'
import Icon from '../Icon'

import useStyles from './styles'

interface ICheckProps extends Omit<FormControlLabelProps, 'control' | 'classes' | 'form'> {
  error?: string
  fullWidth?: boolean
}

export const Checkbox = ({...props}: CheckboxProps) => {
  const {classes} = useStyles()

  return (
    <MuiCheckbox
      icon={<span className={classes.icon}/>}
      className={classes.transparentBack}
      checkedIcon={
        <span className={classes.checkedIcon}>
        <Icon icon={'check'} color={'#fff'}/>
      </span>
      }
      {...props}
    />
  )
}

const CheckboxElement: FC<ICheckProps> = ({className = '', error = '', fullWidth = true, ...props}) => {
  const {classes} = useStyles()

  const {t} = useTranslation('validation')

  return (
    <FormControl fullWidth={fullWidth} error={!!error} className={className}>
      <FormControlLabel
        classes={{root: classes.root, label: classes.label}}
        control={
          <MuiCheckbox
            disableRipple
            icon={<span className={classes.icon}/>}
            checkedIcon={
              <span className={classes.checkedIcon}>
                <Icon icon={'check'} color={'#fff'}/>
              </span>
            }
          />
        }
        {...props}
      />
      <FormHelperText style={{position: 'static'}}>{!!error && t(error)}</FormHelperText>
    </FormControl>
  )
}

export const FormikCheckbox = ({className, label, field, form, fullWidth = true}: ICheckProps & FieldProps) => {
  const {classes} = useStyles()

  return <CheckboxElement
    fullWidth={fullWidth}
    label={label}
    error={form.touched[field.name] ? (form.errors[field.name] as string) : ''}
    checked={form.values[field.name] === true}
    // classes={classes}
    className={className}
    {...field}
  />
}

export const CircleCheckBox = ({active}: {active: boolean}) => {
  const {classes} = useStyles()

  return (
    <Box
      className={classes.circle}
      borderColor={active ? 'primary.main' : 'designGrey.300'}
    >
      <Box
        flex={1}
        borderRadius={"50%"}
        bgcolor={active ? 'primary.main' : 'transparent'}
      />
    </Box>
  )
}

export default CheckboxElement
