export const GET_SOURCE_CURRENCIES = '/unAuth/organization/getSourceCurrencies'
export const GET_DESTINATION_CURRENCIES = '/unAuth/organization/getDestinationCurrencies'

export const GET_PRICE_REQUEST = '/price/getPrice'
export const GET_PRICE_LIMIT = '/price/getSendLimit'

export const GET_LOCATIONS_SETTINGS = '/operator/getPayoutTypes'
export const GET_PICKUP_LOCATIONS = '/operator/getCashPickupByCountry'
export const GET_BANKS = '/operator/getBanksByCountry'
export const GET_BRANCHES_BANK = '/operator/getBankBranchesByCountry'
export const GET_COUNTRY_CITIES = '/operator/getCountryCities'
export const GET_CITY_LOCATIONS = '/operator/getCashPickupLocationsByCity'
export const GET_COUNTRY_STATES = '/operator/getCountryStates'
export const GET_STATE_CITIES = '/operator/getStateCities'
export const GET_STATE_CITY_LOCATIONS = '/operator/getCashPickupByCity'

export const GET_MOBILE_OPERATORS = '/operator/getMobileOperatorsByCountry'

export const GET_IFSC_CODE = '/transaction/queryIFSC'

export const CHECK_FDH_ACCOUNT = '/transactionNew/verifyPaymentSource'

export const CREATE_TRANSACTION = '/transactionNew/mobile/new'
export const CONFIRM_TRANSACTION = '/transactionNew/mobile/confirm'
export const VALIDATE_TRANSACTION = '/transactionNew/validate'

export const GET_PAYMENT_URL = '/payment/getPageUrl'
